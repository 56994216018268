.warning-container {
  .warning-icon-style {
    width: 66px;
    height: 66px;
  }
}

.warning-description {
  width: 328px;
  align-items: center;
  text-align: center;
}