.favorite-lists {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  background: var(--cx-color-white);

  input:focus {
    outline: none !important;
    border-color: none;
    box-shadow: none;
  }

  .flex-item-left {
    flex: 10%;
  }

  .search-box {
    border-radius: 5px;
    padding: 8px;
    border: 1px solid var(--cx-color-primary);
    width: 270px;
  }

  .favorites-list {
    box-shadow: var(--common-box-shadow);
    background: var(--cx-color-white);
  }

  .created-by-bg {
    background-color: var(--cx-p10);
    max-height: 30px;
  }

  .item-heading {
    max-height: 24px;
  }

  #list-number {
    background-color: var(--cx-color-primary2);
    color: var(--cx-color-white);
  }

  table {
    table-layout: fixed;
  }

  th:first-child,
  th:nth-child(3) {
    width: 15%;
  }

  th:nth-child(2) {
    width: 10%;
  }

  th:last-child {
    width: 60%;
  }

   @include media-breakpoint-down(sm) {
       th:first-child {
       width: 30%;
     }
       th:nth-child(2) {
       width: 10%;
     }
     
     th:nth-child(3) {
       width: 20;
     }
       th:last-child {
       width: 40%;
     }
   }

   td {
    word-break: break-all;
   }

  .columns-bg {
    background-color: var(--cx-p2-20);
  }

  #additional-products {
    color: var(--cx-color-primary);
    cursor: pointer;
  }

  .action-btn.mdc-button {
    height: 32px;
    border: 1px solid var(--cx-color-primary);
    box-shadow: none;
    .mat-icon {
      width: 16px;
      height: 20px;
      margin-bottom: 5px;
      margin-right: 7px;
    }
  }

  .manage-btn {
    color: var(--cx-dark-grey);
  }

  .no-lists {
    height: 78px;
    margin-top: 22px;
  }

  .no-lists p {
    color: var(--cx-alert);
    margin-top: 28px;
  }

  .inputBox {
    margin: 0;
    padding: 0 35px 0px 10px;
    height: 38px;
    max-width: 250px;
    border: solid 1px var(--cx-color-black);

    &.createClientButton {
      max-width: none;
      width: 373px;
    }
  }

  .search-icon-wrapper {
    margin-top: 6px;
    position: absolute;
    right: 20px;

    mat-icon.search-icon {
      width: 20px;
      height: 20px;
    }
  }

  .cancel-icon-wrapper {
    margin-top: 6px;
    position: absolute;
    right: 50px;

    mat-icon.cancel-icon {
      width: 20px;
      height: 20px;
    }
  }

  .form-control:focus,
  input:focus {
    outline-style: none;
  }

  @include media-breakpoint-down(sm) {
    .inputBox {
      max-width: 100%;
      width: 100%;
    }
  }

  @include media-breakpoint-down(md) {
    .cart-side-drawer, .wishlist-drawer {
      .mat-mdc-dialog-container {
        padding: 0;
        width: 285px;

        .mat-mdc-unelevated-button.mat-primary {
          background-color: var(--cx-color-primary2);
          padding: 5px;

          &.sitelinks-close {
            min-width: 25px;
            padding: 0;

            span {
              color: var(--cx-color-white);
            }
          }

          &.cdk-program-focused,
          &.cdk-focused {
            color: var(--cx-color-white);
            background-color: var(--cx-color-primary2);
          }
        }
      }
    }

    .cart-side-drawer, .wishlist-drawer {
      height: 100%;
    }
  }
}

.selectProductDropdown {
  min-width: 120px;
}