// @import '../../spartacus/cart.scss';

// custom scss starts //

body {
  .cart {
    .CartProductListSlotName-cartPage {
      width: 66%;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
    .CartSummarySlotName-cartPage {
      width: 32%;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
    &-shipping {
      &-section {
        min-height: 75px;
        background-color: var(--cx-color-white);
        box-shadow: var(--common-box-shadow);
        border-radius: 7px; 
        .select-warehouse {
          max-height: 100px !important;
        }
      }
      &-warehouse {
        @extend .material-override;
        .mat-mdc-form-field-type-mat-select {
          .mdc-notched-outline {
            height: 50px;
            margin-top: 4px;
          }
          .mat-mdc-form-field-subscript-wrapper {
            display: none;
          }
        }
      }
      &-from {
        width: 80%;
        @include media-breakpoint-down(lg) {
          width: 100%;
        }
      }
      &-from-header , &-to-header {
        border: 1px solid var(--cx-p2-40);
        min-width: 180px;
        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }
      &-button {
        //width: 28%;
        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }
      .add-ship-to-btn {
        .mat-icon {
          width: 15px;
        }
      }
      &-do {
        .mat-icon {
          height: auto;
        }
      }
    }
    &-delivery {
      &-section {
        min-height: 50px;
        background-color: var(--cx-color-white);
        box-shadow: var(--common-box-shadow);
      }
      &-button {
        width: 41%;
        @include media-breakpoint-down(lg) {
          width: 50%;
        }
        @include media-breakpoint-down(sm) {
          width: 100%;
          margin-top: 16px;
        }
      }
    }
    &-free-freight {
      height: 28px;
      background-color: var(--cx-free-freight);
      border: solid 1px var(--cx-color-black);
      border-left: solid 5px var(--cx-color-black);
      border-radius: 2px 0px 0px 2px;
      .mat-icon{
        width: 17px;
        margin-bottom: 4px;
      }
    }
    &-item {
      background-color: var(--cx-color-white);
      box-shadow: var(--common-box-shadow);
      margin-bottom: 20px;
      &-header {
        min-height: 45px;
        background-color: var(--cx-global-inventory);
        &-number {
          width: 48px;
          background-color: var(--cx-hr-border);
          color: var(--cx-color-white);
        }
  
        &-width {
          min-width: 22%;
          @include media-breakpoint-down(md) {
            min-width: 17%;
            word-break: break-all;
          }
        }

        &-details {
          @include media-breakpoint-down(md) {
            width: 100% !important;
          }
        }
      }
      &-img a {
        width: 110px;
        display: block;
        img {
          height: 110px;
          width: 110px;
        }
      }
      &-detail{
        max-width: 308px;
      }
      &-qty {
        @extend .material-override;
        .mat-mdc-form-field-infix {
          width: 45px;
        }
        &-section {
          display: flex;
          flex-direction: row;
          .cart-item-qty {
            .mat-mdc-text-field-wrapper.mdc-text-field {
              height: 45px;
            }

            .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
              padding-top: 13px;
            }

            .mat-mdc-form-field-subscript-wrapper {
              display: none;
            }
          }
          @include media-breakpoint-down(md) {
            justify-content: space-between;
            width: 100%;
          }
        }

        .mat-mdc-text-field-wrapper {
          .mdc-text-field {
            height: 40px;
          }
          .mat-mdc-form-field-flex .mat-mdc-floating-label {
            top: 21px;
            &.mdc-floating-label--float-above {
              top: 28px;
            }
          }
        }

       
      }
      &-update {
        text-decoration: underline;
      }
      &-inventory {
        &-wh {
          &-count {
            @extend .t-label-s !optional;
            background-color: var(--cx-warning-success);
            border: 1px solid var(--cx-warning-success);
            color: var(--cx-color-white);
          }
          &-name {
            @extend .t-label-s !optional;
            border: 1px solid var(--cx-warning-success);
          }
          &.red {
            .cart-item-inventory-wh {
              &-count {
                background-color: var(--cx-warning-error);
                border: 1px solid var(--cx-warning-error);
              }
              &-name {
                border: 1px solid var(--cx-warning-error);
              }
            }
          }
        }
      }
      &-orderConfirm{
          display: flex;
          margin:8px 0px;
          flex-direction: row-reverse;
         
      }
      &-cartInventory{
      display: flex;
      flex-direction: column;
      margin:8px 0px;
      border-top: 1px solid var(--cx-table-vertical-border);
      }
      &-remove {
        border: 1px solid var(--cx-hr-border);
        mat-icon {
          height: auto;
          svg {
            height: 24px;
          }
        }
      }
      &.promo {
        margin-left: 45px;
        .cart-item-header {
          &-number {
            background-color: var(--cx-color-white);
            color: var(--cx-color-black);
            border: 1px dashed var(--cx-warning-success);
          }
          &-num {
            border: 1px solid var(--cx-warning-success);
          }
        }
  
      }

      &-detail {
        @include media-breakpoint-down(md) {
          width: 65% !important;
        }
      }

      &-main {
        @include media-breakpoint-down(md) {
          flex-wrap: wrap;
        }
      }
      .order-confirmation-price{
        margin-right: 72px;
      }

      .save-amount {
        background: var(--save-amount);
        outline: 1px solid var(--save-amount);
        border: 1px solid var(--cx-color-black)
      }
    }
    &-promo-section {
      .cart-item-header-number {
        background-color: var(--cx-warning-success);
      }
    }
    &-summary {
      background-color: var(--cx-color-white);
      box-shadow: var(--common-box-shadow);
      margin-top: 52px;
      @include media-breakpoint-down(md) {
        margin-top: 0;
      }
      &-header {
        @extend .cart-shipping-from-header;
      }
      &-seperator {
        height: 2px;
        background-color: var(--cx-table-vertical-border);
      }
      
      .text-decoration-underline {
        text-decoration: underline;
        color: var(--cx-color-black);
      }
      .go-back-icon{
        .mat-icon{
          height: 18px;
        }
      }
    }
  
    button.mat-white, a.mat-white {
      height: 28px;
      padding: 0 20px;
      border: 2px solid var(--hover-button-color);
      border-radius: 7px;
      color: var(--cx-color-black);
      mat-icon {
        width: 18px;
      }
    }

        // quick order styling
        .quick-order-heading-section {
          background: var(--cx-color-white);
          box-shadow: var(--common-box-shadow);
        }
    
        .set-color {
          color: var(--cx-color-primary);
        }
    
        .quick-orders-items {
          display: flex;
          flex-direction: row;
        }
        
        .mat-mdc-text-field-wrapper {
          border-radius: 4px;
          padding-bottom: 0 !important;
        }
    
        .order-items {
          background-color: var(--cx-color-white);
          width: 100%;
          .mat-mdc-form-field-icon-suffix .mat-icon{
            padding-bottom: 4px;
          }
          .mat-mdc-form-field-bottom-align {
            margin-top: -10px;
          }
          .mat-mdc-form-field {
            width: 100%;
            .mat-mdc-text-field-wrapper {
              padding-bottom: 0 !important;
              .mat-mdc-form-field-infix {
                height: 45px;
                min-height: auto;
              }
            }
          }
        }
    
        .order-qtys {
          background-color: var(--cx-color-white);
    
          .mat-mdc-form-field {
            width: 56px;
            .mat-mdc-text-field-wrapper {
              padding-bottom: 0 !important;
              .mat-mdc-form-field-infix {
                height: 45px;
                min-height: auto;
              }
            }
          }
        }
    
        @media (max-width: 800px) {
          .quick-order-main-section {
            flex-direction: column;
          }
        }
    
        .set-color {
          color: var(--cx-color-primary);
        }
    
        .exclamation-icon {
          color: var(--exclamation-icon-color);
        }
    
        .order-opacity {
          opacity: 0.6;
        }
    
        &-add-to-order.mdc-button.mdc-button--unelevated {
          max-height: 42px;
          padding: 0;
          width: 125px;
      }
    
        // Input Section Styling
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
    
        /* Firefox */
        input[type=number] {
          -moz-appearance: textfield;
        }
       
  }
}

//This is the CSS to reduce the height of mat select of warehouse and mat-input of qty as per VD
.material-override {
  .mat-mdc-form-field-wrapper {
    padding: 0 !important; // Imp needed to override the OOTB code
    margin: 0 !important; // Imp needed to override the OOTB code
  
    .mat-form-field-flex {
      margin: 0;
    }
    .mat-mdc-form-field-infix {
      padding: 2px 0 10px 0;
    }
   
    .mat-select-arrow-wrapper {
      padding-top: 12px;
    }
   
    .mat-form-field-label-wrapper {
      top: -1.4em; // OOTB this value is in negative only in mat-select and mat-input
    }
  }
 
  .mat-form-field-should-float {
    .mat-form-field-label-wrapper {
      top: -1em; // OOTB this value is in negative only in mat-select and mat-input
    }
  }
}
// custom scss ends //


.clear-cart-modal {
  &-close {
    left: 93%;
  }
}

.cart-info-dialog {
  @include media-breakpoint-down(md) {
    height: 97vh;
    width: 97vw;
    max-width: 97vw !important; // Imp is needed as mat dialog is injecting inline styles
    padding: 16px;
  }
  &-header {
    text-align: center;
  }
  .shipping-description {
    width: 500px;
    @include media-breakpoint-down(md) {
      width: 75vw;
    }
  }
}

body {
  .medical-info-dialog {
    @include media-breakpoint-down(md) {
      max-width: 95% !important;
      width: 95% !important;
      padding: 20px;
    }
    .mat-mdc-dialog-container {
      //width: 788px;
      border-radius: 10px;
      padding: 24px;
    }
  }

  .medical-info-upim {
    text-overflow: ellipsis;
    word-break: break-all;
  }

  .npi-error {
    margin-top: -15px;
    padding: 0 1em;
  }
}