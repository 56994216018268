.my-account-rep-dialog{
  .my-account-rep-discuss-input-display{
    .mat-mdc-form-field{
      width: 280px;
    }
  }
  .my-account-rep-submit-button {
    & button {
      background-color: var(--cx-color-primary);
      color: var(--cx-color-white);
    }
  }
}