.sr-home-layout-header {
  height: 48px;
  .header-msg {
    gap: 10px;
    background-color: var(--sr-alert-black);
    color: var(--cx-color-white);
    padding: 2px;
    a {
      text-decoration: none;
      color: var(--cx-color-white);
      font-weight: 500;
    }

    .BoxoutPortalHomeHeader {
      p {
        margin-bottom: 0 !important;
      }
    }
  }

  .header-msg{
    p {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: row;

      a {
        margin-left: 6px;
      }
    }
  }

  .header-body {
    .website-logo.bxd-logo {
      app-custom-banner {

        img {
          width: 150px;
          height: auto;
        }
        @include media-breakpoint-down(md) {
          img {
            height: 20px;
          }
        }
      }
    }

    .user-icon {
      mat-icon {
        height: 26px;
        width: 26px;
      }
    }
  }
}

.sr-profile-details {
  .sr-profile-description {
    a {
      gap: 16px;
    }
    
    width: auto;
    .profile-image {
      width: 44px;
      height: 44px;
      background-color: var(--cx-color-primary);
      img.profile-pic {
        width: 100%;
        height: 100%;
      }
    }

    .profile-description {
      gap: 5px;

      .name {
        width: 190px;
        height: auto;
      }
    }
  }
}