body{
  .edit-ship-to-block{

    .edit-ship-to-input{
      mat-mdc-form-field {
        display: block;
      }
    }
    .edit-ship-to-reusable-address-form{
      .state-input-or-zipcode-input{
        mat-form-field{
          width: 369px;
        }
      }
    }

    @include media-breakpoint-down(md){
      .edit-ship-to-reusable-address-form{
        .state-input-or-zipcode-input{
          mat-form-field{
            width: 100%;
          }
        }
      }
    }

    .edit-ship-to-toggle-ship{
      width: 75px;
      overflow-wrap: break-word;
    }
    .edit-ship-to-toggle-bill{
      width: 52px;
      overflow-wrap: break-word;
    }
    .edit-ship-to-cancel-button{
      button{
        height: 40px;
      }
    }
  }
}