/// @group mixins
// @mixin font-attributes ($color: $black, $font-weight, $font-size, $font-family: $base-font-family, $line-height: normal) {
//     color: $color;
//     font-weight: $font-weight;
//     font-size: $font-size;
//     font-family: $font-family;
//     line-height: $line-height;
// }

// @mixin element-spacing ($margin, $padding) {
//     margin: $margin;
//     padding: $padding;
// }

// @mixin element-dimensions ($width, $height) {
//     width: $width;
//     height: $height;
// }

// @mixin element-positioning( $top, $left) {
//     top: $top;
//     left: $left;
// }

// @mixin box-configuration ($border, $bg-color, $color) {
//     border: $border;
//     background-color: $bg-color;
//     color: $color;
// }

@function globalParent() {
  $globalParent: undefined;
  @if ($theme-name != undefined) {
    $globalParent: "." + #{$theme-name};
  } @else {
    $globalParent: body;
  }
  @return $globalParent;
}

/// Function for generating string
/// @param {String} $string
/// @param {String} $search
/// @param {String} $replace
@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }

  @return $string;
}

/// Mixin for generating the font family given the font face name and path to the font folder
/// @group mixins
/// @param {String} $name
/// @param {URL} $path
/// @param {Number} $weight [100-900]
/// @param {String} $style [bold,itallic]
/// @param {String} $exts [eotwoff2woffttfsvg]
@mixin font-face(
  $name,
  $path,
  $weight: null,
  $style: null,
  $exts: eot woff2 woff ttf svg
) {
  $src: null;

  $extmods: (
    eot: "?",
    svg: "#" + str-replace($name, " ", "_"),
  );

  $formats: (
    otf: "opentype",
    ttf: "truetype",
  );

  @each $ext in $exts {
    $extmod: if(
      map-has-key($extmods, $ext),
      $ext + map-get($extmods, $ext),
      $ext
    );
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append(
      $src,
      url(quote($path + "." + $extmod)) format(quote($format)),
      comma
    );
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
    font-display: swap;
  }
}

$html-font-size: 16px;
@function stripUnit($value) {
  @return $value / ($value * 0 + 1);
}

/// px to rem converter
/// @param {string} $pxValue  Pixel value to be converted to rem ex:16px
@function toRem($pxValue) {
  @return #{stripUnit($pxValue) / stripUnit($html-font-size)}rem;
}

///
/// @param {Number} $size
/// @param {String} $type [max| min]
/// @param {Number} $pixels [$viewport-s]

@mixin screen($size, $type: max, $pixels: $viewport-s) {
  @if $size == "small" {
    @media screen and ($type + -width: $viewport-s) {
      @content;
    }
  } @else if $size == "medium" {
    @media screen and ($type + -width: $viewport-m) {
      @content;
    }
  } @else if $size == "large" {
    @media screen and ($type + -width: $viewport-l) {
      @content;
    }
  } @else if $size == "extra-large" {
    @media screen and ($type + -width: $viewport-xl) {
      @content;
    }
  } @else if $size == "custom" {
    @media screen and ($type + -width: $pixels + px) {
      @content;
    }
  } @else {
    @content;
  }
}

/// Mixin for generating btn code boilerplate
/// @param {String} $bgColor
/// @param {String} $txtColor
/// @param {String} $borderColor
/// @param {String} $hoverBgColor
/// @param {String} $hoverTxtColor
/// @param {String} $hoverBorderColor [$borderColor]
@mixin btn(
  $bgColor,
  $txtColor,
  $borderColor,
  $hoverBgColor,
  $hoverTxtColor,
  $hoverBorderColor: $borderColor
) {
  background: $bgColor;
  color: $txtColor;
  border: 1px solid $borderColor;
  &:hover {
    box-shadow: inset 9999px 9999px $hoverBgColor;
    color: $hoverTxtColor;
    border-color: $hoverBorderColor;
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.4;
  }
}
/// Mixin for generating button sizes
/// @param {String} $fontSize
/// @param {String} $padding
/// @param {String} $height
@mixin btn-size($fontSize, $padding, $lineHeight, $height, $border-radius) {
  font-size: $fontSize;
  height: $height;
  padding: 0 $padding;
  line-height: $lineHeight;
  border-radius: $border-radius;
}

/// @group typography
/// Mixin for the font-attributes
/// @param {String} $style  [normal | Itallic | Oblique]
/// @param {String} $variant [nomral | small-caps ]
/// @param {String} $weight [normal | bold | 100-900]
/// @param {String} $size [font size in px]
/// @param {String} $line-height [line height in px]
/// @param {String} $family [font-family]
@mixin font-attributes(
  $font-size-map,
  $style,
  $variant,
  $weight,
  $line-height,
  $family,
  $transform: none
) {
  @each $fs-breakpoint, $font-size in $font-size-map {
    @if $fs-breakpoint == viewport-xs {
      font: $style
        $variant
        $weight
        #{nth($font-size, 1)}/#{nth($font-size, 2)}
        $family;
    }
    @if $fs-breakpoint == viewport-m {
      @media screen and (min-width: $viewport-m) {
        font: $style $variant $weight #{nth($font-size, 1)}/#{nth(
            $font-size,
            2
          )} $family;
      }
    }
    @if $fs-breakpoint == viewport-l {
      @media screen and (min-width: $viewport-l) {
        font: $style $variant $weight #{nth($font-size, 1)}/#{nth(
            $font-size,
            1
          )} $family;
      }
    }
  }
}
