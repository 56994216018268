.inventory-quarantine {
  table {

    th {
      .content {
        display: flex;
        align-items: center;
        justify-content: center;

        mat-icon {
          height: 24px;
          width: 16px;
          transition: rotate 0.3s ease-in-out;
        }
      }

      &.active {
        mat-icon {
          transform: rotate(180deg);
        }
      }
    }
    tr {
      th:first-of-type,
      th:nth-of-type(2){
        width: 90px;
        min-width: 90px;
      }
      th:nth-of-type(3) {
        width: 127px;
        min-width: 110px;
      }
      th:nth-of-type(4) {
        width: 110px;
        min-width: 100px;
      }
      th:nth-of-type(5) {
        width: 131px;
        min-width: 110px;
      }
      th:nth-of-type(6) {
        width: 150px;
        min-width: 110px;
      }
      th:nth-of-type(7) {
        width: 250px;
        min-width: 110px;
      }
      th:nth-of-type(8) {
        width: 210px;
        min-width: 90px;
      }
      th:nth-of-type(9) {
        width: 74px;
        min-width: 60px;
      }
      th:nth-of-type(10),
      th:nth-of-type(11) {
        width: 90px;
        min-width: 80px;
      }
      th:last-of-type {
        width: 138px;
        min-width: 110px;
      }

      td:nth-child(2) {
        text-decoration: underline;
      }
      td:nth-child(4) {
        text-decoration: underline;
      }
    }
    .inventoryQuarantine-hyperlink{
      color: var(--hyperlink-blue);
    }
  }
}
