.theme-mesite {
  .sr-search {
    .website-logo {
      background-color: var(--cx-color-primary);
      padding: 5px;
    }
  }
  .clearFilter{
    padding: 5px 10px;
    color: var(--cx-color-white);
    background-color: var(--cx-color-primary);
  }

  .contact-us-texts .contact-us-time {
    color: var(--cx-color-black);
  }

  .manage-create-user-cancel.mat-mdc-unelevated-button:not(:disabled) {
    color: var(--cx-color-black);
  }

  .link-color {
    color: var(--cx-header-sitelink);
  }

  .breadcrumb-container {
    .base-site {
      color: var(--cx-color-black);
    }
  }
}
