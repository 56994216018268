.portal-bxd-create-wro-success{
  .gap-7{
    gap: 7px;
  }

  .gap-22{
    gap: 22px;
  }

  .gap10 {
    gap: 10px;
  }

  .topHeader{
    margin: -16px;
    box-shadow: var(--common-box-shadow);
    background-color: var(--cx-color-white);
    min-width: 100%;

    .deliveryIcon{
      height: 34px;
      width: 34px;
    }

    .resubmit-wro {
      padding: 5px 12px;
      border-radius: 5px;
      background-color: var(--cx-warning-success);
      color: var(--cx-color-white);
    }

    .cancelSubmitWro {
      padding: 5px 12px;
      border-radius: 5px;
      background-color: var(--cx-warning-error);
      color: var(--cx-color-white);
    }
  }

  .mainBody{
    box-shadow: var(--common-box-shadow);
    background-color: var(--cx-color-white);
    min-width: 100%;

    .done-icon{
      height: 30px;
      width: 30px;
    }

    .warning-info{
      height: 28px;
      width: 24px;
    }

    .right-section{
      gap: 25px;
    }

    .warning{
      gap: 6px;
    }

    .labelBox{
      border: 1px solid var(--cx-color-secondary);
      background-color: var(--cx-drop-ship);
      padding: 10px;
      gap: 1px;
      width: 30%;

      .download-icon {
        height: 24px;
        width: 24px;
      }

      .downloadString {
        font-weight: 700;
      }

      @include media-breakpoint-down(sm) {
        width: 90%;
      }
    }

    .labelInstructions{
      border: 1px solid var(--cx-warning-error);
      padding: 10px;

      .heading{
        color: var(--cx-warning-error);
      }

      .custom-hyperlink {
        color: var(--cx-color-black) !important;
      }
    }
  }
  
  .mousePointer {
    cursor: pointer;
  }
}