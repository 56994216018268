.print {
    &-first-page , &-second-page {
        height: 1025px;
        width: 808px;
        background-color: var(--cx-color-white);

        &-failure {
            &.t-bxdPortal-printLabelBold {
                font-style: italic;
            }
        }

    }

    &-first-page {
        &-footer {
            display:table-cell;
            vertical-align: bottom;
        }
    }

    &-second-page {
        &-box {
            border: 1px dashed var(--cx-color-black);
        }
        &-top {
            &-barcode {
                font-size: 12px;
            }
            &-text {
                margin-top: -6px;
            }
        }
        &-middle {
            height: 30px;
            background-color: var(--bxd-sidenav-bg);
        }

        // margins are needed in negative to align the extra margin which qrcode adds on its own
        &-bottom {
            .qrcodeImage {
                margin-top: -24px;
                margin-right: -8px;
                &-code {
                    margin-top: -12px;
                }
            }
    
            .t-bxdPortal-printLabelFooter, .t-bxdPortal-printLabelSmall {
                line-height: 1.2rem;
            }
        }

    }
}

.hidden-print {
    position: absolute;
    top: -1000%;
    left: -1000%;
}