.reports-search {
  background: var(--cx-color-white);
  box-shadow: var(--common-box-shadow);

  &-select {
    &.mat-mdc-select-panel {
        background-color: var(--cx-p2-10);
      &.select-virtual-scroll-order {
        max-height: 350px;
        min-width: 350px !important; // imp is needed to override the mat cdk virtual scroll width
      }
      &.select-virtual-scroll {
        max-height: inherit;
        &.modify-search-scroll {
          min-width: 350px !important; // imp is needed to override the mat cdk virtual scroll width
        }
      }

      .mat-mdc-option.mat-mdc-selected:not(.mat-mdc-option-multiple) {
        background-color: var(--cx-p2-40);
      }
      
      .mat-option-text {
        color: var(--cx-mat-option);
        line-height: 1.25rem;
      }

      .cdk-viewport {
        height: 350px;
      }
      
      .cdk-item {
        height: 50px;
      }
    }

    .cdk-virtual-scroll-viewport {
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        overflow-y: scroll;
        background: var(--cx-p2-10);
        box-shadow: inset 0 0 6px var(--cx-p2-10);
      }
  
      &::-webkit-scrollbar-thumb {
        background: var(--cx-select-scroll);
        border-radius: 6px;
      }
    }

    &::-webkit-scrollbar {
      width: 4px;
      overflow-y: scroll;
      background: var(--cx-p2-10);
      box-shadow: inset 0 0 4px var(--cx-p2-10);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--cx-select-scroll);
      border-radius: 10px;
    }
  }

  input.ng-invalid.ng-dirty, input.ng-invalid.ng-touched {
    color: var(--cx-warning-error);
  }

  .width-48 {
    width: 48%;
  }
 
  .mat-mdc-text-field-wrapper {
    margin: 0;
  }

  @include media-breakpoint-down(md) {
    .acct-page-heading {
      height: auto;
      line-height: normal;
    }
  }
}

.table-reports-query {
   
  .mat-form-field-outline {
    background-color: var(--cx-color-white);
  }
   
  .mat-form-field-should-float {
    .mat-form-field-label {
      mat-label {
        background: var(--cx-mat-label);
        @extend .t-body-m;
      }
    }
  }
  &-row2 {
    .mat-mdc-form-field {
      width: 22.5%;
    }
  } 
  &-row1 {
    .range-dropdown {
      width: 48.5%;
    }
    .calender-date-section {
      width: 49%;
      .startdate-input, .end-date-section {
        width: 47%;
      }
    }
  }
  .cancel-btn {
    background-color: transparent;
  }
}

app-reports-search-form, app-item-purchase-activity {
  .acct-page-heading {
    height: auto;
    line-height: normal;
  }
}

.pod-search {
  background: var(--cx-color-white);
  box-shadow: var(--common-box-shadow);
  .width-48 {
    width: 48%;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
   
   
  .mat-form-field-subscript-wrapper {
    @extend .p-0 !optional;
    .mat-mdc-form-field-hint {
      color: var(--cx-color-black);
      font-size: 0.875rem;
      line-height: 1.4375rem;  
      font-weight: 500;
    }  
  }  
}

app-pod-bulk-export-search {
  @include media-breakpoint-down(md) {
    .acct-page-heading {
      height: auto;
      line-height: normal;
    }
  }
}


