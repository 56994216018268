.add-ship-to-client-patient-container {
  
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 7px;
    background: var(--cx-p10);
    box-shadow: inset 0 0 4px var(--cx-p10);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--cx-table-scroll);
    border-radius: 10px;
  }
  
  position: fixed;
  width: 368px;
  box-shadow: var(--common-box-shadow);
  background: var(--cx-color-white);
  right: 0;
  top: 0;
  height: 100vh;
  overflow-y: auto;
  .add-ship-to-address-header-border{
    padding: 10px;
    height: 44px;
    border-bottom: 1px solid var(--cx-color-black);
    .mat-mdc-unelevated-button.mat-primary {
      background-color: var(--cx-color-primary2);
      padding: 5px;
      &.sitelinks-close {
          min-width: 25px;
          padding: 0;
          span {
              color: var(--cx-color-white);
          }
      }
      &.cdk-program-focused , &.cdk-focused {
          color: var(--cx-color-white);
          background-color: var(--cx-color-primary2);
      }
  }
  }
  .edit-ship-to-toggle-ship{
    width: 75px;
    overflow-wrap: break-word;
  }

  .add-shipTo-client-address-submit-button {
    & button {
      background-color: var(--cx-color-primary);
      color: var(--cx-color-white);
    }
  }
}