.wishlist-container {
  z-index: 99999;
  position: fixed;
  width: 380px;
  box-shadow: var(--common-box-shadow);
  background: var(--cx-color-white);
  right: 0;
  top: 0;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;

  .wishlist {
    padding: 10px;
    height: 44px;
    border-bottom: 1px solid var(--cx-color-black);

    .cancel-img {
      width: 25px;
    }

    .mat-mdc-unelevated-button.mat-primary {
      background-color: var(--cx-color-primary2);
      padding: 5px;

      &.sitelinks-close {
        min-width: 25px;
        padding: 0;

        span {
          color: var(--cx-color-white);
        }
      }

      &.cdk-program-focused,
      &.cdk-focused {
        color: var(--cx-color-white);
        background-color: var(--cx-color-primary2);
      }
    }
  }

  .copy-radio-option-style {
    display: flex;
    align-items: center;
    width: 333px;
    height: 48px;
    background: var(--cx-color-white);
    border: 1px solid var(--cx-color-primary2);
  }

  .copy-action-style {
    height: 73px;
    background: var(--cx-p2-10);
  }

  .create-new-list-style {
    button {
      width: 333px;
    }

    @include media-breakpoint-down(md) {
      .wishlist-drawer {
        height: 100%;

        .mat-mdc-dialog-container {
          padding: 0;
          width: 285px;

          .mat-mdc-unelevated-button.mat-primary {
            background-color: var(--cx-color-primary2);
            padding: 5px;

            &.sitelinks-close {
              min-width: 25px;
              padding: 0;

              span {
                color: var(--cx-color-white);
              }
            }

            &.cdk-program-focused,
            &.cdk-focused {
              color: var(--cx-color-white);
              background-color: var(--cx-color-primary2);
            }
          }
        }
      }
    }
  }
}
