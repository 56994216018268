body .order-history-list {
    box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
    background: var(--cx-color-white);

    &-heading {
        color: var(--cx-alert);
    }

    .processing,
    .approved,
    .open,
    .order.recieved,
    .created,
    .pending.approval,
    .unapproved {
        margin: 0px;
        color: var(--cx-color-white);
        background-color: var(--status-blue);
    }

    .shipped,
    .completed,
    .shipping {
        color: var(--cx-color-white);
        background-color: var(--cx-color-organic-green);
        margin: 0px;
    }

    .partially.shipped,
    .backordered,
    .waiting.return {
        background-color: var(--status-orange);
        color: var(--cx-color-white);
        margin: 0px;
    }

    .suspended {
        background-color: var(--status-grey);
        color: var(--cx-color-white);
        margin: 0px;
    }

    .cancelled,
    .rejected,
    .modified.cancelled,
    .on.hold {
        background-color: var(--cx-warning-error);
        margin: 0px;
        color: var(--cx-color-white);
    }

}