.inventory-transaction {
  gap: 16px;
  &.expand-table {
    background-color: var(--cx-color-white);
    box-shadow: var(--common-box-shadow);

    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
    height: 100vh;
    overflow-y: auto;
  }

  &-filter {
    padding: 12px;
    box-shadow: var(--common-box-shadow);
    background-color: var(--cx-color-white);
    min-width: 100%;
    gap: 6px;

    .section-top {
      border-bottom: 1px solid var(--cx-product-border);

      .li-gap {
        gap: 10px;
      }
    }

    .view-select {
      margin-bottom: -15px;
    }

    .select-section {
      mat-select {
        padding-top: 6px;
      }

      .mat-mdc-text-field-wrapper.mdc-text-field--outlined
        .mdc-notched-outline--upgraded
        .mdc-floating-label--float-above {
        --mat-mdc-form-field-label-transform: translateY(-27px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
      }

      .mat-mdc-text-field-wrapper
        .mat-mdc-form-field-flex
        .mat-mdc-floating-label {
        top: 18px;
        left: 3px;
      }

      .mdc-text-field--outlined {
        height: 36px;
      }

      .mat-mdc-text-field-wrapper.mdc-text-field--outlined
        .mat-mdc-form-field-infix {
        padding: 0px;
      }
    }

    .active {
      background-color: var(--sr-blue-tag);
    }

    .nav-menu {
      color: var(--cx-mat-input-color);
    }

    .nav-btn {
      // height: 30px !important; //necessary for design
      padding: 5px 12px;
      gap: 10px;
      .filter-icon {
        width: 12px;
        height: auto;
      }

      mat-icon.action-icon {
        width: 10px;
        height: auto;
        margin: 0;
      }
    }

    .search {
      border: 1px solid var(--cx-color-primary);
      border-radius: 5px;
      height: 36px;
      flex: 1;

      input {
        color: var(--cx-color-primary);
      }
    }

    .section-mid {
      gap: 17px;
    }

    .section-bottom {
      gap: 16px;
      flex-direction: row;
      mat-form-field.view-select {
        width: 153px;
      }
      .expand-icon {
        border: 1px solid;
        padding: 2px 3px 6px 3px;
        height: 30px;
        width: 30px;

        &:hover {
          cursor: pointer;
        }
      }

      .slide-gap {
        gap: 16px;

        label {
          margin-bottom: 0 !important;
          margin-right: 6px;
        }
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
        flex-direction: column;
        position: relative;

        mat-form-field.view-select {
          width: 100% !important;
          align-self: flex-start;
        }
        
        // .expand-icon {
        //   position: absolute;
        //   right: 0;
        //   top: 0 !important;
        // }
        
        .calender {
          width: 100%;
        }
      }
    }
  }

  &-table {
    .matSortIcon{
      width: 14px;
    }
    .rotated {
      .matSortIcon {
        transform: rotate(180deg);
      }
    }
    th{ 
      white-space: nowrap;
    }
    box-shadow: var(--common-box-shadow);
    overflow-x: auto;
    table.inventory-transaction-table {
      width: 2200px;
      tr {
        th:first-of-type {
          width: 77px;
        }
        th:nth-of-type(2) {
          width: 64px;
        }
        th:nth-of-type(3) {
          width: 110px;
        }
        th:nth-of-type(4) {
          width: 150px;
        }
        th:nth-of-type(5) {
          width: 250px;
        }
        th:nth-of-type(6) {
          width: 76px;
        }
        th:nth-of-type(7) {
          width: 92px;
        }
        th:nth-of-type(8) {
          width: 107px;
        }
        th:nth-of-type(9) {
          width: 82px;
        }
        th:nth-of-type(10) {
          width: 115px;
        }
        th:nth-of-type(11) {
          width: 117px;
        }
        th:nth-of-type(12) {
          width: 112px;
        }
        th:nth-of-type(13) {
          width: 77px;
        }
        th:nth-of-type(14) {
          width: 117px;
        }
        th:nth-of-type(15) {
          width: 114px;
        }
        th:nth-of-type(16) {
          width: 114px;
        }
      }
    }
    .inventoryTransaction-hyperlink{
      color: var(--hyperlink-blue);
    }
  }

  &-footer {
    box-shadow: var(--common-box-shadow);
    background-color: var(--cx-color-white);
    padding: 12px;
    border-radius: 0px 0px 7px 7px;
  }
}

//for filter section
.inventory-transacrion-filter {
  .filter-menu-item {
    width: 100%;
  }
}
