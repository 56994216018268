.container-width {
  width: 300px;
  margin: 0;
  padding: 0;
}

.create-list {
  background: var(--cx-color-white);
  .flex-item-left {
    flex: 10%;
  }


}

.btn-bg {
  background-color: var(--cx-color-primary2);
  color: white;
}

.create-info {
  align-items: flex-start;
  padding: 16px;
  width: 100%;
  border-top: 1px solid black;
}

.info-text {
  border: 1px solid var(--cx-warning-error);
  background-color: var(--cx-warning-error-light);
  margin-bottom: 20px;
}

.icon-info {
  width: 14px;
  height: 14px;
}