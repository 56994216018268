body {
  .facet-container {
    min-width: 250px;
    background: var(--cx-color-white);
    box-shadow: var(--common-box-shadow);
      input[type=checkbox]{
        outline: auto;
      }
  }
  .filter {
    border-bottom: solid 1px var(--cx-color-primary2);
    border-top: solid 3px var(--cx-color-primary);
  }
  .mat-mdc-unelevated-button:not(:disabled).clearFilter {
    padding: 5px 10px;
    color: var(--cx-color-white);
    background-color: var(--cx-clearFilter);
  }
  .mat-mdc-standard-chip {
    height: auto;
    border-radius: 4px;
  }
  
  .mat-mdc-chip-listbox {
    .mat-mdc-chip.mat-mdc-chip-option {
      margin: 10px 0px 0px 14px;
      background-color: var(--cx-color-white);
      border: 1px solid var(--cx-color-primary2);
      padding: 7px 8px;
      max-width: 238px;
      &:hover {
        background-color: var(--cx-color-white);
      }
      .mdc-evolution-chip__action {
        &.mdc-evolution-chip__action--primary {
          pointer-events: none;
        }
        .mdc-evolution-chip__graphic  {
          display: none;
        }
      }
      .mdc-evolution-chip__text-label {
        font-weight: 500;
      }
    }
  }

  .mdc-evolution-chip__text-label {
   white-space: normal;
   word-wrap: break-word;
  }

  .mat-mdc-chip.mat-mdc-standard-chip .mat-mdc-chip-remove {
    opacity: inherit;
    &:hover {
      opacity: 0.5;
    }
  }
  .showMore {
    color: var(--show-more-color);
    text-decoration: underline;
    cursor: pointer;
    padding-left: 18px;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  .accordion .card {
    margin: 0;
  }
  .accordion .card-header {
    padding: 2px;
  }
  .accordion .card-body {
    padding: 8px;
  }
  .facetItem {
    margin-left: 35px;
  }
  .facetItemCount {
    padding: 0 4px;
    background-color: var(--cx-facet-count);
    font-weight: 800;
  }
  .accordion .card-body {
    padding: 12px;
  }
  .accordion .card-header h5 .btn[aria-expanded="true"]:after {
    content: url(../../../assets/dropdown.svg);
    color: var(--cx-color-black);
    bottom: 7px;
  }
  .accordion .card-header h5 .btn[aria-expanded="false"]:after {
    content: url(../../../assets/dropdownUp.svg);
    color: var(--cx-color-black);
    bottom: 7px;
  }
  .accordion .card-header h5 .btn[aria-expanded="true"] {
    color: var(--cx-color-black);
  }
  .accordion .card-header h5 .btn {
    color: var(--cx-color-black);
    padding: 12px 14px;
  }
  .displaySlider {
    @include media-breakpoint-down(lg) {
      display: none;
      &.sidebar-slider {
        display: block;
      }
    }
  }
  .accordion .card-header {
    background-color: var(--cx-color-white);
    border-top: solid 1px var(--cx-color-primary2);
    border-bottom: solid 1px var(--cx-color-primary2);
    color: var(--cx-color-black);
    border-left: 0;
    border-right: 0;
    max-height: 45px;
    &:focus,
    &:focus-within,
    &:focus-visible {
      border-top: solid 1px var(--cx-color-primary2);
      border-bottom: solid 1px var(--cx-color-primary2);
      border-left: 0;
      border-right: 0;
    }
  }
  .sidebar-opener {
    cursor: pointer;
  }

  .sidebar-slider {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    transform: translateX(-100%);
    transition: ease-in-out 300ms transform;
    overflow: auto;
    z-index: 9999;
  }

  .sidebar-slider.sidebar-slide-in {
    transform: translateX(0%);
    transition: ease-in-out 600ms transform;
  }
  .search-filter-backdrop{
      position: absolute;
      top: -145px;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1000;
      pointer-events: auto;
      -webkit-tap-highlight-color: transparent;
      transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
      opacity: 1;
      background: #00000052;
  }
  .mat-mdc-chip-remove .mat-icon {
    width: 24px;
    height: 24px;
  }
}
