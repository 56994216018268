.other-ship-to-client-patient-container {
  
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 7px;
    background: var(--cx-p10);
    box-shadow: inset 0 0 4px var(--cx-p10);
  }
  &::-webkit-scrollbar-thumb {
    background: var(--cx-table-scroll);
    border-radius: 10px;
  }
  
  position: fixed;
  width: 368px;
  box-shadow: var(--common-box-shadow);
  background: var(--cx-color-white);
  right: 0;
  top: 0;
  height: 100vh;
 

  .other-ship-to-address-header-border{
    padding: 10px;
    height: 44px;
    border-bottom: 1px solid var(--cx-color-black);
    .mat-mdc-unelevated-button.mat-primary {
      background-color: var(--cx-color-primary2);
      padding: 5px;
      &.sitelinks-close {
          min-width: 25px;
          padding: 0;
          span {
              color: var(--cx-color-white);
          }
      }
      &.cdk-program-focused , &.cdk-focused {
          color: var(--cx-color-white);
          background-color: var(--cx-color-primary2);
      }
  }
  }

  .other-ship-to-address-block{
    border: 1px dashed var(--side-nav-border-bottom);
    border-radius: 7px;
    .other-ship-to-address-block-margin{
      border: 1px solid var(--cx-hr-border);
    }
  }
  .other-ship-to-client-patient-button{
    .other-ship-to-client-patient-action{
      padding: 0px;
      height: 30px;
    }
  }
  .add-address-icon {
    font-size: 30px;
  }
}