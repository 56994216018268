.wro-list {
  .no-pointer {
    cursor: default;
    pointer-events: none;
  }
  gap: 20px;
  &-draft__section {
    padding: 12px;
    flex-direction: row;
    box-shadow: var(--common-box-shadow);
    background-color: var(--cx-color-white);

    .text-section {
      gap: 4px;

      // p:first-of-type {
      //   font-weight: 600;
      // }
      p:last-of-type {
        color: var(--cx-wro-sub-heading);
      }
    }

    .draft-action {
      margin-left: auto;
      justify-content: center;

      button {
        background-color: var(--cx-color-primary);
        color: var(--cx-color-white);
        border: none;
        padding: 5px 12px;
        border-radius: 5px;
        cursor: pointer;
      }

      @include media-breakpoint-down(md) {
        margin-left: 0;
        justify-content: start;
      }
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;
      gap: 12px;
    }
  }

  &-draft-table__container {
    overflow-x: auto;

    table.draft-table {
      th:first-of-type {
        width: 300px;
        min-width: 250px;
      }
      th:nth-of-type(2) {
        width: 140px;
        min-width: 110px;
      }
      th:nth-of-type(3) {
        width: 110px;
        min-width: 90px;
      }
      th:nth-of-type(4) {
        width: 90px;
        min-width: 75px;
      }
      th:nth-of-type(5),
      th:nth-of-type(6),
      th:nth-of-type(7) {
        width: 150px;
        min-width: 120px;
      }
    }
    .wroDraftList-hyperlink{
      color: var(--hyperlink-blue);
      text-decoration: underline;
    }
  }

  &-main-table__container {
    overflow-x: auto;

    table.wro-list-table {
      th:first-of-type {
        width: 80px;
        min-width: 60px;
      }
      th:nth-of-type(2) {
        width: 130px;
        min-width: 100px;
      }
      th:nth-of-type(3),
      th:nth-of-type(4) {
        width: 145px;
        min-width: 110px;
      }
      th:nth-of-type(5) {
        width: 95px;
        min-width: 75px;
      }
      th:nth-of-type(6) {
        width: 110px;
        min-width: 80px;
      }
      th:nth-of-type(7),
      th:nth-of-type(8) {
        width: 85px;
        min-width: 65px;
      }
      th:nth-of-type(9),
      th:nth-of-type(10) {
        width: 150px;
        min-width: 120px;
      }
    }
    .wroList-hyperlink{
      color: var(--hyperlink-blue);
      text-decoration: underline;
    }
  }

  span.status-btn {
    text-align: center;
    color: var(--cx-color-white);
    border: none;
    padding: 2px 4px;
    border-radius: 3px;

    &.status-draft-btn {
      background-color: var(--bxd-wro-list-draft-btn);
    }

    &.status-received-complete-btn {
      background-color: var(--cx-warning-success);
    }

    &.status-awaiting-receipt-btn {
      background-color: var(--future-inactive);
    }

    &.status-partially-received-btn {
      background-color: var(--bxd-wro-list-partially-receivced-btn);
    }

    &.status-cancelled-btn {
      background-color: var(--bxd-wro-list-cancelled-btn);
    }
  }
}
