.bxd-product {
  gap: 1rem;
  .product-list,
  .product-details,
  .inventory-location-table,
  .inventory-status-table,
  .product-footer {
    background-color: var(--cx-color-white);
    box-shadow: var(--common-box-shadow);
  }

  .product-list {
    padding: 12px;
    gap: 16px;

    &__content {
      gap: 10px;
      flex-direction: row;
      &-img {
        gap: 10px;
        .img {
          width: 88px;
          height: 88px;
          border-radius: 12px;
          padding: 10px;
          border: 1px solid var(--bxd-product-details-img-border);
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .main-section {
          gap: 6px;
          width: 600px;
          p {
            margin: 0;
          }
  
          &__id {
            font-weight: 700;
            span {
              font-weight: 400;
            }
          }
  
          &__action {
            padding: 10px 0;
            gap: 10px;
  
            button {
              border-radius: 3px;
              padding: 2px 4px;
              max-width: 50px;
              // word-wrap: break-word;
              color: var(--cx-color-white);

              &.active {
                background-color: var(--cx-warning-success);
              }
              &.future-inactive {
                background-color: var(--future-inactive);
              }
              &.draft {
                background-color: var(--status-orange);
              }
              &.inactive {
                background-color: var(--status-grey);
              }
            }
          }

          @include media-breakpoint-down(md) {
            width: 500px;
          }
          @include media-breakpoint-down(sm) {
            width: auto;
          }
        }
      }

      &-main {
        padding: 10px;
        gap: 6px;

        .edit-btn {
          margin-left: auto;
          button {
            padding: 5px 12px;
            border-radius: 5px;
            color: var(--cx-color-white);
            background-color: var(--cx-color-primary);
          }

          @include media-breakpoint-down(sm) {
            margin-left: 0;
          }
        }
      }

      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }
    }

    &__card-separator {
      height: 1px;
      background-color: var(--cx-table-vertical-border);
    }

    &__status {
      padding: 6px;
      gap: 10px;

      .icons {
        gap: 16px;

        .icon {
          mat-icon {
            height: 24px;
            width: 24px;
          }
        }

        .icon.rx-flag-icon {
          mat-icon {
            height: 30px;
            width: 28px;
          }
        }
        .icon.lithium-icon {
          mat-icon {
            height: 40px;
            width: 38px;
          }
        }

        .custom-prop-icon {
          position: relative;
          width: 24px;
          height: 24px;
        }
        
        .custom-prop-icon mat-icon {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        
        .custom-prop-icon mat-icon[svgIcon="bxd_prop_65_bg"] {
          z-index: 1;
        }
        
        .custom-prop-icon mat-icon[svgIcon="bxd_prop_65"] {
          z-index: 2;
        }
      }
    }
  }

  // product details
  .product-details {
    padding: 12px;
    gap: 16px;
    background-color: var(--cx-color-white);

    &__heading {
      padding: 6px;
    }

    &__container {
      padding: 6px;
      gap: 16px;

      &-generalSection {
        gap: 16px;

        .fields {
          gap: 32px;
          flex-wrap: wrap;
          justify-content: start;
          align-items: baseline;

          @include media-breakpoint-down(sm) {
            gap: 16px;
          }
          
          // input styling
          & >  mat-form-field {
            max-width: 242px;
            .mdc-text-field--disabled .mdc-notched-outline__leading,
            .mdc-text-field--disabled .mdc-notched-outline__notch,
            .mdc-text-field--disabled .mdc-notched-outline__trailing {
              border-color: var(--cx-mat-outline);
            }

            & .mdc-text-field__input {
              font-size: 0.75rem;
              font-weight: 400;
              line-height: 1.25rem;
              color: var(--cx-mat-input-color);
            }

            .t-bxdPortal-inputLabel {
              color: var(--cx-mat-input-color);
            }

            @include media-breakpoint-down(sm) {
              max-width: 100%;
            }
          }
        }

        // inner-separator applied only in dimensions and weight section
        .inner-separator {
          gap: 32px;

          .t-bxdPortal-inputLabel {
            font-size: 0.75rem;
            font-weight: 400;
            line-height: 1.5rem;
            letter-spacing: 0.5px;
            color: var(--cx-mat-input-color);
          }

          & >  mat-form-field {
            max-width: 242px;
            .mdc-text-field--disabled .mdc-notched-outline__leading,
            .mdc-text-field--disabled .mdc-notched-outline__notch,
            .mdc-text-field--disabled .mdc-notched-outline__trailing {
              border-color: var(--cx-mat-outline);
            }
          }

          @include media-breakpoint-down(sm) {
            flex-wrap: wrap;
            gap: 16px;
          }
        }

        .separator {
          height: 1px;
          background-color: var(--cx-table-vertical-border);
        }

        // Lot and Serial & Unit of Measure
        &.lot-serial,
        &.unit-of-measure,
        &.dimensions-weight-sellable {
          mat-form-field {
            max-width: 170px;

            @include media-breakpoint-down(sm) {
              max-width: 140px;
            }
          }
        }

        &.dimensions-weight-sellable {
          .fields {
            gap: 16px;
          }
        }

        &.dimensions-weight-sellable.classifications {
          @include media-breakpoint-down(sm) {
            .inner-separator {
              flex-direction: column !important;
              gap: 16px;
            }
            mat-form-field {
              max-width: 198px;
            }
          }
        }
      }
    }
  }
  .inventory-location-table,
  .inventory-status-table {
    padding: 12px;
    gap: 16px;
    overflow: auto;

    

    table {
      box-shadow: var(--common-box-shadow);
      width: 100%;
      th,
      td {
        border-right: 1px solid var(--cx-table-vertical-border);
        border-bottom: 2px solid var(--cx-color-white);
        text-align: center !important;
        vertical-align: middle !important;
        padding: 10px;
      }

      td {
        span {
          a {
            color: var(--cx-color-black);
          }
        }
      }
      tr:first-of-type {
        border-bottom: 3px solid var(--cx-color-black);
      }

      tr:nth-child(even) {
        background: var(--cx-product-table-bg);
      }

      .mat-mdc-header-cell {
        border-bottom: 3px solid var(--cx-color-black);
      }
      // for draggable fix
      .productTableHeader {
        .wrapper {
          display: grid;
          justify-content: center;

          .bar {
            margin: 0px -18px 0 8px;
          }
        }
      }
    }
  }

  // for column width
  .inventory-location-table {
    tr {
      th:first-of-type {
        min-width: 49px;
      }
      th:nth-of-type(2) {
        min-width: 92px;
      }
      th:not(:first-of-type):not(:nth-of-type(2)) {
        min-width: 271px;
      }
    }
  }

  .inventory-status-table {
    table {
      tr {
        th:first-of-type {
          min-width: 49px;
        }
        th:nth-of-type(2) {
          min-width: 92px;
        }
        th:nth-of-type(3) {
          min-width: 110px;
        }
        th:nth-of-type(4) {
          min-width: 246px;
        }
        th:nth-of-type(5) {
          min-width: 137px;
        }
        th:not(:first-of-type):not(:nth-of-type(2)):not(:nth-of-type(3)):not(
            :nth-of-type(4)
          ):not(:nth-of-type(5)) {
          min-width: 104px;
        }
      }
    }
  }
  // footer
  .product-footer {
    background-color: var(--cx-color-white);
    padding: 12px;
    border-radius: 0px 0px 7px 7px;
  }
}
