body {
  .order-status {
    &-one {
      @include media-breakpoint-down(sm) {
        width: 100%;
      }

      text-align: center;
    }

    &-fixedField {
      @include media-breakpoint-down(sm) {
        width: 100%;
        justify-content: space-around;
      }

      &-flex-wrap {
        flex-wrap: wrap;
      }
    }

    &-mobileFixedField {
      width: 100%;
    }

    .mat-mdc-unelevated-button {
      padding: 4px 8px;
      height: 30px;

      @include media-breakpoint-down(sm) {
        width: 33%;
      }
    }

    .mat-icon {
      height: 23px;
      width: 14px;
    }

    &-border {
      border-top: 1px solid var(--cx-color-primary);
      width: 100%;
    }

    &-mobile-flex {
      width: 40%;
      justify-content: space-between;
      display: flex;

      @include media-breakpoint-down(sm) {
        display: block;
        width: 100%;

        .startReturn {
          width: 100%;
        }

        .proofOfDelivery {
          width: 100%;
        }

        .orderAgain {
          width: 100%;
        }

        .edit-cta {
          width: 100%;
        }
      }
    }

    .edit-cta {
      background-color: var(--flag-blue);
      color: var(--cx-color-white);
    }

    .reject-cta,
    .cancel-order-cta {
      background-color: var(--cx-warning-error);
      color: var(--cx-color-white);
    }

    .approve-cta {
      background-color: var(--cx-warning-success);
      color: var(--cx-color-white);
    }

    &-body {

      .other-statuses {
        margin: 0px;
        color: var(--cx-color-white);
        background-color: var(--status-blue);

        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }

      .processing,
      .approved,
      .open,
      .order.recieved,
      .created,
      .unapproved {

        margin: 0px;
        color: var(--cx-color-white);
        background-color: var(--status-blue);

        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }

      .shipped,
      .completed,
      .shipping {
        color: var(--cx-color-white);
        background-color: var(--cx-color-organic-green);
        margin: 0px;

        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }

      .partially.shipped,
      .backordered,
      .waiting.return {
        background-color: var(--status-orange);
        color: var(--cx-color-white);
        margin: 0px;

        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }

      .suspended {
        background-color: var(--status-grey);
        color: var(--cx-color-white);
        margin: 0px;

        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }

      .cancelled,
      .rejected,
      .modified.cancelled,
      .on.hold,
      .pending.approval {
        background-color: var(--cx-warning-error);
        margin: 0px;
        color: var(--cx-color-white);

        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }
    }

    &-head {

      .other-statuses {
        margin: 0px;
        color: var(--cx-color-white);
        padding: 0px 16px;
        background-color: var(--cx-color-darkBlue);

        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }

      .processing,
      .approved,
      .open,
      .order.recieved,
      .created,
      .unapproved {
        color: var(--cx-color-white);
        margin: 0px;
        padding: 0px 16px;
        background-color: var(--cx-color-darkBlue);
      }

      .shipped,
      .completed,
      .shipping {
        color: var(--cx-color-white);
        padding: 0px 16px;
        background-color: var(--cx-warning-success);
      }

      .partially.shipped,
      .backordered,
      .waiting.return {
        background-color: var(--cx-color-darkOrange);
        color: var(--cx-color-white);
        padding: 0px 16px;
      }

      .suspended {
        background-color: var(--cx-color-black);
        color: var(--cx-color-white);
        padding: 0px 16px;
      }

      .cancelled,
      .rejected,
      .modified.cancelled,
      .on.hold,
      .pending.approval {
        background-color: var(--cx-color-darkred);
        color: var(--cx-color-white);
        padding: 0px 16px;
      }
    }
  }
}