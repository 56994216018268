.sales-rep-call-me-container {
  position: fixed;
  width: 368px;
  box-shadow: var(--common-box-shadow);
  background: var(--cx-color-white);
  right: 0;
  top: 0;
  height: 100vh;

 

  .sales-rep-call-me-header-border{
    padding: 10px;
    height: 44px;
    border-bottom: 1px solid var(--cx-color-black);
    .mat-mdc-unelevated-button.mat-primary {
      background-color: var(--cx-color-primary2);
      padding: 5px;
      &.sitelinks-close {
          min-width: 25px;
          padding: 0;
          span {
              color: var(--cx-color-white);
          }
      }
      &.cdk-program-focused , &.cdk-focused {
          color: var(--cx-color-white);
          background-color: var(--cx-color-primary2);
      }
  }
  }

  .sales-rep-call-me-input{
    mat-mdc-form-field {
      display: block;
    }
  }

  .sales-rep-call-me-action-style {
    
    background: var(--cx-p2-10);
    height: 75px;
  }

  .sales-rep-product-code{
    width: fit-content;
    height: 24px;
    background: var(--cx-drop-ship);
  }

  .sales-rep-action{
    width:138px;
  }
  
  
}