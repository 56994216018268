// footer distribution sign up component
body {
  background-color: $background-body;

  .footer-dist-sign-up-container {
    background-color: var(--cx-footer-distribution);

    mat-mdc-form-field {
      width: 250px;
    }

    &-sign-up-email-input {
      .mat-form-field-outline {
        background-color: var(--cx-color-white);
        border-radius: 4px;
      }
    }
  }

  .sign-up-email-distribution {
    text-align: center;

    & p {
      color: var(--footer-distribution-text);
    }
  }

  .sign-up-terms-condition {
    text-align: center;
    color: var(--footer-distribution-text);
    width: 145px;

    a {
      color: var(--footer-distribution-text);
    }
  }

  .sign-up-submit-button {
    & button { 
      background-color: var(--navbar-bg) !important; // Imp is needed to override ccv1 migrated css
      color: var(--cx-color-white);
      margin: 0!important;
      &:hover {
        background-color: var(--cx-color-primary) !important; // Imp is needed to override ccv1 migrated css
      }
    }

    span {
      padding: 10px 8px;
    }
  }

  .email-label {
    color: var(--cx-dark-grey);
  }

  //footer custom navigation component

  .navigation-title {
    text-transform: uppercase;
  }

  .footer-nav-grid {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
    column-gap: 180px;

    .ng-star-inserted {
      break-inside: avoid-column;
      width: max-content;
    }

    @include media-breakpoint-down(lg) {
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
      column-gap: 56px;
    }
  }

  .footer-nav-link {
    a {
      &:hover {
        color: var(--cx-color-black);
      }
    }
  }

  .custom-navigation-container {
    background-color: var(--cx-color-white);
  }

  /* style for contact us component*/

  .contactUsSocialMediaContainer {
    background: var(--cx-footer-contact-us);
  }

  //below section html coming from backend that why extending directly in css
  .contact-us-texts {
    & .contact-us-title {
      text-transform: uppercase;
      @extend .t-title-m;
    }

    & .contact-us-address {
      @extend .t-title-light-m, .mb-4;
    }

    & .contact-us-time {
      color: var(--cx-color-secondary2);
      @extend .t-title-m;
    }

    & .contact-us-phone {
      @extend .t-title-m, .mt-2;
    }
  }

  .socialMediaLogo {
    height: 30px;
    width: 30px;
    margin-right: 12px;
  }

  // footer powered by component

  .footer-powered-by-container {
    background-color: var(--navbar-bg);
    .footer-dist-logo {
      width: 223px;
      height: 47px;
    }
  
    .footer-powered-logo {
      width: 121px;
      height: 26px;
    }
    
    .footer-powered-by-text {
      & div {
        //this html coming from backend that why extending directly in css
        @extend .t-body-s;
        background-color: var(--navbar-bg);
        align-items: flex-start;
        color: var(--nav-color);
        font-weight: 500;
        flex-direction: column;
        display: flex;
  
        & p {
          margin-top: 8px;
          text-align: start;
        }
      }
      custom-paragraph{
        .cx-notice{
          padding: 0px 0px;
        }
      }
    }
  }
  //Login footer logo compoment
  .login-footer-powered-by-container {
    .footer-dist-logo {
      width: 223px;
      height: 47px;
    }
    .footer-powered-logo {
      width: 121px;
      height: 26px;
    }
    @include media-breakpoint-down(md) {
      .footer-dist-logo {
        width: 150px;
        height: 15px;
      }
      .footer-powered-logo {
        width: 130px;
        height: 15px;
      }
      img {
        height: initial;
      }
    }
  }
  



  //footer info links component


  .footer-info-link-container {
    background-color: var(--cx-footer-info-link);

    .cx-generic-link,
    a {
      @extend .t-subhead-normal, .mr-3, .footer-info-link-display;
    }

    .hideTabletLS {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    .hideMobile {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  .footer-info-link-display {
    color: var(--cx-dark-grey);

    a {
      &:hover {
        color: var(--cx-dark-grey);
      }
    }
  }

  .FooterSocialMedia {
      cx-generic-link {
        @extend .socialMediaLogo;
      
    }
  }

  // Placing the OOTB spartacus styles for container with important to correct the content page footer .. 
  // In content page CSS we already have important so we need important
  footer {

    .container,
    .container-fluid,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    cx-page-layout.ProductDetailsPageTemplate .tab-delivery,
    cx-page-layout.ProductDetailsPageTemplate cx-page-slot.Summary {
      width: 100% !important;
      padding-right: 0 !important;
      padding-left: 0 !important;
      margin-right: auto !important;
      margin-left: auto !important;
  }
  
  @media (min-width: 576px) {
      .container,.container-sm,cx-page-layout.ProductDetailsPageTemplate .tab-delivery,cx-page-layout.ProductDetailsPageTemplate cx-page-slot.Summary {
          max-width:646px !important;
      }
  }
  
  @media (min-width: 768px) {
      .container,.container-md,.container-sm,cx-page-layout.ProductDetailsPageTemplate .tab-delivery,cx-page-layout.ProductDetailsPageTemplate cx-page-slot.Summary {
          max-width:880px !important;
      } 
  }
  
  @media (min-width: 992px) {
      .container,.container-lg,.container-md,.container-sm,cx-page-layout.ProductDetailsPageTemplate .tab-delivery,cx-page-layout.ProductDetailsPageTemplate cx-page-slot.Summary {
          max-width:960px !important;
      }
    }

    @media (min-width: 992px) {

      .container,
      .container-lg,
      .container-md,
      .container-sm,
      cx-page-layout.ProductDetailsPageTemplate .tab-delivery,
      cx-page-layout.ProductDetailsPageTemplate cx-page-slot.Summary {
        max-width: 960px !important;
      }
    }

    @media (min-width: 1200px) {

      .container,
      .container-lg,
      .container-md,
      .container-sm,
      .container-xl,
      cx-page-layout.ProductDetailsPageTemplate .tab-delivery,
      cx-page-layout.ProductDetailsPageTemplate cx-page-slot.Summary {
        max-width: 1140px !important;
      }
    }

    .container.content-powered-by {
      padding: 3rem 0 !important;// this is needed for content page. Important is needed as we need to override the css applied by components in content page
    }

    .container.content-info-link {
      padding: 1rem 0 !important; // this is needed for content page. Important is needed as we need to override the css applied by components in content page
    }

  }
}





app-custom-banner {
  a {
    display: block;
    &:focus {
      position: relative;
      z-index: 1;
    }
  }

  cx-generic-link {
    display: block;
  }

  img {
    width: 100%;
    margin: var(--cx-margin);
  }

 @include media-breakpoint-down(md) {
  img {
    height: 50px;
  }
}
  p {
    font-size: 4vw;
    line-height: 5vw;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

    position: absolute;
    z-index: 10;
    padding: 5vw;
    color: #fff;

    width: 100%;
    text-align: center;
    margin: 0;
  }
}