.brands-container {
    .brands-container-main {
        padding: 0 calc((100% - var(--xl-max-width)) / 2);
    }

    .title-background {
        background: var(--cx-color-white);
    }

    .keys {
        letter-spacing: 24.1px;
        color: var(--cx-color-primary);
    }

    .backToTop {
        height: 16px;
        width: 16px;
    }

    .brands-row {
        border-radius: 7px;
        background-color: var(--cx-color-white);
    }

    .alphabet {
        border-bottom: 1px solid var(--cx-color-primary2);
    }

    .new-icon {
        position: relative;
        bottom: 2px;
        background: var(--cx-color-green);
        border-radius: 8px;
        padding: 1px 5px;
        width: 34px;
        height: 13px;
        color: var(--cx-color-white);
    }
    .disabled {
        opacity: 0.5;
        cursor: default;
      }
}

.product-search-container {
    .brand-details {
        background: var(--cx-color-white);
        box-shadow: var(--common-box-shadow);
        &-img {
            img {
                width: 100px;
                height: 100px;
            }
        }
        &-btn {
            margin-left: auto;
            @include media-breakpoint-down(lg) {
                margin-left: 0;
            }
            a {
                width: 110px;
                padding: 10px 24px;
            }
        }
    }

    .search-header {
        &-width {
          width: 70%;
          @include media-breakpoint-down(lg) {
            width: 100%;
          }
        }
        &-brand {
          &.show-ellipses {
            max-width: 80%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            display: inline-block;
            @include media-breakpoint-down(lg) {
                max-width: 65%;
            }
          }
          &-link {
            color: var(--cx-alert);
          }
          @include media-breakpoint-down(lg) {
            max-width: 90%;
          }
        }
      }
}
