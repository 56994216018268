body{

  
  .favorite-product-container {
    background: var(--cx-color-white);
    box-shadow: var(--common-box-shadow);
    border-radius: 8px;
    &.disabledTile {
      background-color: var(--btn-primary-disabled-bg);
      color: var(--btn-disabled-txt-clr);
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.38;
      }

          // Input Section Styling
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
      }

      /* Firefox */
      input[type=number] {
          -moz-appearance: textfield;
      }

      .qty-error-box-enter-qty{
        width: 228px;
        height: 16px;
        color: var(--cx-color-white);
        text-align: center;
        background: var(--cx-warning-error);
      }
      .qty-error-box-max{
        width: 228px;
        height: 16px;
        color: var(--cx-color-white);
        text-align: center;
        background: var(--cx-color-secondary);
      }
      .qty-error-box-multiple{
        width: 228px;
        height: 16px;
        color: var(--cx-color-white);
        text-align: center;
        background: var(--cx-multiple-order);
      }
      .qty-red-box-multiple{
        width: 228px;
        height: 16px;
        color: var(--cx-color-white);
        text-align: center;
        background: var(--cx-warning-error);
      }

    .product-tile-image{
      img{
          width: 151px;
          min-height: 151px;
          max-height: 151px;
      }
    }
    
    .inventory-status-in-stock-box{
      width: 58px;
      height: 24px;
      padding: 4px 5px;
      background: var(--cx-multiple-order);
      color: var(--cx-color-white);
    }
    
    .inventory-status-discontinued-box{
      padding: 4px 5px;
      width: 88px;
      height: 24px;
      background: var(--yellow-bg-color);
      color: var(--cx-color-black);
    }
    
    .inventory-status-out-of-stock-box{
      padding: 4px 5px;
      width: 82px;
      height: 24px;
      background: var(--cx-alert);
      color: var(--cx-color-white);
    }
    
    .inventory-status-back-ordered{
      padding: 4px 5px;
      width: 88px;
      height: 24px;
      background: var(--cx-alert);
      color: var(--cx-color-black);
    }
    
    .inventory-status-special-order{
      padding: 4px 5px;
      width: 88px;
      height: 24px;
      background: var(--cx-color-black);
      color: var(--flag-blue);
    }
    
    .inventory-status-text{
      width: 96px;
      height: 16px;
      color: var(--cx-mat-outline);
    }
    
    .product-description{
      width: 235px;
    }
    
    .product-sku-details{
      width: 235px;
    }

    .remove-icon{
      cursor: pointer;
      .remove-icon-style{
        width: 16px;
        height: auto;
      }
    }
      
    .quantity__minus{
      box-sizing: border-box;
      width: 46px;
      height: 45px;
      background: var(--cx-color-white);
      border: 1px solid var(--cx-color-primary);
      box-shadow: 0 2px 2px rgb(0 0 0 / 25%);
      border-radius: 5px 0px 0px 5px;
    }
    .quantity__plus {
      @extend .quantity__minus;
      border-radius: 0 5px 5px 0;
    }
    .quantity__input {
      width: 52px;
      height: 45px;
      text-align: center;
      margin: 0 5px;
      background: var(--cx-color-white);
      border: 1px solid var(--cx-color-primary);
    }

    .update-button{

      .update-button-style{
        width:70px;
        height:45px;
      }
      button{
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .mat-mdc-checkbox.t-productTile-productGroup {
      .mdc-form-field {
        label {
          padding: 0;
          margin: 0;
        }
      }
    }
  }
}