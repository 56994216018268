body {
  .items-added-to-cart {
    &-action-btn {
      height: 44px;
      border: 1px solid var(--cx-color-primary);
      box-shadow: none;
    }

    &-icon {
      width: 65px;
      height: 60px;
    }
  }
}