.bxd-returns-table {
  table {
    width: 150%;
    th {
      .content {
        display: flex;
        align-items: center;
        justify-content: center;

        mat-icon {
          height: 24px;
          width: 16px;
          transition: rotate 0.3s ease-in-out;
        }

        mat-icon.active {
          transform: rotate(180deg);
        }
      }
    }
    tr {
      color: var(--cx-color-black);
      th:first-of-type {
        width: 70px;
        min-width: 60px;
      }
      th:nth-of-type(2) {
        width: 110px;
        min-width: 90px;
      }
      th:nth-of-type(3) {
        width: 80px;
        min-width: 70px;
      }
      th:nth-of-type(4) {
        width: 100px;
        min-width: 90px;
      }
      th:nth-of-type(5) {
        width: 110px;
        min-width: 90px;
      }
      th:nth-of-type(6) {
        width: 70px;
        min-width: 60px;
      }
      th:nth-of-type(7) {
        width: 150px;
        min-width: 130px;
      }
      th:nth-of-type(8) {
        width: 95px;
        min-width: 80px;
      }
      th:nth-of-type(9),
      th:nth-of-type(10) {
        width: 90px;
        min-width: 80px;
      }
      th:nth-of-type(11) {
        width: 260px;
        min-width: 240px;
      }
      th:nth-of-type(12) {
        width: 100px;
        min-width: 90px;
      }
      th:nth-of-type(13) {
        width: 90px;
        min-width: 80px;
      }
      th:nth-of-type(14),
      th:nth-of-type(15) {
        width: 120px;
        min-width: 110px;
      }

      td:nth-child(2) {
        text-decoration: none;
      }
      td:nth-child(4) {
        color: var(--cx-color-black);
        text-decoration: none;
        a {
          color: var(--cx-color-black);
          text-decoration: none;
        }
      }
      td:nth-child(5) {
        color: var(--status-blue);
        text-decoration: underline;
      }

      td {
        .return-col-with-icon {
          gap: 9px;
        }
      }
    }
  }
}
