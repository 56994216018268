.order {
 
  &-report-query {
    //height: 370px;
    background: var(--reports-bg);
    border: 1px solid var(--cx-p2-40);
 
    .hide-icon {
      display: none;
    }
 
    &-search {
      border-top: 1px solid var(--cx-color-black);
    }
  }
 
  &-report {
    .reports-main {
      &-table-section.table-responsive {
        background: var(--cx-p10);
 
        .scrollable-table-2 {
          overflow-x: scroll;
          &::-webkit-scrollbar {
            height: 7px;
            background: var(--cx-p10);
            box-shadow: inset 0 0 4px var(--cx-p10);
          }
 
          &::-webkit-scrollbar-thumb {
            background: var(--cx-table-scroll);
            border-radius: 10px;
          }
        }
      }
      .modify-search-btn {
        flex-direction: row-reverse;
        mat-icon {
          height: 18px;
          width: 18px;
        }
      }
    }
 
    table {
      &.table-1.backorder {
        width: 95%;
      }
      &.table-2 {
        width: 1440px !important; // Giving important to fix the max resizing of the table
        &.ebill {
          width: 2200px !important; // Giving important to fix the max resizing of the table
          .mat-column-Shipped
          {
            width: 100px;
            max-width: 100px;
          }
          .mat-column-Ship-To-Account-Name{
            width: 80px;
            max-width: 80px;
          }
          .mat-column-Address-1{
            width: 80px;
            max-width: 80px;
          }
          .mat-column-Address-2 {
            width: 60px;
            max-width: 60px;
          }
          .mat-column-Description{
            width: 100px;
            max-width: 100px;
          }
          .mat-column-City{
            width: 80px;
            max-width: 80px;
          }
          .mat-column-UOM {
            width: 60px;
            max-width: 60px;
          }
          .mat-column-Track {
            width: 80px;
            max-width: 80px
          }
          .mat-column-NDC-- {
            width: 60px;
            max-width: 60px
          }
          .mat-column-Zip {
            width: 50px;
            max-width: 50px;
          }
          .mat-column-State{
            width: 50px;
            max-width: 50px;
          }
          .mat-column-Shipped-Qty{
            width: 40px;
            max-width: 40px;
          }
          .mat-column-Invoice-no-{
            width: 80px;
            max-width: 80px;
          }
        }
        &.item-purchase{
          width: 2200px !important;
          .mat-column-PO {
            width: 60px;
            max-width: 60px;
          }
          .mat-column-Invoice-no-{
            width: 60px;
            max-width: 60px;
          }
          .mat-column-Ordered ,
          .mat-column-Shipped
          {
            width: 70px;
            max-width: 70px;
          }
          .mat-column-Ship-To-Account-Name{
            width: 80px;
            max-width: 80px;
          }
          .mat-column-Address-1{
            width: 80px;
            max-width: 80px;
          }
          .mat-column-Address-2{
            width: 60px;
            max-width: 60px;
          }
          .mat-column-Description{
            width: 80px;
            max-width: 80px;
          }
          .mat-column-City{
            width: 80px;
            max-width: 80px;
          }
          .mat-column-State{
            width: 60px;
            max-width: 60px;
          }
          .mat-column-Zip {
            width: 50px;
            max-width: 50px;
          }
          .mat-column-Product-Class {
            width: 80px;
            max-width: 80px;
          }
        }
        &.backorder {
          .mat-column-Shipped
          {
            width: 100px;
            max-width: 100px;
          }
          .mat-column-Ship-To-Account-Name{
            width: 80px;
            max-width: 80px;
          }
          .mat-column-Address-1{
            width: 80px;
            max-width: 80px;
          }
          .mat-column-Address-2 {
            width: 60px;
            max-width: 60px;
          }
          .mat-column-Description{
            width: 80px;
            max-width: 80px;
          }
          .mat-column-City{
            width: 80px;
            max-width: 80px;
          }
          .mat-column-UOM {
            width: 60px;
            max-width: 60px;
          }
          .mat-column-Track {
            width: 80px;
            max-width: 80px
          }
          .mat-column-NDC-- {
            width: 60px;
            max-width: 60px
          }
          .mat-column-Zip {
            width: 60px;
            max-width: 60px;
          }
        }
      }

      &.ebill , &.backorder {
        .mat-column-PO, .mat-column-Ln {
          width: 100px !important;
          max-width: 100px;
          background-color: var(--cx-p10);
        }
 
        .mat-column-Ordered ,
        .mat-column-Shipped ,
        .mat-column-Delivered,
        .mat-column-Item-,
        .mat-column-Ship-To-Account-Name,
        .mat-column-Order-Type {
          width: 100px;
          max-width: 100px;
        }
 
        .mat-column-Carrier, .mat-column-Mfg-Item, .mat-column-City {
          width: 60px;
          max-width: 60px;
        }
 
        .mat-column-Brand , .mat-column-HCPCS , .mat-column-NDC- , .mat-column-WH, .mat-column-Shipped-Qty  {
          width: 80px;
          max-width: 80px;
        }
 
        .mat-column-Track {
          width: 150px;
          max-width: 150px;
        }
 
        .mat-column-Address-1 , .mat-column-Address-2 {
          width: 130px;
          max-width: 130px;
        }
 
        .mat-column-State {
          width: 70px;
          max-width: 70px;
        }
 
        .mat-column-Action {
          width: 40px;
          max-width: 40px;
        }
 
        .mat-header-row {
          .mat-column-PO {
            background-color: var(--cx-p2-20);
          }
        }
 
        .mat-column-Track {
          .t-table-s {
            color: var(--cx-table-tracking);
          }
        }
      }
 
      tr.mat-mdc-header-cell ,
      tr.mat-mdc-row,
      th.mat-mdc-header-cell ,
      th.mat-mdc-row {
        height: 45px;
      }
 
      th.mat-header-cell,
      td.mat-cell,
      td.mat-footer-cell {
        padding: 10px;
        text-align: center;
 
        &:first-of-type {
          padding-left: 10px;
        }
      }
 
      th {
        background-color: var(--cx-p2-20) !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
 
        span.t-table-m {
          color: var(--cx-table-header);
        }
      }
 
      td {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
 
        span.t-table-s {
          color: var(--cx-table-column);
        }
        &.mat-column-select:last-child {
          border-right: 1px solid rgba(0, 0, 0, 0.12);
        }
      }
 
      tr:nth-child(even) {
        background: var(--cx-p2-10);
      }
 
      .mat-column-Account,
      .mat-column-Order--,
      .mat-column-Ship-To-Account-Number,
      .mat-column-Invoice-no-,
      .mat-column-Order-Type,
      .mat-column-Ln {
        width: 180px !important; // Important is needed here as we need to override so mat table applied inline width
        background-color: var(--cx-p10);
        max-width: 180px;
      }
 
      .mat-column-Product-Class {
        width: 120px;
        max-width: 120px;
      }
 
      .mat-header-row {
 
        .mat-column-Account,
        .mat-column-Order--,
        .mat-column-Ship-To-Account-Number,
        .mat-column-select,
        .mat-column-Order-Type,
        .mat-column-Ln {
          background-color: var(--cx-p2-20);
        }
      }
 
      .mat-column-Order-- {
        a.t-table-s {
          color: var(--cx-table-order-col);
          text-decoration: underline;
        }
      }
 
      .mat-column-select {
        background-color: var(--cx-p10);
        border: 0;
        // width: 50px;
        // max-width: 50px;
        mat-checkbox {
          .mat-checkbox-frame {
            height: 16px;
            width: 16px;
            background-color: var(--cx-color-white);
            border-radius: 4px;
            border-color: transparent;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
          }
          &.mat-checkbox-disabled {
            opacity: 0.35;
            cursor: not-allowed;
          }
        }
        .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
        .mat-checkbox-checked.mat-accent .mat-checkbox-background {
          background-color: var(--cx-color-white);
        }
      }
 
      .mat-column-Brand,
      .mat-column-Item- {
        width: 90px;
        max-width: 90px;
      }
 
      .mat-column-PO {
        width: 70px;
        max-width: 70px;
      }
 
      .mat-column-Ordered,
      .mat-column-Shipped,
      .mat-column-Status {
        width: 80px;
        max-width: 80px;
      }
 
      .mat-column-Description {
        width: 180px;
        max-width: 180px;
      }
 
      .mat-column-Qty {
        width: 40px;
        max-width: 40px;
 
      }
 
      .mat-column-UOM , .mat-column-BO-Qty {
        width: 60px;
        max-width: 60px;
      }
 
      .mat-column-Price,
      .mat-column-Ext--Price {
        width: 70px;
        max-width: 70px;
      }
 
      .mat-column-Tax {
        width: 60px;
        max-width: 60px;
      }
 
      .mat-column-Action {
        width: 30px;
        max-width: 30px;
      }
    }

    //Action Menu CSS
    .action-menu.mat-mdc-menu-panel {
      background-color: var(--cx-p2-20);
      button.mat-mdc-menu-item {
        outline: none;
        height: 35px;
        line-height: 35px;
        &:disabled {
          opacity: 0.35;
          cursor: not-allowed;
        }
      }
    }
    .mdc-data-table__table {
      min-width: auto !important;
      white-space: nowrap;
      // border-spacing: 0;
    }
    
    .mdc-data-table__cell, .mdc-data-table__header-cell {
      padding: 0 16px 0 16px;
      border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
    }

    .mdc-checkbox__background {
      background-color: white !important;
      border: lightgray !important;
      border-radius: 4px !important;
      box-shadow: 0px 2px 5px 0px #5960781A;
    }
  }
 
  // THIS IS THE CSS FOR RESIZING THE TABLE HENCE WRITTING IT INDEPENDENTLY STARTS//
  &-report {
    padding: 0;
 
    .mat-mdc-table {
 
      //width: 100%;
      &.table-1 {
        width: 50%;
        margin-bottom: 7px;
      }
 
      &.resizing {
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        cursor: col-resize;
      }
 
      .mat-mdc-cell {
        span {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
 
      .mat-mdc-header-cell {
        position: relative;
 
        &:not(:last-child) {
          .resize-holder {
            cursor: col-resize;
            width: 20px;
            height: 100%;
            position: absolute;
            right: -10px;
            top: 0;
            z-index: 1;
          }
        }
      }
 
      .mat-mdc-cell,
      .mat-mdc-header-cell {
        //border-color: var(--cx-table-vertical-border) var(--cx-table-horizontal-border);
        border-right: 1px solid rgba(0, 0, 0, 0.12);
 
        &:not(:nth-child(1)) {
          padding: 0 10px;
        }
      }
    }
  }
  // THIS IS THE CSS FOR RESIZING THE TABLE HENCE WRITTING IT INDEPENDENTLY ENDS//
 
}
 
// THIS IS THE CSS FOR EXPANDED VIEW OF THE TABLE HENCE WRITING IT INDEPENDENTLY - STARTS //
body {
  .reports-main {
    background-color: var(--cx-color-white);
    box-shadow: var(--common-box-shadow);
 
    &-table {
      //min-height: 500px;
      min-height: auto;
    }
 
    &.expand-table {
      position: fixed;
      left: 0;
      top: 0;
      z-index: 99;
      height: 100%;
      overflow-y: auto;
 
      .mat-mdc-table.table-1 {
        width: 30%;
      }
 
      .mat-mdc-table.table-2 {
        width: 100% !important; // Giving important to fix the max resizing of the table
        &.ebill {
          width: 2700px !important; // Giving important to fix the max resizing of the table
          .mat-column-Ship-To-Account-Name{
            width: 120px;
            max-width: 120px;
          }
          .mat-column-Address-1{
            width: 120px;
            max-width: 120px;
          }
          .mat-column-Address-2 {
            width: 80px;
            max-width: 80px;
          }
          .mat-column-Description{
            width: 240px;
            max-width: 240px;
          }
          .mat-column-City{
            width: 100px;
            max-width: 100px;
          }
          .mat-column-UOM {
            width: 100px;
            max-width: 100px;
          }
          .mat-column-Track {
            width: 120px;
            max-width: 120px
          }
          .mat-column-NDC--{
            width: 80px;
            max-width: 80px;
          }
          .mat-column-Zip {
            width: 60px;
            max-width: 60px;
          }
          .mat-column-State{
            width: 60px;
            max-width: 60px;
          }
          .mat-coulmn-Shipped-Qty{
            width: 60px;
            max-width: 60px;
          }
          .mat-column-Invoice-no-{
            width: 80px;
            max-width: 80px;
          }
        }
        &.item-purchase{
          width: 2100px !important; // Giving important to fix the max resizing of the table
          .mat-column-PO {
            width: 80px;
            max-width: 80px;
          }
          .mat-column-Ship-To-Account-Name{
            width: 120px;
            max-width: 120px;
          }
          .mat-column-Address-1{
            width: 120px;
            max-width: 120px;
          }
          .mat-column-Address-2{
            width: 80px;
            max-width: 80px;
          }
          .mat-column-Description{
            width: 220px;
            max-width: 220px;
          }
          .mat-column-City{
            width: 120px;
            max-width: 120px;
          }
          .mat-column-State{
            width: 60px;
            max-width: 60px;
          }
          .mat-column-Zip {
            width: 60px;
            max-width: 60px;
          }
          .mat-column-Product-Class {
            width: 100px;
            max-width: 100px;
          }
          .mat-column-Invoice-no-{
            width: 80px;
            max-width: 80px;
          }
        }
        &.backorder{
          width: 1500px !important; // Giving important to fix the max resizing of the table
          .mat-column-Ship-To-Account-Name{
            width: 120px;
            max-width: 120px;
          }
          .mat-column-Address-1{
            width: 120px;
            max-width: 120px;
          }
          .mat-column-Description{
            width: 220px;
            max-width: 220px;
          }
          .mat-column-City{
            width: 80px;
            max-width: 80px;
          }
          .mat-column-Zip {
            width: 70px;
            max-width: 70px;
          }
          .mat-column-State{
            width: 60px;
            max-width: 60px;
          }
        }
      }
 
      .reorderSelectedGlobal {
        display: none; // hiding the reorder button when table is expanded
      }
 
      .mat-column-Action {
        width: 40px;
        max-width: 40px;
      }
 
      .ebill {
        .mat-column-Description {
          width: 20%;
          max-width: 20%;
        }
 
        .mat-column-Ship-To-Account-Name {
          width: 15%;
          max-width: 15%;
        }
 
        .mat-column-Track {
          width: 8%;
          max-width: 8%;
        }
 
        .mat-column-City , .mat-column-Address-1 {
          width: 6%;
          max-width: 6%;
        }
      }
 
    }
 
    .mat-mdc-paginator {
      background-color: transparent;
      color: var(--cx-color-black);
      font-weight: 600px;
 
      &-outer-container {
        height: 30px;
      }
 
      &-container {
        min-height: 45px;
      }
 
      &-icon {
        height: 20px;
        width: 24px;
        background-color: var(--cx-color-white);
        border-radius: 6px;
      }
    }
 
    .mat-mdc-unelevated-button.mat-white {
      padding: 5px 24px;
      background-color: transparent;
      color: var(--cx-color-black);
    }
  }
 
  .no-table-data {
    background-color: var(--cx-color-white);
    box-shadow: var(--common-box-shadow);
    &-msg {
      color: var(--cx-alert);
    }
  }
}
// THIS IS THE CSS FOR EXPANDED VIEW OF THE TABLE HENCE WRITING IT INDEPENDENTLY - ENDS //
 
// CLASS TO HIDE THE SCROLL ONCE TABLE IS EXPANDED - STARTS
body.hide-scroll {
  overflow: hidden !important; // important is needed to override the overflow inline styles applied by chrome n frefox on body tag
}
// CLASS TO HIDE THE SCROLL ONCE TABLE IS EXPANDED - ENDS
