.promotion-slider-container {
  z-index: 99999;
  position: fixed !important; // Default CSS precedence is higher
  max-width: 340px !important; // Default CSS precedence is higher
  min-width: 320px;
  box-shadow: var(--common-box-shadow);
  background: var(--cx-color-white);
  right: 0;
  top: 0;
  height: 100vh;
  .mat-mdc-dialog-container {
    padding: 0 !important;
    .mat-mdc-dialog-surface {
      padding: 0 !important;
    }
  }
  .promotion-slider {
    min-width: 340px;
    overflow: auto;
    height: 90vh;
    &-header {
      position: absolute;
      top: 0;
      z-index: 1;
      height: 74px;
      background-color: var(--cx-warning-success);
      gap: 16px;
      z-index: 999;
      .promotion-icon-container {
        background-color: var(--cx-color-white);
      }
      .content-section {
        .promo-text,
        .promo-desc {
          color: var(--cx-color-white);
        }
        @include media-breakpoint-down(sm) {
          width: 70%;
        }
      }
      .close-btn {
        width: 44px;
      }
    }
    &-header-back {
      gap: 16px;
      background-color: var(--cx-global-inventory);
    }

    &-items {
      .promotion-slider-item {
        border: 1px dashed var(--cx-warning-success);
        .promotion-icon-container {
          background-color: var(--cx-warning-success);
          .mat-icon[svgicon="applied_promo"] {
            width: 12px;
            height: 12px;
            line-height: 12px;
          }
        }
        .promotion-type {
          color: var(--cx-warning-success);
        }
        .separator {
          border-color: var(--cx-table-vertical-border);
        }
        .promotion-cta {
          gap: 1rem;
          .apply-promo {
            background-color: var(--cx-warning-success);
          }
          .edit-promo {
            border: 2px solid var(--cx-warning-success);
            color: var(--cx-color-black);
          }
          .cancel-promo {
            border: 2px solid var(--cx-warning-error);
            color: var(--cx-color-black);
          }
        }
      }
    }

    &-footer {
      position: absolute;
      bottom: 0;
      background-color: var(--cx-global-inventory);
      gap: 16px;
      .backToCart {
        z-index: 1000;
      }
    }
  }
}
