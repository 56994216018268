body {
  .product-search-container {
    z-index: 9999;
    &-spinner{
      height: 200px;
    }
    .ngx-spinner-overlay{
      position: relative !important;
    }
    .btn:focus{
      box-shadow: none;
      border: none;
    }
    .sidenav {
      max-width: 250px;
    }
    .sortKeys {
      @include media-breakpoint-down(lg) {
        align-items: center;
      }
      .compare {
        height: 35px;
        width: 35px;
        border: solid 1px var(--cx-color-primary2);
      }
      .gridViewIcon {
        background-color: var(--cx-color-primary);
        width: 35px;
        height: 35px;
      }
      .listViewIcon {
        background-color: var(--cx-color-primary);
        width: 35px;
        height: 35px;
      }
      .sortBy {
        padding: 3px;
        border: 1px solid var(--cx-color-black);
        height: 32px;
        margin-right: 80px;
        @include media-breakpoint-down(lg) {
          margin-right: 10px;
        }
      }
    }
    .banner-image-container{
      .banner-image{
        height: 100px;
      }
    }
    .BoxoutProComponentFirstSlot,.BoxoutProComponentSecondSlot{
      cx-banner cx-generic-link {
        &:focus{
          outline-style: none;
        }
        a{
          &:focus{
            outline-style: none;
          }
          cx-media img{
            max-width: 247px;
            max-height: 339px;
          }
        }
      }
      cx-media img{
        box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
        border-radius: 8px;
      }
    }
    .CategorySearchcoopFirstSlot{
      cx-banner cx-generic-link {
        &:focus{
          outline-style: none;
        }
        a{
          &:focus{
            outline-style: none;
          }
          cx-media img{
            max-width: 871px;
            height: 220px;
          }
        }
      }
      cx-media img{
        box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
      }
    }
    .BottomSearchBarSlot{
      cx-banner cx-generic-link {
        &:focus{
          outline-style: none;
        }
        a{
          &:focus{
            outline-style: none;
          }
          cx-media img{
            max-width: 268px;
            max-height: 268px;
          }
        }
      }
      cx-paragraph{
        @extend .ml-5;
        p{
          h1{
            @extend .t-display-m;
          }
          p{
            @extend .t-title-m;
          }
        } 
      }
    }
  }
}
