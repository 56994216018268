.portal-bxd-inventory{
  .portal-bxd-inventory-nav {
    padding: 12px;
    box-shadow: var(--common-box-shadow);
    background-color: var(--cx-color-white);
    min-width: 100%;

    .section-top {
      border-bottom: 1px solid var(--cx-product-border);

      .li-gap{
        gap: 10px;
      }
    }

    .view-select {
      margin-bottom: -15px;
    }

    .select-section {
      mat-select {
        padding-top: 6px;
      }

      .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
        --mat-mdc-form-field-label-transform: translateY(-27px) scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
      }

      .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
        top: 18px;
        left: 3px;
      }

      .mdc-text-field--outlined {
        height: 36px;
      }

      .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
        padding: 0px;
      }
    }

    .btn-color {
      width: 100%;
      height: 5px;
    }

    .active {
      background-color: var(--sr-blue-tag);
    }

    .nav-menu {
      color: var(--cx-mat-input-color);
    }

    .nav-btn {
      height: 30px !important; //necessary for design

      .filter-icon {
        width: 12px;
        margin-right: 10px;
      }

      .action-icon {
        width: 5px;
        margin-left: 10px;
      }
    }

    .search {
      border: 1px solid var(--cx-color-primary);
      border-radius: 5px;
      height: 36px;
      flex: 1;

      input {
        color: var(--cx-color-primary);
      }
    }

    .section-mid {
      gap: 25px;
    }

    .section-bottom {
      // margin-top: 6px;

      .expand-icon {
        border: 1px solid;
        padding: 2px 3px 6px 3px;
        height: 30px;
        width: 30px;

        &:hover {
          cursor: pointer;
        }
      }

      .slide-gap{
        gap: 16px;

        label{
          color: var(--cx-input-text-color);
        }
      }
    }

    // for expand icon 
    & .reusuable__filter-container .filter__item-section .section-bottom .expand-icon {
      top: 0;
    }
  }

  .table__container {
    overflow-x: auto;
    margin-top: 16px;
  }

  .inventory-table{

    // @include media-breakpoint-down(md) {
    //   display: block;
    // }

    .product-col{
      text-align: start;
    }

    .matSortIcon{
      width: 14px;
    }

    .sticky-col {
      position: sticky;
      left: 0;
      background: white;
    }

    .rotated {
      .matSortIcon {
        transform: rotate(180deg);
      }
    }

    .wrapper {
      display: grid;
      justify-content: center;

      .bar {
        margin: 0px -12px 0 8px !important;
      }
    }

    & .mfg-checked-col, & .bxd-sku-col{
      color: var(--status-blue);
      text-decoration: underline;

      .inventory-hyperlink {
        color: var(--hyperlink-blue);
      }
    }
  }

  .bxd-portal-inventory-largeScreen {
    .expand-table {
      background-color: var(--cx-color-white);
      box-shadow: var(--common-box-shadow);

      width: 100%;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 99;
      height: 100vh;
      overflow-y: auto;
    }

    .paginate{
      border-top: none;
      border-radius: 0px 0px 7px 7px;
    }
  }
  
}