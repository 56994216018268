.order-credit {
  .all-credit {
    background-color: var(--cx-rewards-bg-light);
    border-radius: 7px;
    min-height: 80px;

    .inner-body {
      border: 1px dashed var(--cx-rewards-bg);
      border-radius: 7px;
      gap: 1rem;

      .credit-icon-container {
        width: 45px;
        height: 44px;
        background-color: var(--cx-rewards-bg);
        border-radius: 50%;
        .mat-icon[svgicon="order_promotion"] {
          width: 24px;
          height: 24px;
        }
      }

      .promotional-slider-cta {
        margin-left: auto;
      }

      .apply-remove-credit {
        position: relative;
        min-width: 50px;
        padding: 2px 10px;
        height: 24px;
        border-radius: 7px;
        background-color: var(--cx-rewards-bg);
        color: var(--cx-color-white);
      }

      .arrow {
        position: relative;
        top: 6px;
        span {
          display: inline-block;
          width: 10px;
          height: 10px;
          border-bottom: 3px solid var(--cx-rewards-bg);
          border-right: 3px solid var(--cx-rewards-bg);
          transform: rotate(-45deg);
          margin: 0 1px;
          animation: animate 2s infinite;
        }
      }
    }
  }
}
