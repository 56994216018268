body .custom-modal {
  pointer-events: auto;
  overflow: auto;
}
.product-media-preview-container {
  // max-height: calc(100vh - 32px);
  max-width: calc(100vw) !important; // Overriding inline css
  width: calc(100vw - 128px);
  @include media-breakpoint-down(md) {
    width: calc(100vw - 64px);
  }
  @include media-breakpoint-down(sm) {
    width: calc(100vw - 16px);
  }

  .product-media-preview {
    .header-section {
      position: relative;
      .modal-close {
        background-color: var(--cx-modal-close);
        color: var(--cx-color-white);
        position: relative;
        right: 0;
        top: 0;
        border-radius: 50%;
      }
    }
    .media-preview-section {
      border: 2px solid var(--cx-color-primary2);
      .preview {
        height: 60vh;
        @include media-breakpoint-down(xs) {
          height: 260px;
        }
        &.image-preview {
          .product-image {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        &.video-preview {
          width: 100%;
        }
      }
    }
    .gallery-carousel-section {
      .custom-carousel {
        .container {
          .custom-carousel-item {
            .image-thumbnail,
            .video-thumbnail {
              object-fit: cover;
              border: 2px solid var(--cx-color-primary2);

              &.active {
                border-width: 10px;
                @include media-breakpoint-down(lg) {
                  border-width: 7px;
                }
                @include media-breakpoint-down(md) {
                  border-width: 6px;
                }
                @include media-breakpoint-down(sm) {
                  border-width: 4px;
                }
              }
            }
            .video-thumbnail-box {
              position: relative;
              background: var(--cx-p2-20);
              .video-icon {
                position: absolute;
                width: 40px;
                height: 40px;
                @include media-breakpoint-down(lg) {
                  width: 30px;
                  height: 30px;
                }
                @include media-breakpoint-down(md) {
                  width: 26px;
                  height: 26px;
                }
                @include media-breakpoint-down(sm) {
                  width: 18px;
                  height: 28px;
                }
              }
            }
          }
        }
        .arrow-prev,
        .arrow-next {
          mat-icon {
            text-align: center;
            svg {
              width: 20px;
              @include media-breakpoint-down(lg) {
                width: 18px;
              }
              @include media-breakpoint-down(md) {
                width: 18px;
              }
              @include media-breakpoint-down(sm) {
                width: 16px;
              }
            }
          }
          &.disabled {
            opacity: 0.5;
            cursor: default;
          }
        }
      }
    }
  }
}
