.restricted-pdp-warning-container{
  .restricted-pdp-warning-border{
    border-bottom: 1px solid #717171;
  }
  .restricted-pdp-warning-description{
    line-height: 2;
    text-align: center;
    .restricted-pdp-warning-link{
      color: var(--side-nav-border-bottom);
      &:hover{
        color: var(--side-nav-border-bottom);
        text-decoration: underline;
      }
  }
  }
 
}