.bxd-portal-mfa {
  &-heading, &-sub-heading{
    color: var(--cx-color-primary) !important; // Necessary for design
  }
  &-sub-heading{
    color: var(--cx-color-primary) !important; // Necessary for design
  }
  .error-gap{
    margin-top: 23px;
  }
  .line-gap{
    margin-top: 23px;
    margin-bottom: 23px;
  }
}
