#{globalParent()} {
  @each $type, $sizes in $typography {
    .t-#{$type},
    %t-#{$type} {
      @each $size, $attrList in $sizes {
        &-#{$size} {
          @if (map-has-key($attrList, "text-transform")) {
            text-transform: map-get($attrList, "text-transform");
          }
          @if (map-has-key($attrList, "letter-spacing")) {
            letter-spacing: map-get($attrList, "letter-spacing");
          }
          @include font-attributes(
            map-get($attrList, "font-size-map"),
            normal,
            normal,
            map-get($attrList, "font-weight"),
            map-get($attrList, "line-height"),
            map-get($attrList, "font-family")
          );
          color: map-get($attrList, "color");
        }
      }
      @each $size, $attrList in $sizes {
        @if (map-has-key($attrList, "alt-color")) {
          &-alt-#{$size} {
            @include font-attributes(
              map-get($attrList, "font-size-map"),
              normal,
              normal,
              map-get($attrList, "font-weight"),
              map-get($attrList, "line-height"),
              map-get($attrList, "font-family")
            );
            color: map-get($attrList, "alt-color");
          }
        }

        @if (map-has-key($attrList, "inverse-color")) {
          &-inverse-#{$size} {
            @include font-attributes(
              map-get($attrList, "font-size-map"),
              normal,
              normal,
              map-get($attrList, "font-weight"),
              map-get($attrList, "line-height"),
              map-get($attrList, "font-family")
            );
            @if (map-has-key($attrList, "letter-spacing")) {
              letter-spacing: map-get($attrList, "letter-spacing");
            }
            color: map-get($attrList, "inverse-color");
          }
        }

        @if (map-has-key($attrList, "inverse-alt-color")) {
          &-inverse-alt-#{$size} {
            @include font-attributes(
              map-get($attrList, "font-size-map"),
              normal,
              normal,
              map-get($attrList, "font-weight"),
              map-get($attrList, "line-height"),
              map-get($attrList, "font-family")
            );
            color: map-get($attrList, "inverse-alt-color");
          }
        }
      }
    }
  }
}
