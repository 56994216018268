.portal-bxd-product{

  .bxd-product-dashboard {
    border-radius: 7px;
    padding: 12px;
    box-shadow: var(--common-box-shadow);

    &-heading-gap {
      gap: 6px;
      display: grid;
    }

    &-rightSpace {
      margin-right: 15px;
    }

    &-activeProduct {
      gap: 10px;
      padding: 2px, 5px;
    }

    &-outOfStock {
      padding: 12px;
      gap: 12px;
      border-radius: 6px;
    }

    &-outOfStockNo {
      border-radius: 20px;
      background-color: var(--cx-warning-error);
      color: var(--cx-color-white);
      padding: 2px 6px;
    }

    .totalUnitsNumber{
      color: var(--cx-color-black);
    }

    p.circle-text  {
      color: var(--cx-color-white);
    }
  }

  .product-table {
    width: 100%;
    max-width: 100%;
    overflow: auto;

    // for draggable fix
    .productTableHeader {
      .wrapper {
        display: grid;
        justify-content: center;

        .bar {
          margin: 0px -18px 0 8px;
        }
      }
    }

    &-iconGap {
      gap: 9px;
    }

    th, td {
      border-right: 1px solid var(--cx-table-vertical-border);
      border-bottom: 2px solid var(--cx-color-white);
      text-align: center !important;
      vertical-align: middle !important;
    }

    tr:nth-child(even) {
      background: var(--cx-product-table-bg);
    }

    .mat-mdc-header-cell {
      border-bottom: 3px solid var(--cx-color-black);;
    }

    .active {
      background-color: var(--cx-warning-success);
    }

    .draft {
      background-color: var(--status-orange)
    }

    .inactive {
      background-color: var(--status-grey);
    }

    .futureInactive{
      background-color: var(--future-inactive);
    }

    .productStatus {
      padding: 2px 4px;
      border-radius: 5px;
      color: var(--cx-color-white);
      letter-spacing: 1.1px;
    }

    .mat-icon {
      vertical-align: middle;
    }
    mat-icon {
      height: 24px;
      width: 16px;
      transition: rotate 0.3s ease-in-out;
    }


    .mat-column-Select {
      width: 55px;
      max-width: 80px;
    }

    .mat-column-Status {
      width: 70px;
      max-width: 110px;
    }

    .mat-column-Boxout-SKU {
      width: 140px;
      // max-width: 200px;
    }

    .mat-column-MFG-SKU{
      width: 140px;
      // max-width: 200px;
    }

    .mat-column-Manufacturer{
      width: 140px;
      // max-width: 130px;
    }

    .mat-column-Product {
      width: 350px;
      // max-width:630px;
    }

    .mat-column-Available{
      width: 110px;
    }

    .mat-column-UOM{
      width: 93px;
    }

    .mat-column-UPC-Sell{
      width: 110px;
    }

    .rotated {
      .matSortIcon {
        transform: rotate(180deg);
      }
    }

    & .bxdTableAnchor {
      color: var(--hyperlink-blue);
      text-decoration: underline;
    }
  }

  .portal-bxd-product-nav {
    padding: 12px;
    box-shadow: var(--common-box-shadow);
    background-color: var(--cx-color-white);
    min-width: 100%;

    .view-select {
      margin-bottom: -15px;
    }

    .li-gap{
      gap: 10px;
    }

    .select-section {
      mat-select {
        padding-top: 6px;
      }

      .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
        --mat-mdc-form-field-label-transform: translateY(-27px) scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
      }

      .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
        top: 18px;
        left: 3px;
      }

      .mdc-text-field--outlined {
        height: 36px;
      }

      .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
        padding: 0px;
      }
    }

    .extra-gap{
      gap: 16px;
    }

    .btn-color {
      width: 120%;
      height: 5px;
    }

    .active {
      background-color: var(--sr-blue-tag);
    }

    .nav-menu {
      color: var(--cx-mat-input-color);
      margin-bottom: -8px;
    }

    .nav-btn {
      height: 30px !important; //necessary for design

      .filter-icon {
        width: 12px;
        margin-right: 10px;
      }

      .action-icon {
        width: 5px;
        margin-left: 10px;
      }

      .view-icon {
        width: 10px;
      }
    }

    .search {
      border: 1px solid var(--cx-color-primary);
      border-radius: 5px;
      height: 36px;
      flex: 1;

      input {
        color: var(--cx-color-primary);
      }
    }

    .section-mid {
      gap: 25px;
    }

    .section-bottom {
      margin-top: 6px;

      .expand-icon {
        border: 1px solid;
        padding: 2px 3px 6px 3px;
        height: 30px;
        width: 30px;

        &:hover {
          cursor: pointer;
        }
      }

      .calender{
        .today-btn{
          padding: 0 6px;
          border-radius: 0;
          background-color: var(--bxd-calender-date-bg);
          color: var(--cx-color-black);
        }

        height: 29px;
        gap: 9px;

        .date-section {
          padding: 0 6px;
          background-color: var(--bxd-calender-date-bg);
        }

        .date-range {
          margin-top: 6px;

          .dateInput{
            width: 80px;
          }
        }
        mat-datepicker-toggle {
          button {
            background-color: var(--cx-color-white);
            outline: none;
            padding: 0;
            margin: 0;
            height: 24px;
            width: 24px;

            .mat-mdc-button-touch-target {
              height: 24px;
              width: 24px;
            }

            span {
              color: var(--cx-color-black);
            }

            mat-icon {
              height: 24px;
              width: 24px;
            }
          }
        }
      }
    }
  }

  .portal-bxd-panel-class {
    .mat-mdc-option .mdc-list-item__primary-text {
      margin-right: 0;
      margin-left: auto;
    }
  }

  .bxd-portal-product-largeScreen {
    .expand-table {
      background-color: var(--cx-color-white);
      box-shadow: var(--common-box-shadow);

      width: 100%;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 99;
      height: 100vh;
      overflow-y: auto;
    }

    .paginate{
      border-top: none;
      border-radius: 0px 0px 7px 7px;
    }
  }

  // for expand icon
  .lgScreen {
    & .reusuable__filter-container .filter__item-section .section-bottom .expand-icon {
      top: 6px;
    }
  }

  @include media-breakpoint-down(sm) {
    .alignItemVertically{
      display: grid !important;
      gap: 6px;
    }

    .alignTextRight{
      text-align: end;
    }
    
    .expand-icon {
      top: 3px !important;
    }

    .bxd-product-dashboard {
      &-outOfStock {
        margin-top: 6px;
        border: none;
        padding: 0px;
        align-items: end !important;
      }
    }
  }
}

.portal-bxd-filter-mat-menu {
  width: 250px;
  color: var(--cx-color-black);
  border-top: 5px solid var(--cx-warning-success);

  .filter-label {
    height: 48px;
  }

  .filter-list-item {
    width: 100%;
    border: 1px solid var(--rewards-button-color);
    border-radius: 0px;

    .filter-list-icon {
      width: 12px;
    }
  }

  .sub-menu-div {
    .filter-item-count {
      background-color: var(--cx-drop-ship);
      padding: 2px;
      color: var(--cx-color-black);
    }
  }
}

.portal-bxd-action-mat-menu{
  border: 1px solid var(--sr-green-tag);
  max-width: 500px !important;
}

.portal-bxd-view-mat-menu{
  border: 1px solid var(--cx-color-primary2);
}

.BoxoutPortalHomeLeftNavBar {
  app-portal-bxd-drawer {
    mat-drawer-container {
      mat-drawer-content {
        z-index: 2;
      }
    }
  }
}