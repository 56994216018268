body {
    font-family: Roboto, Helvetica Neue, sans-serif !important;

    cx-link a {
        text-decoration: none;
    }

    .mat-mdc-dialog-container {
        .mat-mdc-dialog-surface {
            padding: 24px;
            @include media-breakpoint-down(md) { 
                padding: 16px;
            }
        }
    }

    .mat-mdc-button.mat-mdc-button-base,
    .mat-mdc-raised-button.mat-mdc-button-base,
    .mat-mdc-unelevated-button.mat-mdc-button-base,
    .mat-mdc-outlined-button.mat-mdc-button-base {
        max-height: 48px;
        min-height: auto;
        height: auto;
        //padding: 11px 12px;
    }

    // .mdc-text-field--outlined.mdc-text-field--invalid {
    //     .mdc-notched-outline {
    //         color: var(--cx-warning-error);
    //         border: 1.5px solid var(--cx-warning-error);
    //     }
    // }

    // .mdc-text-field--outlined {
    //     --mdc-outlined-text-field-outline-width: 2px;
    // }

    input.mat-mdc-input-element::-webkit-calendar-picker-indicator {
        display: block;
    }


    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
        border-color: var(--cx-mat-outline);
    }

    .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-notched-outline__leading,
    .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-notched-outline__notch,
    .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-notched-outline__trailing {
        border-color: var(--cx-warning-error);
    }

    .mat-form-field-invalid {
        .mdc-notched-outline__leading {
            border-left: 2px solid;
        }

        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing {
            border-top: 2px solid;
            border-bottom: 2px solid;
        }

        .mdc-notched-outline__trailing {
            border-right: 2px solid;
        }

        .mat-mdc-form-field,
        .mat-mdc-floating-label {
            font-weight: 500;
        }

        .mdc-notched-outline--notched .mdc-notched-outline__notch {
            border-top: none;
        }
    }

    .mat-mdc-form-field,
    .mat-mdc-floating-label {
        letter-spacing: normal;
    }

    .mat-mdc-text-field-wrapper.mdc-text-field {
        padding: 0 12px !important;
    }

    .mat-mdc-form-field-error-wrapper {
        padding: 0 12px;
        .mat-mdc-form-field-error {
            line-height: normal;
        }
    }

    .mdc-text-field--outlined .mdc-floating-label {
        left: 0;

        &--float-above {
            left: 2px;
        }
    }

    .mat-mdc-form-field,
    .mat-mdc-floating-label {
        font-family: Roboto, Helvetica Neue, sans-serif !important;
    }

    .mdc-text-field--invalid:not(.mdc-text-field--disabled) , .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover , .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):focus , .mdc-text-field--focused {
        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing {
            border-color: var(--cx-warning-error);
        }
    }

    @include media-breakpoint-down(md) {  
        a {
            min-height: auto;
        }
    }

}