.mini-cart-container {
  z-index: 99999;
  position: fixed;
  width: 368px;
  box-shadow: var(--common-box-shadow);
  background: var(--cx-color-white);
  right: 0;
  top: 0;
  height: 100vh;
  .mini-cart{
      padding: 10px 10px;
      height: 44px;
      .cancel-img{
        width: 25px;
      }
      .mat-mdc-unelevated-button.mat-primary {
        background-color: var(--cx-color-primary2);
        padding: 5px;
        &.sitelinks-close {
            min-width: 25px;
            padding: 0;
            span {
                color: var(--cx-color-white);
            }
        }
        &.cdk-program-focused , &.cdk-focused {
            color: var(--cx-color-white);
            background-color: var(--cx-color-primary2);
        }
    }
  }
  .mini-cart-content{
    height: -webkit-fill-available;
  }
  .mini-cart-items{
    border-top: 1px solid var(--cx-color-black);
  }
  .mini-cart-items .img {
    flex-basis: calc(10% - 20px);
    margin: 10px;
    border: 1px solid var(--cx-color-secondary2);
  }
  .img img {
    display: block;
    width: 72px;
    height: 72px;
  }
  .item {
    flex-grow: 1;
    margin: 10px;
  }
  .mini-cart-qty{
    .cart-qty{
      border: 1px solid var(--cx-color-secondary2);
      min-width: 34px;
      height: 34px;
      text-align: center;
    }
  }
  .separators{
    background-color: var(--cx-color-black);
  }
  .txt-color{
    color: var(--cx-color-primary);
    font-size: 9px;
  }
  .checkout-cart{
    position: fixed;
    width: inherit;
    background-color: $background-body;
    height: 201px;
    .checkout-desc{
      text-align: center;
    }
  }
  .mini-cart-list{
    height: calc(85vh - 245px);
    overflow: auto;
  }
  .mini-cart-list:focus{
    outline: none;
  }
  .view-cart-btn{
    padding-left: 38.5px; //Need to align with figma
    padding-right: 38.5px; //Need to align with figma
  }
}

.set-color {
  color: var(--cx-color-primary);
}


.order-opacity {
  opacity: 0.6;
}

body {
  .mat-mdc-unelevated-button.mat-primary.mini-cart-btn {
    background-color: var(--cx-cart);
    border-color: var(--cx-cart);
    &:hover {
      background-color: var(--cx-cart);
      border-color: var(--cx-cart);
      opacity: 0.8;
    }
  }
}