body {

  .rapid-order {
      box-shadow: var(--common-box-shadow);
      background: var(--cx-color-white);

      &-font-color {
        color: var(--cx-color-primary);
      }

    &-mobile-sku-count {
      border: 1px solid var(--cx-hr-border);
    }

    &-layout {

      &-category {
        width: 100%;

        @include media-breakpoint-up(lg) {
          width: 70%;
        }
      }

      &-accordion {

        .mat-expansion-panel {
          box-shadow: none;
        }

        #accordion-panel {
          border-bottom-left-radius: 0;
        }

        .mat-expansion-panel-header {
          height: 30px;
          .mat-expansion-panel-header-title {
            align-items: normal;
          }
        }

        .mat-expansion-panel-body {
          padding: 0;
        }

        &-header {
          align-items: flex-start;

          &-number {
            background-color: var(--side-nav-border-bottom);
            color: var(--cx-number-color);
            height: 30px;
            width: 30px;
            padding-top: 5px;
          }

          &-category {
            width: 90%;
            padding-top: 5px;
          }

          &-icon {
            padding-top: 5px;
            mat-icon {
              width: 20px;
              height: 24px;
            }
          }
        }

        &-content {

          border-top: 1px solid var(--side-nav-border-bottom);

          &-sku {
            border: 1px solid var(--cx-hr-border);

            &-text {
              color: var(--cx-rapidorder-sku);
              //color: var(--cx-color-black);
            }
          }

          &-description {
            width: 50%;
          }

          &-status {
            border-top: 1px dashed var(--cx-table-vertical-border);

            &-instock-text {
              color: var(--cx-color-organic-green);
            }

            &-backordered-text {
              color: var(--cx-warning-error);
            }

            &-outofstock-text {
              color: var(--cx-warning-error);
            }

            &-discontinued-text {
              color: var(--cx-warning-error);
            }

            &-specialorder-text {
              color: var(--cx-color-organic-green);
            }
          }

          &-multiplier {
            color: var(--cx-color-white);
            height: 22px;

            &-error {
              background-color: var(--cx-warning-error);;
            }

            &-no-error {
              background-color: var(--cx-color-organic-green);
            }
          }

          &-max-quantity {
            color: var(--cx-color-white);
            height: 22px;
            background-color: var(--cx-color-secondary);
          }

          &-quantity {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            margin-top: 7px;

            &-border {
              border-bottom: 1px dashed var(--cx-table-vertical-border);
            } 

            // Input Section Styling
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            /* Firefox */
            input[type=number] { // give class
              -moz-appearance: textfield;
            }

            .quantity__minus {
              box-sizing: border-box;
              width: auto;
              height: 45px;
              padding-right: 12px;
              padding-left: 12px;
              background: var(--cx-color-white);
              border: 1px solid var(--cx-color-primary);
              border-radius: 5px 0 0 5px;
            }

            .quantity__plus {
              @extend .quantity__minus;
              border-radius: 0 5px 5px 0;
            }

            .quantity__input {
              width: 52px;
              height: 45px;
              text-align: center;
              margin: 0 2px;
              border: 1px solid var(--cx-color-primary);
            }

          }

          &-ext-price {
            height: 32px;
          }
        }

        &-bottom-blue {
          height: 7px;
          background-color: var(--side-nav-border-bottom);
        }

        &-no-products {
          color: var(--cx-alert);
        }
      }

      &-summary {
        width: 30%;
        background-color: var(--cx-color-white);
        height: fit-content;
        position: sticky;
        top: 50px;

        &-header {
          border: 1px solid var(--cx-p2-40);

          &-text {
            font-size: 20px;
          }
        }

        &-separator {
          border-top: 2px solid var(--cx-table-vertical-border);
        }

        &-add-to-cart-button {
          height: 56px;
          border-radius: 5px;

          &-mobile {
            height: 38px;
          }
        }

        &-clear-selection-button {
          border: 2px solid var(--cx-hr-border);
          border-radius: 7px;
        }
      }
    }

  }

}