.order__details {
  gap: 16px;

  &-navigation {
    button {
      gap: 7px;
      padding: 4px 8px;
      border-radius: 7px;
      border: 2px solid var(--cx-hr-border);
      background-color: var(--cx-color-white);
      span {
        color: var(--cx-color-black);
      }
    }

    @media print {
      & {
        display: none;
      }
    }
  }

  &-container {
    background-color: var(--cx-color-white);
    border-top: 5px solid var(--cx-color-primary);
    padding: 0px;
    &-content {
      gap: 16px;
      padding: 16px 8px;
      .section {
        gap: 15px;
        height: 170px;
        .section-text {
          p {
            margin: 0;
          }
        }

        @include media-breakpoint-down(md) {
          height: auto;
        }
      }

      @include media-breakpoint-down(md) {
        flex-direction: column;
      }
    }

    &-action {
      padding: 8px;
      border-top: 1px solid var(--cx-color-primary);

      .status-badge {
        div:first-child {
          background-color: var(--cx-warning-success);
          color: var(--cx-color-white);
          text-transform: uppercase;
        }
        div:last-child {
          background-color: var(--cx-warning-success);
          color: var(--cx-color-white);
          padding: 5px 16px;
        }
      }

      @media print {
        .order-status,
        .status-badge {
          display: block !important;
          color: black !important;
          background-color: white !important;
          -webkit-print-color-adjust: exact;
          color-adjust: exact;
        }

        .status-badge div:first-child {
          background-color: var(--cx-warning-success) !important;
          color: var(--cx-color-white) !important;
          text-transform: uppercase;
          -webkit-print-color-adjust: exact;
          color-adjust: exact;
        }

        .status-badge div:last-child {
          background-color: var(--cx-product-filter) !important;
          color: var(--cx-color-white) !important;
          padding: 5px 16px;
          -webkit-print-color-adjust: exact;
          color-adjust: exact;
        }
      }

      .order-print {
        gap: 10px;

        button {
          gap: 7px;
          padding: 4px 8px;
          background-color: var(--cx-color-white);
          border: 2px solid var(--cx-hr-border);
          border-radius: 7px;
        }
      }

      @include media-breakpoint-down(md) {
        gap: 16px;
        flex-direction: column;

        .order-status,
        .order-print {
          margin-right: auto;
        }
      }
    }
  }

  &-shipTo-billTo-container {
    gap: 16px;
    flex-direction: row;
    .ship-to__section,
    .bill-to__section {
      background-color: var(--cx-color-white);
      width: 50%;
      gap: 16px;
      box-shadow: var(--common-box-shadow);

      .section-heading {
        padding: 5px 8px;
        background-color: var(--cx-p10);
        text-align: left;
        text-transform: uppercase;
      }

      .section-content {
        padding: 8px;
        gap: 8px;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    @media print {
      flex-direction: row !important;
      .ship-to__section,
      .bill-to__section {
        background-color: var(--cx-color-white) !important;
        width: 50% !important;
        gap: 16px !important;
        box-shadow: var(--common-box-shadow) !important;
        -webkit-print-color-adjust: exact !important;
        color-adjust: exact !important;
      }
    }
  }

  .order-lines__heading {
    padding: 10px;
    background-color: var(--cx-color-white);
    border-bottom: 2px solid var(--cx-color-black);
    box-shadow: var(--common-box-shadow);
  }

  .order-line-items {
    box-shadow: var(--common-box-shadow);
    background-color: var(--cx-color-white);

    .header-section {
      background-color: var(--cx-global-inventory);
      gap: 10px;
      padding: 0 10px 0 0;

      .serial-no {
        button {
          background-color: var(--cx-hr-border);
          text-align: center;
          width: 46px;
          height: 46px;
          color: var(--cx-color-white);
        }

        @media print {
          & button {
            background-color: var(--cx-hr-border) !important;
            color: var(--cx-color-white) !important;
            -webkit-print-color-adjust: exact;
            color-adjust: exact;
            width: 46px;
            height: 46px;
            text-align: center;
          }
        }
      }

      .header-item-section {
        gap: 12px;
        padding: 5px 10px;
      }

      .header-item.ship-complete-btn {
        margin-left: auto;
        button {
          font-weight: 900;
          color: var(--cx-color-white);
          padding: 5px;
          background-color: var(--cx-warning-success);

          @media print {
            & {
              font-weight: 900 !important;
              color: var(--cx-color-white) !important;
              padding: 5px !important;
              background-color: var(--cx-warning-success) !important;
              -webkit-print-color-adjust: exact;
              color-adjust: exact;
            }
          }
        }
      }

      @include media-breakpoint-down(md) {
        & {
          overflow-x: scroll;
        }
      }

      @media print {
        & {
          background-color: var(--cx-global-inventory) !important;
          gap: 10px !important;
          padding: 0 10px 0 0 !important;
          -webkit-print-color-adjust: exact !important;
          color-adjust: exact !important;
        }
      }
    }

    .line-item {
      border-left: 5px solid var(--cx-table-vertical-border);
      margin: 10px;

      .main-section {
        padding: 0 0 0 10px;

        .item {
          gap: 12px;

          .header {
            max-width: 130px;
            text-align: center;
            background-color: var(--cx-hr-border);
            color: var(--cx-color-white);
            padding: 0 6px;

            @media print {
              & {
                background-color: var(--cx-hr-border) !important;
                color: var(--cx-color-white) !important;
                -webkit-print-color-adjust: exact;
                color-adjust: exact;
              }
            }
          }

          .tracking-details {
            margin-left: auto;
            display: inline-block;
            width: -moz-fit-content; /* For Firefox */
            width: fit-content;
            p {
              display: inline-block;
              letter-spacing: 2.1px;
              background-color: var(--cx-p70);

              @media print {
                & {
                  background-color: var(--cx-p70) !important;
                  -webkit-print-color-adjust: exact;
                  color-adjust: exact;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media print {
  app-bxd-header {
    display: none;
  }

  mat-drawer.sr-home-layout-sidenav {
    display: none;
  }

  mat-drawer-content.mat-drawer-content {
    margin-left: 0 !important;
  }

  app-breadcrumb {
    display: none;
  }
}
