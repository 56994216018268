.asm-header {
  height: 31px;
  background-color: var(--cx-p40);

  &-powered-by-logo {
    gap: 16px;
    background-color: var(--cx-color-primary);

    .FooterPoweredByBoxoutLogo {
      img {
        max-width: 74px;
      }
    }
    .asm-text {
      color: var(--cx-color-white);
      @include media-breakpoint-up(sm) {
        border-left: 0.5px solid var(--cx-color-white);
      }
    }
  }
  &-account-details {
    .details-box {
      max-width: fit-content;
      .number,
      .name {
        border-left: 1px solid var(--cx-color-black);
        border-right: 1px solid var(--cx-color-black);
      }
    }
  }
  &-logout-button {
    gap: 14px;
    background-color: var(--cx-color-darkOrange);
    color: var(--cx-color-white) !important; // Necessary for design
    .logout-icon {
      width: 14px;
      height: 14px;
      line-height: 14px;
    }
  }
}
.asm-border-space {
  height: 4px;
  width: 100%;
  border-top: 1px dashed var(--cx-color-black);
  filter: drop-shadow(var(--common-box-shadow));
}
