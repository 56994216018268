/// Standard Palette
/// Base - common for all sites
$member-solid: #ff9662 !default;
$member-solidAlt: #f8f8f8 !default;
$neutral-10: #f8f8f8 !default;

/// Neutral
$neutral-10: #f8f8f8 !default;
$neutral-20: #eeeeee !default;
$neutral-30: #c4c4c4 !default;
$neutral-40: #707070 !default;
$neutral-50: #303030 !default;
$neutral-00: #000001 !default;
$base-10: #000000 !default;
$base-20: #ffffff !default;

/// Alerts
$alert-00: #ffffff !default;
$alert-05: #fcebed !default;
$alert-10: #d0021b !default;
$alert-20: #ffffff !default;
$alert-30: #d0021b !default;
$alert-40: #b94600 !default;
$alert-50: #477d00 !default;
$alert-60: #1c1c1c !default;
$alert-80: #455a64;
$alert-90: #8f857d;


//background-color
$background-01: #72bef2;
$background-02: #495a64;
$background-03: #ecedf0;
$background-body: #f2f8fe;

