.portal-bxd-receiving {
  .bxd-portal-table.open-wro-table {
    width: 100%;
    max-width: 100%;
    overflow: auto;

    // for draggable fix
    .receivingTableHeader {
      .wrapper {
        display: grid;
        justify-content: center;

        .bar {
          margin: 0px -12px 0 8px;
        }
      }
    }

    .receiving-table-iconGap {
      gap: 9px;

      mat-icon {
        height: 24px;
        width: 16px;
        transition: rotate 0.3s ease-in-out;
      }
    }

    th, td {
      padding: 10px;
      border-right: 1px solid var(--cx-table-vertical-border);
      border-bottom: 2px solid var(--cx-color-white);
      text-align: center !important;
      vertical-align: middle !important;
    }

    td:nth-child(3) {
      .receiving-hyperlink {
        color: var(--hyperlink-blue);
        text-decoration: underline;
      }
    }

    tr {
      th:first-of-type {
        width: 55px;
        min-width: 45px;
      }

      th:nth-child(2), th:nth-child(6) {
        width: 130px;
        min-width: 115px;
      }

      th:nth-child(3) {
        width: 200px;
        min-width: 150px;
      }

      th:nth-child(4) {
        width: 50px;
        min-width: 40px;
      }

      th:nth-child(5) {
        width: 150px;
        min-width: 110px;
      }

      th:nth-child(7) {
        width: 90px;
        min-width: 70px;
      }
  
      th:nth-child(8) {
        width: 350px;
        min-width: 300px;
      }
  
      th:last-of-type {
        width: 110px;
        min-width: 90px;
      }
    }


    tr:nth-child(even) {
      background: var(--cx-product-table-bg);
    }

    .mat-mdc-header-cell {
      border-bottom: 3px solid var(--cx-color-black);
    }

    .mat-icon {
      vertical-align: middle;
    }

    .rotated {
      .matSortIcon {
        transform: rotate(180deg);
      }
    }
  }

  .portal-bxd-receiving-nav {
    padding: 12px;
    box-shadow: var(--common-box-shadow);
    background-color: var(--cx-color-white);
    min-width: 100%;

    .view-select {
      margin-bottom: -15px;
    }

    .select-section {
      mat-select {
        padding-top: 6px;
      }

      .mat-mdc-text-field-wrapper.mdc-text-field--outlined
        .mdc-notched-outline--upgraded
        .mdc-floating-label--float-above {
        --mat-mdc-form-field-label-transform: translateY(-27px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
      }

      .mat-mdc-text-field-wrapper
        .mat-mdc-form-field-flex
        .mat-mdc-floating-label {
        top: 18px;
        left: 3px;
      }

      .mdc-text-field--outlined {
        height: 36px;
      }

      .mat-mdc-text-field-wrapper.mdc-text-field--outlined
        .mat-mdc-form-field-infix {
        padding: 0px;
      }
    }

    .btn-color {
      width: 120%;
      height: 5px;
    }

    .active {
      background-color: var(--sr-blue-tag);
    }

    .nav-menu {
      color: var(--cx-mat-input-color);
    }

    .nav-btn {
      height: 30px !important; //necessary for design

      .filter-icon {
        width: 12px;
        margin-right: 10px;
      }

      .action-icon {
        width: 5px;
        margin-left: 10px;
        background-color: var(--cx-warning-success);
      }

      .view-icon {
        width: 10px;
      }
    }

    .search {
      border: 1px solid var(--cx-color-primary);
      border-radius: 5px;
      height: 36px;
      flex: 1;

      input {
        color: var(--cx-color-primary);
      }
    }

    .section-mid {
      gap: 25px;
    }

    .section-bottom {
      margin-top: 6px;

      .expand-icon {
        border: 1px solid;
        padding: 2px 3px 6px 3px;
        height: 30px;
        width: 30px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .portal-bxd-panel-class {
    .mat-mdc-option .mdc-list-item__primary-text {
      margin-right: 0;
      margin-left: auto;
    }
  }

  .bxd-portal-receiving-largeScreen {
    .expand-table {
      background-color: var(--cx-color-white);
      box-shadow: var(--common-box-shadow);

      width: 100%;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 99;
      height: 100vh;
      overflow-y: auto;
    }

    .paginate {
      border-top: none;
      border-radius: 0px 0px 7px 7px;
    }
  }
}

