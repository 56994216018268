.acct-page {
    &-header {
        box-shadow: var(--common-box-shadow);
        border-radius: 5px 5px 0 0;
        background-color: var(--cx-color-white);
    }

    &-heading {
        height: 32px;
    }

    &-section-header {
        height: 35px;
    }
}

.manage-bus-acct {
    a{
        color:var(--cx-color-black);
        &:hover{
            color:var(--cx-color-black);
        }
    }
    
    &-section {
        box-shadow: var(--common-box-shadow);
        background-color: var(--cx-color-white);

        h3 {
            background-color: var(--cx-p2-60);
            height: 35px;
        }
        app-business-addresses {
            .card {
                border: 0;
                .card-body {
                    border: 0;
                    padding: 0 !important; // writting important to override bootstarp default card styles
                }
            }
        }
    }

    .acct-section-division {
        @include media-breakpoint-up(lg) {
            width: 49%;
        }
    }

    .edit-info {
        padding: 10px;
    }

    table {
        border-collapse: collapse;
        box-shadow: var(--common-box-shadow);
        table-layout: fixed;

        thead {
            border: 1px solid var(--cx-table-border);

            tr {
                height: 48px;
            }
        }

        .mat-mdc-row:nth-child(2n+1) {
            background-color: var(--cx-table-row);
        }

        th.cdk-column-User-ID {
            width: 35%;
        }

        td.mat-mdc-cell {
            border: none;
        }

        tr.mat-mdc-row {

            border: 1px solid var(--cx-table-border);
        }
    }

    // CSS TO ACCOMODATE THE REUSABLE ADDRESS COMPONENT BUTTONS STARTS
    .actionButton {
        justify-content: normal !important;
        flex-direction: column;

        .mat-mdc-unelevated-button {
            white-space: normal;
            padding: 10px;
        }

        .shipTo {
            padding: 10px;
            flex: 1;

            svg {
                height: 20px;
                width: 20px;
            }

            @include media-breakpoint-up(sm) {
                flex-direction: row;
                margin-bottom: 0;
            }
        }

        .viewAddress {
            padding: 10px 20px;

            @include media-breakpoint-up(sm) {
                margin-bottom: 0;
            }
        }

        .orderHistory {
            margin-right: auto;
            width: 100%;

            @include media-breakpoint-up(sm) {
                width: auto;
                margin-bottom: 0;
            }
        }

        @include media-breakpoint-up(sm) {
            flex-direction: row;
        }
    }

    // CSS TO ACCOMODATE THE REUSABLE ADDRESS COMPONENT BUTTONS ENDS

    .business-addresses-sub-header,
    .business-addresses-header {
        display: none !important;
    }

}

.edit-info-modal {
    @include media-breakpoint-down(lg) {
        max-width: 95vw !important;
    }
    .mat-mdc-dialog-container {
        padding: 16px;

        @include media-breakpoint-up(lg) {
            width: 580px;
        }
    }

    .bus-modal {
        &-close {
            background-color: var(--cx-color-primary2);
            padding: 5px;
        }
        .manage-bus-act-title {
            width: 85%;
        } 
    }

    input,
    select {
        outline: none;
    }

    .license-input,
    .expiration-input {
        width: 100%;

        @include media-breakpoint-up(lg) {
            width: 40%;
        }
    }
    
    .mat-checkbox-label {
        white-space: normal;
        width: 100%;

        @include media-breakpoint-up(lg) {
            width: 350px;
        }
    }
    
    .mat-checkbox-inner-container {
        height: 18px;
        width: 18px;
        margin-top: 5px;
    }
}

.bus-modal-select-margin {
    margin-top: 35px;
}

.manage-user {
    &-card {
        background: var(--cx-color-white);
        box-shadow: var(--common-box-shadow);
        width: 100%;

        @include media-breakpoint-up(md) {
            width: 48%;
        }
    }

    &-id {
        border-bottom: 2px solid var(--cx-color-primary2);
    }

    &-login {
        background: var(--cx-p10);
    }

    &-btn {
        .mat-mdc-unelevated-button.mat-white {
            border: 1px dashed;
            padding: 5px 10px;
            text-decoration: underline;
        }
    }
}

body {
    .manage-account-btn {
        text-decoration: none;
    }
}