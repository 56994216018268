body {
  .main-search {
    background: var(--cx-color-white);
    box-shadow: var(--common-box-shadow);

    .searchItem {
      color: var(--searchterm-color);
    }

    .not-selected {
      opacity: 0.3;
    }

    .backToTop {
      height: 16px;
      width: 16px;
    }

    .textCompare {
      color: var(--cx-mat-outline);
    }
    
    .search-container {
      z-index: 9999;
      .searchResultHeader {
        @include media-breakpoint-down(lg) {
          margin: 13px;
        }
      }

      .co-op {
        height: 339px;
        background: var(--cx-color-white);
        box-shadow: var(--common-box-shadow);
        border-radius: 8px;
        margin-top: 28px;
      }

      .compareDiv {
        column-gap: 10px;
      }

      .viewIcon {
        column-gap: 10px;
      }

      .search-header {
        .sortKeys {
          @include media-breakpoint-down(md) {
          }
        }
      }

      .sortKeys {
        @include media-breakpoint-down(lg) {
          align-items: center;
        }

        .compare {
          height: 30px;
          width: 30px;
          border: solid 1px var(--cx-color-primary2);
        }

        .gridViewIcon {
          background-color: var(--cx-color-primary);
        }

        .listViewIcon {
          background-color: var(--cx-color-primary);
        }

        .sortBy {
          padding: 3px;
          border: 1px solid var(--cx-color-black);
          height: 32px;
          @include media-breakpoint-down(lg) {
            margin-right: 10px;
          }
        }
      }
    }

    .mat-form-field-appearance-outline {
      .mat-mdc-text-field-wrapper {
        max-width: 154px;
        max-height: 45px;
      }

      .mat-mdc-form-field-infix {
        padding: 10px 0;
      }
    } 
    @include media-breakpoint-down(md) {
      .sortByFields{
        mat-form-field{
          bottom: 18px;
        }
      }
    }
    @include media-breakpoint-down(sm) {
      .sortByFields{
        mat-form-field{
          bottom: 0px;
        }
      }
    }
  }
}
