// style for reusable alert component

.reusable-alert-box-style{
  @mixin alertbox($prop1, $prop2, $prop3) {
    margin-bottom: 12px;
    padding: 5px 5px 5px 10px;
    box-sizing: border-box;
    border-radius: 2px 0 0 2px;
  
    // box background and border
    background: $prop1;
    border: $prop2;
    border-left: $prop3;
  }
  
  .warningAlertBox {
    @include alertbox(
      var(--cx-warning-error-light),
      1px solid var(--cx-warning-error),
      4px solid var(--cx-warning-error)
    );
  }
  
  .successAlertBox {
    @include alertbox(
      var(--cx-warning-success-light),
      1px solid var(--cx-warning-success),
      4px solid var(--cx-warning-success)
    );
  }

  .successAlertBoxText {
    color: var(--cx-warning-success);
  }

  .warningAlertBoxText {
    color: var(--cx-warning-error);
  }
}

