.download-histry {
  &-section {
    background-color: var(--cx-color-white);
    box-shadow: var(--common-box-shadow);
  }

  &-main {
    border: 1px solid var(--cx-table-border);
    &-header {
      background-color: var(--cx-p2-60);
    }
  }

  &-btn {
    @include media-breakpoint-down(md) {
      margin-left: auto;
    }
    button,a {
      padding: 6px 20px;
      border-radius: 5px;
      &:hover {
        text-decoration: none;
      }
    }

    .mdc-button.mdc-button--unelevated {
      flex-direction: row-reverse;
      .mat-icon {
        height: 18px;
        width: 18px;
        margin-top: -4px;
      }
    }

  }

  &-query {
    &-section {
      width: 45%;
    }
  }

  &-status {
    &-section {
      width: 30%;
    }
  }

  .font-bold {
    font-weight: 600;
  }

  .text-underline {
    text-decoration: underline;
  }
}