/* You can add global styles to this file, and also import other style files */

$styleVersion: 6.6;

// import for the spartacus core styles
$skipComponentStyles: (cx-category-navigation,cx-navigation-ui,nav-heading);
@import "@spartacus/styles";
// import for boxout global styles common across all divisions
$theme-name: undefined !default;

@import "./styles/themes/global/global";

// Theme specific files imports starts

@import "./styles/themes/theme-boxout/theme-boxout";

@import "./styles/themes/theme-chsite/theme-chsite";

@import "./styles/themes/theme-fwsite/theme-fwsite";

@import "./styles/themes/theme-mmsite/theme-mmsite";

@import "./styles/themes/theme-musite/theme-musite";

@import "./styles/themes/theme-omsite/theme-omsite";

@import "./styles/themes/theme-ptsite/theme-ptsite";

@import "./styles/themes/theme-spasite/theme-spasite";

@import "./styles/themes/theme-mesite/theme-mesite";

// Theme specific files imports ends

@import "./styles/themes/global/material";

// custom feature file import
@import "./styles/features/custom-feature/custom-feature.scss";


html {height:auto !important; top: 0!important;}