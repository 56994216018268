body {

  .reset-password {
    width: 300px;

    &-informative-text {
      color: var(--cx-color-primary);
    }

    &-tick {
      color: var(--cx-warning-success);
      // needed for aligning success tick
      margin-left: -16px;
    }

    &-success {
      color: var(--cx-warning-success);
    }
  }
}