@include media-breakpoint-down(md) {
  .AccountPageTemplate {
    .SiteLinkFlexSlot,
    .NavigationBar {
      display: none !important;
    }
  }
}

.my-account-main-container {
  &.left-panel {
    .left-panel-container {
      display: flex;
      flex-direction: column;
    }

    .is-mobile .left-panel-toolbar {
      position: fixed;
      z-index: 2;
    }

    .sidenav-container {
      flex: 1;
      background: none;
    }

    .is-mobile .sidenav-container {
      flex: 1 0 auto;
    }

    .content {
      border-radius: 10px;
      overflow: auto;

      @include media-breakpoint-down(lg) {
        margin-left: 0 !important;
      }
    }

    .is-desktop {
      display: none;
    }

    .user-card {
      left: 0;
      background: var(--cx-color-white);
      box-shadow: none;
      border-bottom: 1px solid var(--cx-color-primary2);
    }

    .orders-list {
      background: var(--cx-color-white);
      box-shadow: none;
      border-radius: 5px 5px 0 0;
      border-bottom: 1px solid var(--side-nav-border-bottom);
    }

    .user-container {
      position: relative;
      text-align: left;
      color: var(--cx-color-white);
      background-color: var(--cx-color-green);
      padding: 6px 10px;
    }

    /*this will create triangle of color white */
    .triangleBorder {
      width: 0;
      height: 0;
      border-top: 14px solid transparent;
      border-bottom: 14px solid transparent;
      border-right: 20px solid var(--cx-color-white);
      ;
      position: absolute;
      right: -1px;
    }

    .centered {
      position: absolute;
      top: 5px;
      left: 10px;
    }

    .button-img {
      width: 13px;
      margin-right: 8px;
      margin-top: 0 !important;
      margin-left: 0;
      margin-bottom: 0;
    }

    .order-img {
      width: 21px;
    }

    .address-heading {
      margin-top: 15px !important;
    }

    .cancel-img {
      width: 9%;
    }

    mat-sidenav {
      border-right: none;
      background: var(--cx-left-panel);
      color: var(--cx-color-white);
      float: left;
    }

    .mat-drawer-container {
      position: static;
    }

    .mat-mdc-card-header-text {
      @extend .ml-0;
      .mat-mdc-card-title {
        line-height: 24px;
        letter-spacing: 0;
      }
    }

    .mat-mdc-list-item {
      border-top: 1px solid var(--side-nav-border-bottom);
    }

    .open-img {
      visibility: hidden;
    }

    .mat-mdc-nav-list .mat-mdc-list-item:hover .open-img {
      visibility: visible;
    }

    .mat-mdc-list-item {
      height: 45px;
    }

    .mat-drawer {
      position: relative;
      max-width: 275px;
      @extend .mr-lg-3;
    }

    .mat-drawer-inner-container {
      overflow: hidden;
    }

    @include media-breakpoint-down(lg) {
      .mat-drawer {
        position: absolute;
      }
    }

    @include media-breakpoint-down(md) {
      .mat-drawer {
        height: 75vh; //BCGI2-1928: mobile scroll issue
        right: 0;
      }
    }

    .mat-drawer-inner-container {
      overflow: auto;
    }

    mat-drawer-content {
      padding: 12px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .mat-drawer-backdrop {
      height: 100%; //BCGI2-1928: mobile scroll issue
    }

    .mat-sidenav-content {
      overflow: hidden;
      @extend .m-0;
    }

    .account-action-btn {
      border: 1px solid var(--cx-color-black);
      padding: 6px 12px 6px 8px;
    }

    .account-action-btn:hover {
      background-color: var(--cx-p2-20);
    }

    .mat-mdc-nav-list .mat-mdc-list-item:hover .list-title {
      border: 1px dotted var(--cx-color-primary2);
      padding: 5px;
    }

    .list-title-open {
      border: 1px dotted var(--cx-color-secondary);
      padding: 5px;
    }

    .list-title {
      width: -webkit-fill-available;
    }
  }
}

.AccountPageTemplate {
  padding: 32px 0;
}

.username-ribbon {
  width: 245px;
  overflow-wrap: break-word;
}

.loyalty-text {
  margin-top: 1px;
}

.text-ribbon {
  word-break: break-all;
  width: 245px;
}

.mdc-list-item__primary-text {
  display: flex;
}

mat-nav-list.mdc-list {
  padding: 0;
}