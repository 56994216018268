.warehouse-receipts {
  .paginate {
    border-top: none;
    border-radius: 0px 0px 7px 7px;
  }
  &-table {
    box-shadow: var(--common-box-shadow);
    overflow-x: auto;
    table {
      tr {
        th:first-of-type {
          width: 55px;
        }
        th:nth-of-type(2) {
          width: 86px;
        }
        th:nth-of-type(3) {
          width: 172px;
        }
        th:nth-of-type(4) {
          width: 146px;
        }
        th:nth-of-type(5) {
          width: 84px;
        }
        th:nth-of-type(6) {
          width: 525px;
        }
        th:nth-of-type(7) {
          width: 107px;
        }
        th:nth-of-type(8) {
          width: 107px;
        }
        th:nth-of-type(9) {
          width: 107px;
        }
        th:nth-of-type(10) {
          width: 107px;
        }
        th:nth-of-type(11) {
          width: 62px;
        }
      }
      th,
      td {
        white-space: nowrap;
      }
    }
    .warehouseReceipts-hyperlink {
      color: var(--hyperlink-blue);
    }
  }
  &.expand-table {
    background-color: var(--cx-color-white);
    box-shadow: var(--common-box-shadow);

    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
    height: 100vh;
    overflow-y: auto;
  }
  &-filter {
    padding: 12px;
    box-shadow: var(--common-box-shadow);
    background-color: var(--cx-color-white);
    min-width: 100%;
    gap: 6px;

    .section-top {
      border-bottom: 1px solid var(--cx-product-border);

      .li-gap {
        gap: 10px;
      }
    }

    .view-select {
      margin-bottom: -15px;
    }

    .select-section {
      mat-select {
        padding-top: 6px;
      }
      .mat-mdc-text-field-wrapper.mdc-text-field--outlined
        .mdc-notched-outline--upgraded
        .mdc-floating-label--float-above {
        --mat-mdc-form-field-label-transform: translateY(-27px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
      }
      .mat-mdc-text-field-wrapper
        .mat-mdc-form-field-flex
        .mat-mdc-floating-label {
        top: 18px;
        left: 3px;
      }
      .mdc-text-field--outlined {
        height: 36px;
      }
      .mat-mdc-text-field-wrapper.mdc-text-field--outlined
        .mat-mdc-form-field-infix {
        padding: 0px;
      }
    }

    .nav-btn {
      // height: 30px !important; //necessary for design
      padding: 5px 12px;
      gap: 10px;
      .filter-icon {
        width: 12px;
        height: auto;
      }
      mat-icon.action-icon {
        width: 10px;
        height: auto;
        margin: 0;
        background-color: var(--cx-warning-success);
      }
    }

    .search {
      border: 1px solid var(--cx-color-primary);
      border-radius: 5px;
      height: 36px;
      flex: 1;

      input {
        color: var(--cx-color-primary);
      }
    }

    .section-mid {
      gap: 17px;
    }

    .section-bottom {
      gap: 16px;
      flex-direction: row;
      .expand-icon {
        border: 1px solid;
        padding: 2px 3px 6px 3px;
        height: 30px;
        width: 30px;

        &:hover {
          cursor: pointer;
        }
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
        flex-direction: column;
        position: relative;
        mat-form-field.view-select {
          width: 100% !important;
          align-self: flex-start;
        }
        
        .expand-icon {
          position: absolute;
          right: 0;
          top: 0;
        }
        
        .calender {
          width: 100%;
        }
      }
      
      .calender {
        gap: 9px;
        .date-section {
          padding: 0 6px;
          background-color: var(--bxd-calender-date-bg);
        }

        .date-last-days-section {
          .last-days-btn {
            padding: 0 6px;
            border-radius: 0;
            background-color: var(--bxd-calender-date-bg);
            color: var(--cx-color-black);
            white-space: nowrap;
          }
        }

        .date-range {
          mat-date-range-input {
            width: 130px;
            margin-right: 10px;
          }
        }
        mat-datepicker-toggle {
          button {
            background-color: var(--cx-color-white);
            outline: none;
            padding: 0;
            margin: 0;
            height: 24px;
            width: 24px;

            .mat-mdc-button-touch-target {
              height: 24px;
              width: 24px;
            }

            span {
              color: var(--cx-color-black);
            }

            mat-icon {
              height: 24px;
              width: 24px;
            }
          }
        }
      }
    }
  }
}

body .no-records-found {
  box-shadow: var(--common-box-shadow);
  background: var(--cx-color-white);
  &-heading {
      color: var(--cx-alert);
  }
}
body .no-records-accoutConfig {
  background:var(--sr-nav);
  &-heading {
      color: var(--cx-color-primary);
  }
}
