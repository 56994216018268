.sr-home {
  
  
  min-height: 100vh; // For making header sticky while scrolling (necessary)
  background-color: var(--cx-p2-10);
  .alerts {
    .sr-home-first-notificaiton {
      background-color: var(--cx-warning-error);
      color: var(--cx-color-white);
      min-height: 25px;
      p {
        margin-bottom: 0;
        a {
          font-weight: bold;
        }
      }
    }
    .sr-home-second-notificaiton {
      @extend .sr-home-first-notificaiton;
      background-color: var(--sr-alert-black);
    }
  }
  &-layout {
    height: 48px;
    position: sticky; // For making header sticky while scrolling
    top: 0;
    //z-index: 1000;

    &-header {
      height: 48px;
      .header-body {
        background-color: var(--cx-color-primary);

        .website-logo {
          .SalesRepHeaderLogo {
            img {
              max-width: 146px;
            }
          }
        }

        .website-name {
          .SalesRepHeader {
            p {
              color: var(--cx-color-white);
              // HTML is coming from backend
              margin-bottom: 0;
              padding-left: 12px;
              // HTML is coming from backend
            }
          }
        }
        .search-field-box {
          .header-search {
            .header-search-field {
              padding-left: 32px; // Required for design
              height: 32px;
              width: 225px;
              background-color: var(--sr-search);
              color: var(--cx-color-black);
              &::placeholder {
                color: var(--cx-color-black);
              }
            }
            .header-search-icon {
              top: 0;
              left: 0;
              width: 32px;
              height: 32px;
              .mat-icon[svgicon="search_white"] {
                height: 15px;
                width: 15px;
                svg {
                  path {
                    fill: var(--cx-color-black);
                  }
                }
              }
            }
          }
        }
      }
    }
    &-sidenav {
      height: calc(100vh - 86px);
      top: 50px;
      position: sticky; 
      @media only screen and (max-width: 768px) { 
      height: calc(100vh - 190px);
      }

      // For sticky sidenav on scroll
      // 

      .sr-nav {
        width: max-content;
        max-width: 160px;
        background-color: var(--sr-sidenav);
        border-right: 1px solid var(--cx-table-vertical-border);
        box-shadow: var(--common-box-shadow);
        transition: all 1s ease-in-out;
        
        .nav-link {
          height: 41px;
          gap: 16px;
          &.active {
            background-color: var(--sr-nav);
          }
          &:last-of-type {
            border-bottom: 1px solid var(--cx-table-vertical-border);
            box-shadow: var(--common-box-shadow);
            margin-bottom: 41px; // Necessary for footer logout button
          }
        }
        .sr-logout-button {
          position: relative; // For bottom left logout button
          bottom: 0;
          height: 41px;
          margin-top: 50px;
          gap: 16px;
          color: var(--cx-color-white);
          background-color: var(--cx-dark-grey);
        }
      }
    }
  }

  &-content {
    flex-grow: 1;

    
    .mat-drawer-container {
      min-height: 653px;
      width: 100%;
      background-color: var(--cx-p2-10);
    }
    .mat-drawer-backdrop {
      opacity: 0.5;
    }
  }
}

.sr-profile-details {
  box-shadow: var(--common-box-shadow);
  .mat-mdc-menu-content:not(:empty) {
    padding-top: 0;
  }
  .sr-profile-description {
    background-color: var(--sr-sidenav);
    gap: 16px;
    .profile-image {
      width: 44px;
      height: 44px;
      background-color: var(--cx-color-primary);
      img.profile-pic {
        width: 44px;
        height: 44px;
      }
    }
  }
  .sr-profile-rule {
    border-top: 2px solid var(--cx-hr-border);
  }
  .sr-link-section {
    .nav-link {
      gap: 16px;
      height: 28px;
      color: var(--cx-10);
      .mat-icon {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.sr-homepage {
  .sr-homepage-container {
    min-height: 400px;
    gap: 16px;
    .sales-rep-bar-graph {
      width: 71%;
      gap: 16px;
      @media only screen and (max-width: 768px) {
        width: 100%;
      }

      .sales-rep-graph-container,
      .sales-rep-header-contentImage {
        background: var(--cx-color-white);
        box-shadow: 0 4px 4px var(--cx-box-shadow);
        border-radius: 6px;
      }
    }
    .sales-rep-announcement {
      width: 29%;
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
      .sales-rep-announcement-container {
        padding: 8px;
        background: var(--cx-color-white);
        box-shadow: 0 4px 4px var(--cx-box-shadow);
        border-radius: 6px;
      }
    }
  }

  .sales-rep-training,
  .sales-rep-multi-component {
    gap: 16px;
    custom-paragraph {
      padding: 12px;
      flex-grow: 1;
      background: var(--cx-color-white);
      box-shadow: 0 4px 4px var(--cx-box-shadow);
      border-radius: 6px;
    }
  }
}
