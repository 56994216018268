body {

  .edit-po-modal,
  .edit-payment-modal {
    .mat-mdc-dialog-container {
      padding: 16px;

      .close {
        background-color: var(--cx-color-primary2);
        padding: 5px;
        min-width: 25px;
        padding: 0;
        opacity: 1;

        span {
          color: var(--cx-color-white);
        }

        &:not(:disabled):not(.disabled):hover,
        &:not(:disabled):not(.disabled):focus {
          opacity: 1;
        }

        &.cdk-program-focused,
        &.cdk-focused {
          color: var(--cx-color-white);
          background-color: var(--cx-color-primary2);
        }
      }

      .paymentdd {
        width: 250px;
      }
      .mat-mdc-form-field .mat-mdc-text-field-wrapper {
        padding-bottom: 0 !important; // overriding default styles for mat-form-field
      }

      .font-weight-medium {
        font-weight: $font-weight-medium;
      }

      .mat-mdc-radio-button.mat-primary {
        .mat-radio__outer-circle {
          border-color: $base-10;
        }
        .mat-radio__inner-circle {
          background-color: $base-10;
        }
      }
    }
  }
}