.email-notification {

  &-header {
    background: var(--cx-p2-60);
  }

  &-main-block {
    background: var(--cx-color-white);
    box-shadow: var(--common-box-shadow);
  }

  &-account-block {
    border: 1px dashed var(--cx-color-primary);
    padding: 24px;
  }

  .accountTitle-color {
    color: var(--cx-dark-grey);
  }

  .gap-5 {
    gap: 5px;
    display: grid;
  }

  .table thead tr th {
    text-transform: inherit;
    color: var(--cx-color-black);
  }

  .table thead tr th:first-of-type {
    width: 80%;
  }

  .table tbody tr {
    border: 1px solid var(--cx-table-border);
  }
  
  .gap-16 {
    gap: 16px;
    display: grid;
  }

}