.header-sitelinks {
  &-link {
    a {
      color: var(--cx-color-black);
    }
  }
}

body.theme-spasite {
  header {
    .header {
      .ProfileTopHeaderSlot {
        .profile-header {
          .header-user {
            .account-num , .account-label {
              color: var(--cx-header-sitelink);
            }
          }
        }
      }
      .HeaderPlaceholderFlexSlot {
        .header-main-dropdowns {
          .account , .quick-order {
            color: var(--cx-header-sitelink);
          }
        }
      }
    }
  }

  .rapid-order-font-color {
    color: var(--cx-color-black);
  }

  .breadcrumb-container {
    .base-site {
      color: var(--cx-color-black);
    }
  }

  .quick-orders-container {
    .set-color {
      color: var(--cx-color-black);
    }
  }

  .link-color {
    color: var(--cx-header-sitelink);
  }

  .favorite-lists {
    #additional-products {
      color: var(--cx-header-sitelink);
    }
  }

  .checkout-main-section {
    .checkout-header {
      font-size: 24px;
    }
  }

  .sr-home-layout-header .header-body .website-name .SalesRepHeader p {
    font-size: 24px;
  }

  .asm-header-powered-by-logo .asm-text {
    color: var(--cx-color-black);
    @include media-breakpoint-up(sm) {
      border-left: 0.5px solid var(--cx-color-black);
    }
  }

  .pdp-container {
    .product-datail-container {
      .text-color {
        color: var(--cx-color-black);
        text-decoration: underline;
      }
    }
  } 

  .sr-change-password-dialog {
    .sr-change-password {
      .security-service-provider-text {
        color: var(--cx-color-black);
      }
    }
  }
  
  .manage-create-user-cancel.mat-mdc-unelevated-button:not(:disabled) {
    color: var(--cx-color-black);
  }

  //Changes for Rewards Page

  .rewards-credits-header .t-rewards-brand-earned .t-rewards-amount-points {
      font-size: 22px;
  }

  .rewards-credits-header .t-rewards-amount-subtitle {
    font-size: 20px;
  }
}