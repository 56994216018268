body{
  .order-confirmation{
    &-heading{
      background: var(--cx-color-white);
      box-shadow: var(--cx-box-shadow);
    }
&-info{ 
  &-status{
    background: var(--cx-color-white);
    border-top: 1px solid var(--cx-color-primary);
    .order-status{
      max-width: 111px;
      .statusName{
        color:var(--cx-color-white);
        background-color:var(--cx-warning-success) ;
      }
      .statusBody{
        color:var(--cx-color-white);
        background-color:var(--cx-color-organic-green) ;
      }
    }
  }
}
  }

}