.category-specific-page-container{

  .banner-image-container{
    .banner-image{
      height: 100px;
    }
  }
  .super-category{
    .super-category-item{
      .super-category-element{
        flex: 1 1;
      }
    }
    .super-category-title{
      background: var(--cx-p2-20);
    }
    .super-category-image{
      background: var(--cx-color-white);
      .super-category-image-item{
        width: 300px;
        min-height: 300px;
        max-height: 300px;
      }
    }
  }

  .super-category-shop-button {
    & button {
      background-color: var(--cx-color-primary);
      color: var(--cx-color-white);
    }
  }

  .lower-category-banner{
   .image-banner{
    height: 130px;
   }
  }

  .sub-category{
    background: var(--cx-color-white);
    box-shadow: var(--common-box-shadow);
    display: grid;
    grid-template-columns: auto auto auto auto;
    background-color: var(--cx-color-white);
    padding: 10px;

    @media screen and (max-width: 1024px){
      grid-template-columns: auto auto auto;
    }

    @media screen and (max-width: 768px){
      grid-template-columns: auto auto;
    }

    @media screen and (max-width: 480px){
      grid-template-columns: auto;
    }

    .sub-category-item{
      border-right: 1px solid var(--grid-border);
      border-bottom: 1px solid var(--grid-border);

      @media screen and (min-width: 1025px){
        &:nth-child(4n){
          border-right: none;
        }
      }
     
      @media screen and (max-width: 1024px) and (min-width:769px){
        &:nth-child(3n){
          border-right: none;
        }
      }

      @media screen and (max-width: 768px) and (min-width: 481px){
        &:nth-child(2n){
          border-right: none;
        }
      }

      @media screen and (max-width: 480px){
        &:nth-child(n){
          border-right: none;
        }
      }
      
      .sub-category-image{
        img{
          width: 200px;
          min-height: 200px;
          max-height: 200px;
      }
      }
      
    }

    .sub-category-link{
      a{
        text-decoration: none !important;
      }
    }

    .sub-category-name{
      width: 190px;
      text-align: center;
      font-weight: bold;
    }

    .sub-category-child-link{
      a{
        text-decoration: none !important;
        color: var(--cx-color-black);
      }
    }
  }
}