.register-main {
  .stepperClass {
    background-color: var(--cx-p2-10);

    .mat-horizontal-content-container {
      padding: 0;
    }
    .mat-horizontal-stepper-header-container {
      background: var(--cx-color-white);
      box-shadow: var(--common-box-shadow);
      margin-bottom: 55px;
    }
  }

  .register-account-main-container {
    .rightBottomSlot{
      background: var(--cx-color-white);
      box-shadow: var(--common-box-shadow);
    }
    .content-slot-left {
      background: var(--cx-color-white);
      box-shadow: var(--common-box-shadow);
      width: 48%;
      border-radius: 6px;
      .mat-mdc-form-field {
        width: 96%;
      }
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
    .content-slot-right {
      width: 48%;
    }
    

    .content-slot-right-step2 {
      background: var(--cx-p2-10);
      width: 48%;
    }
    .rightTopSlot{
      background: var(--cx-color-white);
      box-shadow: var(--common-box-shadow);
    }
    
  }
}
