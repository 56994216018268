/*****************************************************************************************
Name - master-config
Description - All the contextual global variables (brandable elements) used across the site should be defined here.
These variables can be overwritten from brand configs.
******************************************************************************************/

/*base styles*/

///base font styles used for body
$base-font-family: "roboto" !default;
$base-font-weight: $font-weight-regular !default;
$base-line-height: $line-height-xs !default;
$base-background-color: $neutral-10 !default;
$base-font-color: $base-10 !default;

/*:root {
/*
--heading-font-family: 'roboto';

--h2-font-size:  36px;
--h2-font-weight: 400;
--h2-line-height: 38px;
--h2-letter-space: -0.5px;
--h2-font-style: normal;
--h2-font-size-mobile: 30px;
--h2-line-height-mobile: 34px;

--h3-font-size: 32px;
--h3-font-weight: 400;
--h3-line-height: 36px;
--h3-letter-space: 0;
--h3-font-style: normal;
--h3-font-size-mobile: 26px;
--h3-line-height-mobile: 28px;

--h4-font-size: 20px;
--h4-font-weight: 400;
--h4-line-height: 22px;
--h4-letter-space: 0;
--h4-font-style: normal;
--h4-font-size-mobile: 20px;
--h4-line-height-mobile: 22px;

/*
** Category 1 - Buttons
** 1. Background Color for Buttons.
** 2. Text color for buttons.
**/
/*
--btn-primary-bg-color: #1c1c1c;
--btn-primary-text-color: #fff;

/*
** Category 2 - Links
** 1. Color for link text elements.
**/
/*
--link-text-color: #1c1c1c;
--link-text-color-dark-bg: #fff;
*/
/*
** Category 3 -Non Interactive accent color
** 1. Accent Color for non interactive elements.
**/
/*
--non-interactive-accent-color: #FF4338;

/*
** Category 4 -  Interactive accent color
** 1. Accent Color for interactive elements.
**/
/*
--interactive-accent-color: #a9a9a9;

/*
** Category 5 - Background Color
** 1. Background color
**/
/*
--brand-dark-background-color: #000;
--brand-dark-background-text-color: #fff;

/*
** Category 6 - Accent Background Color
** 1. Background color accent
**/
/*
--brand-accent-background-color: #f4f4f4;
--brand-accent-background-text-color: #000;

/*
** Category 7 - UI Elements
** 1. Element Color
** 2. Element background color
**/
/*
--brand-ui-element-color: #FFF;
--brand-ui-element-bg-color: #FF9962;

/*
** Border radius
** 1. Images
** 2. Buttons
** 3. Cards
**/ /*
--brand-buttons-border-radius: 20px;
--brand-images-border-radius: 14px;
--brand-cards-border-radius: 14px;
}*/
/*
}*/

:root {
  --brand-font-family: "roboto";

  --title-m-font-size-viewport-l: 2.5rem;
  --title-m-font-weight: 700;
  --title-m-line-height-viewport-l: 2.75rem;
  --title-m-letter-space: normal;
  --title-m-font-style: normal;
  --title-m-font-size-viewport-xs: 1.875rem;
  --title-m-line-height-viewport-xs: 2rem;

  --title-s-font-size-viewport-l: 1.875rem;
  --title-s-font-weight: 700;
  --title-s-line-height-viewport-l: 2rem;
  --title-s-letter-space: normal;
  --title-s-font-style: normal;
  --title-s-font-size-viewport-xs: 1.375rem;
  --title-s-line-height-viewport-xs: 1.625rem;

  --t-subtitle-xl-font-size-viewport-l: 1.375rem;
  --t-subtitle-xl-font-weight: 700;
  --t-subtitle-xl-line-height-viewport-l: 1.625rem;
  --t-subtitle-xl-letter-space: normal;
  --t-subtitle-xl-font-style: normal;
  --t-subtitle-xl-font-viewport-xs: 1.375rem;
  --t-subtitle-xl-line-viewport-xs: 1.625rem;

  --xs-max-width: auto;
  --s-max-width: 540px;
  --m-max-width: 768px;
  --l-max-width: 960px;
  --xl-max-width: 1140px;

  // --btn-primary-bg-color: #1c1c1c;
  // --btn-primary-txt-color: #ffffff;
  // --btn-primary-border-color: #1c1c1c;

  // --link-text-color: #1c1c1c;
  // --link-text-color-inverse: #fff;
  // --link-border-color: #1c1c1c;

  // --icon-color-decorative: #ff9662;

  /*
--brand-dark-background-color: #000;
--brand-dark-background-text-color: #fff;

--brand-accent-background-color: #f4f4f4;
--brand-accent-background-text-color: #000;

--brand-ui-element-color: #FFF;
--brand-ui-element-bg-color: #FF9962;

--brand-buttons-border-radius: 20px;
--brand-images-border-radius: 14px;
--brand-cards-border-radius: 14px;
*/
}

/*typography*/
/// map for typography variables

$typography: (
  "display": (
    "l": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          3.5625rem,
          4rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
    "m": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          2.8125rem,
          3.25rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
    "s": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          2.25rem,
          2.75rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
  ),
  "headline": (
    "l": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          2rem,
          2.5rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
    "m": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.75rem,
          2.25rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
    "s": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.5rem,
          2rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
  ),
  "title": (
    "l": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.375rem,
          1.75rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
    "m": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1rem,
          1.5rem,
        ),
      ),
      "font-weight": 500,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "med": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1rem,
          1.5rem,
        ),
      ),
      "font-weight": 500,
      "color": $neutral-40,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "s": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.875rem,
          1.4375rem,
        ),
      ),
      "font-weight": 500,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "m-regular": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1rem,
          1.5rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "m-bold": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1rem,
          1.5rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
  ),
  "title-light": (
    "m": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1rem,
          1.5rem,
        ),
      ),
      "font-weight": 300,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
  ),
  "body": (
    "l": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1rem,
          1.5rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.5px,
    ),
    "m": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.875rem,
          1.25rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.25px,
    ),
    "s": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.75rem,
          1rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.4px,
    ),
    "s-bold": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.75rem,
          1rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.4px,
    ),
  ),
  "label": (
    "l": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.875rem,
          1.25rem,
        ),
      ),
      "font-weight": 500,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "m": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.75rem,
          1rem,
        ),
      ),
      "font-weight": 500,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "s": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.6875rem,
          1rem,
        ),
      ),
      "font-weight": 500,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
  ),
  "subhead": (
    "normal": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.875rem,
          1.25rem,
        ),
      ),
      "font-weight": 500,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
  ),
  "form": (
    "font": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.875rem,
          1.25rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "column-header": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.875rem,
          1.25rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.25px,
    ),
  ),
  "userid": (
    "login": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.1875rem,
          1.75rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0,
    ),
    "text": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.8125rem,
          1.5rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.5px,
    ),
    "text-bold": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.8125rem,
          1.5rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.5px,
    ),
  ),
  "address": (
    "edit": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.6875rem,
          1rem,
        ),
      ),
      "font-weight": 500,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.5px,
    ),
    "type": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.5rem,
          1rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.4px,
    ),
  ),
  "menucategory": (
    "normal": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.875rem,
          1.25rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
  ),
  "menu": (
    "responsive": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.875rem,
          1.25rem,
        ),
      ),
      "font-weight": 700,
      "color": $alert-80,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
  ),
  "subcategory": (
    "normal": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.8125rem,
          1.25rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
  ),
  "acctno-text": (
    "regular": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.75rem,
          1rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
  ),
  "section": (
    "header": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.6875rem,
          1.5rem,
        ),
      ),
      "font-weight": 900,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
  ),
  "order": (
    "status": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.75rem,
          1rem,
        ),
      ),
      "font-weight": 900,
      "color": $base-20,
      "inverse-color": $base-10,
      "letter-spacing": 0.1px,
    ),
    "price": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1rem,
          1rem,
        ),
      ),
      "font-weight": 800,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "totalPrice": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.3125rem,
          1.3125rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "fraction": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.625rem,
          1.25rem,
        ),
      ),
      "font-weight": 500,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "medicalInfoNpi": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.0625rem,
          1.5rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
  ),
  "numbers": (
    "l": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          5.625rem,
          5.625rem,
        ),
      ),
      "font-weight": $font-weight-medium,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": -0.188rem,
    ),
    "s": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.875rem,
          1.875rem,
        ),
      ),
      "letter-spacing": -0.031rem,
      "font-weight": $font-weight-medium,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
  ),
  "headerLink": (
    "normal": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.0625rem,
          1.25rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
  ),
  "searchResults": (
    "header": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.6875rem,
          1.6875rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "banner-desc": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.4375rem,
          1.5rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "banner-desc-bold": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.4375rem,
          1.5rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "mobile-header": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.9375rem,
          1.6875rem,
        ),
      ),
      "font-weight": 900,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "mobile-subtext": (
      "font-family": "roboto",

      "font-size-map": (
        viewport-xs: (
          0.9375rem,
          1.6875rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "facet": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.8125rem,
          0.8125rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "showMore": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.8125rem,
          0.8125rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
  ),
  "nav": (
    "subCategory": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.8125rem,
          0.8125rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
  ),
  "product": (
    "description": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1rem,
          1rem,
        ),
      ),
      "font-weight": 500,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
  ),
  "tableData": (
    "normal": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.875rem,
          1.25rem,
        ),
      ),
      "font-weight": 300,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "mobile-heading": (
      "font-family": "Roboto",
      "font-style": normal,
      "font-size-map": (
        viewport-xs: (
          0.6875rem,
          1.25rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.25px,
    ),
    "mobile-content": (
      "font-family": "Roboto",
      "font-style": normal,
      "font-size-map": (
        viewport-xs: (
          0.875rem,
          1.25rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.25px,
    ),
  ),
  "productTile": (
    "productGroup": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.6875rem,
          1.25rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "iconAlert": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.625rem,
          0.6875rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 1.1px,
    ),
    "currencyDollar": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.625rem,
          1.25rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 1.1px,
    ),
    "description": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1rem,
          1.5rem,
        ),
      ),
      "font-weight": 500,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "brand": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.875rem,
          1.4375rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "teaser": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.875rem,
          1.25rem,
        ),
      ),
      "font-weight": 300,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "boldPrice": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.625rem,
          1.4375rem,
        ),
      ),
      "font-weight": 900,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "priceTeaser": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.75rem,
          1.4375rem,
        ),
      ),
      "font-weight": 300,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "availability-Instock": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.0625rem,
          1.5rem,
        ),
      ),
      "font-weight": 900,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.25px,
    ),
    "orderDetailHeader": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.125rem,
          1.5rem,
        ),
      ),
      "font-weight": 900,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.25px,
    ),
    "availability-Instock-description": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.0625rem,
          1.5rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
  ),
  "price": (
    "tile": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.8125rem,
          1.25rem,
        ),
      ),
      "font-weight": 500,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.25px,
    ),
    "tile-s": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.565rem,
          1.25rem,
        ),
      ),
      "font-weight": 500,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.25px,
    ),
    "currenyTile": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.5rem,
          1.25rem,
        ),
      ),
      "font-weight": 800,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.25px,
    ),
    "currenyTile-s": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1rem,
          1.25rem,
        ),
      ),
      "font-weight": 800,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.25px,
    ),
    "currenyFractionTile": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.8125rem,
          1.25rem,
        ),
      ),
      "font-weight": 500,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.25px,
    ),
    "currenyFractionTile-s": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.625rem,
          1.25rem,
        ),
      ),
      "font-weight": 500,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.25px,
    ),
    "purchaseUomTile": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.1875rem,
          1.25rem,
        ),
      ),
      "font-weight": 300,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.25px,
    ),
    "purchaseEachTile": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.75rem,
          1.25rem,
        ),
      ),
      "font-weight": 300,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.25px,
    ),
    "was": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.625rem,
          1.4375rem,
        ),
      ),
      "font-weight": 500,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.25px,
    ),
    "reward-tile": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.5rem,
          1.25rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.25px,
    ),
  ),
  "table": (
    "l": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1rem,
          1.4375rem,
        ),
      ),
      "font-weight": 600,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
    "m": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.75rem,
          1rem,
        ),
      ),
      "font-weight": 600,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "s": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.75rem,
          1rem,
        ),
      ),
      "font-weight": 500,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
  ),
  "product-details": (
    "sku": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.75rem,
          1rem,
        ),
      ),
      "font-weight": 500,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.5px,
    ),
    "sku-code": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.813rem,
          1rem,
        ),
      ),
      "font-weight": 800,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.4px,
    ),
    "product-summary": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1rem,
          1.25rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $background-02,
      "letter-spacing": 0.25px,
    ),
    "availability-Instock": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.25rem,
          1.5rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.25px,
    ),
    "availability-Instock-description": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.25rem,
          1.5rem,
        ),
      ),
      "font-weight": 900,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.25px,
    ),
    "product-delivery": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.125rem,
          1.5rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.25px,
    ),
  ),
  "cart": (
    "heading": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.25rem,
          1.625rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "product-name": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.125rem,
          1.5rem,
        ),
      ),
      "font-weight": 500,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.25px,
    ),
    "product-number": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1rem,
          1rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.25px,
    ),
    "qty": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1rem,
          1.25rem,
        ),
      ),
      "font-weight": 800,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.25px,
    ),
    "creditModalHeading": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.812rem,
          0.975rem,
        ),
      ),
      "font-weight": 500,
    ),
    "creditModalBody": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1rem,
          1.5rem,
        ),
      ),
      "font-weight": 400,
    ),
    "creditModalTotal": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1rem,
          1.5rem,
        ),
      ),
      "font-weight": 700,
    ),
    "creditModalBtn": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.875rem,
          1.25rem,
        ),
      ),
      "font-weight": 500,
    ),
    "creditModalFinalAmount": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          2.25rem,
          2.7rem,
        ),
      ),
      "font-weight": 800,
    ),
  ),
  "line_promo": (
    "heading": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.25rem,
          1.5rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.25px,
    ),
    "desc": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1rem,
          1.5rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.25px,
    ),
  ),
  "order_promo": (
    "heading": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.0625rem,
          1.25rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "desc": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.875rem,
          1.125rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.25px,
    ),
  ),
  "applied_order_promo": (
    "heading": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.825rem,
          1.5rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.25px,
    ),
  ),
  "mixMatch": (
    "heading": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.0625rem,
          1.25rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "regular": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.0625rem,
          1.0625rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "desc": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.875rem,
          1.125rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.25px,
    ),
    "desc-2": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.875rem,
          1.5rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.25px,
    ),
    "offer-heading": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.4375rem,
          2.25rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
    "brand": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.625rem,
          1.5rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.25px,
    ),
    "product-name": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1rem,
          1.5rem,
        ),
      ),
      "font-weight": 500,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "sku": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.625rem,
          1.5rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.25px,
    ),
  ),
  "promoSlider": (
    "heading": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.125rem,
          1.5rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.25px,
    ),
    "name": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.125rem,
          1.5rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.25px,
    ),
    "desc": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.875rem,
          1.5rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.25px,
    ),
    "amount": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.875rem,
          1.5rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.25px,
    ),
  ),
  "sales-rep": (
    "header": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.0625rem,
          1.25rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "header-msg": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.75rem,
          1.25rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "search": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.825rem,
          1.25rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "nav": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.8125rem,
          1.25rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "sidenav": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.75rem,
          1.25rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "sidenav-footer": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.375rem,
          1.25rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "version": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.375rem,
          1.25rem,
        ),
      ),
      "font-weight": 500,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
  ),
  "productRestricted": (
    "header": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          3.125rem,
          4rem,
        ),
      ),
      "font-weight": 800,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
  ),
  "salesRepGraph": (
    "header": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1rem,
          1.25rem,
        ),
      ),
      "font-weight": 500,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
  ),
  "salesRepGraphPrice": (
    "price": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1rem,
          1.25rem,
        ),
      ),
      "font-weight": 300,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": -0.9px,
    ),
  ),
  "salesRepGraphSubHeader": (
    "subheader": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.625rem,
          1.25rem,
        ),
      ),
      "font-weight": 500,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
  ),
  "sr_search": (
    "org": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.625rem,
          1.25rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
    "company": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.1875rem,
          1.25rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
    "email": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.75rem,
          1.25rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
    "acct": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.75rem,
          1.25rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
    "acct-type": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.5rem,
          1.25rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
    "acct-type-info": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.5rem,
          1.25rem,
        ),
      ),
      "font-weight": 500,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
    "alert": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.75rem,
          1.25rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "bill": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.5625rem,
          1.25rem,
        ),
      ),
      "font-weight": 500,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
    "bill-value": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.6875rem,
          1.25rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
    "card": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.5625rem,
          1.25rem,
        ),
      ),
      "font-weight": 300,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
    "card-holder": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.5625rem,
          1.25rem,
        ),
      ),
      "font-weight": 800,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
  ),
  "userImpersonate": (
    "heading": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.75rem,
          2.25rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
    "sub-heading": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1rem,
          1.5rem,
        ),
      ),
      "font-weight": 500,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "main": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1rem,
          1.5rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "warn-msg": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.875rem,
          1.5rem,
        ),
      ),
      "font-weight": 500,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
  ),
  "asm": (
    "text": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.6875rem,
          0.875rem,
        ),
      ),
      "font-weight": 900,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "account-number": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.5rem,
          0.75rem,
        ),
      ),
      "font-weight": 900,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "account-name": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.5rem,
          0.75rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "close": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.6875rem,
          1.25rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
  ),
  "pageNotFound": (
    "heading": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          3.688rem,
          2.25rem,
        ),
      ),
      "font-weight": 800,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
  ),
  "bxdPortal": (
    "printLabelBold": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1rem,
          1.625rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
    "ordersCarrierDrop": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1rem,
          1.25rem,
        ),
      ),
      "font-weight": 400,
    ),
    "printLabelSmall": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1rem,
          1.625rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
    "printLabelMedium": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1rem,
          1.625rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
    "printLabelFooter": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.75rem,
          1.625rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
    "wro-submessage": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.75rem,
          1.8666rem,
        ),
      ),
      "font-weight": 400,
    ),
    "wro-message": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.875rem,
          1.3125rem,
        ),
      ),
      "font-weight": 700,
    ),
    "wro-status": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.875rem,
          1.0625rem,
        ),
      ),
      "font-weight": 500,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
    "wro-subHeading": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.8125rem,
          1.4375rem,
        ),
      ),
      "font-weight": 500,
      "letter-spacing": 0.1px,
    ),
    "createdDate": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.6875rem,
          1.4375rem,
        ),
      ),
      "font-weight": 400,
      "letter-spacing": 0.1px,
      "color": $base-10,
    ),
    "keyAction": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.9375rem,
          1.5rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
    ),
    "headerText": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.0625rem,
          1.25rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "updatePassword": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1rem,
          1.75rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
    "mfaAuthHeading": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.75rem,
          2.25rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
    "product-buttonText": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.5rem,
          0.68rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 1.1px,
    ),
    "product-statusIcon": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.5rem,
          0.625rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
    "inputLabel": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.75rem,
          1.5rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.5px,
    ),
    "textSeparator": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.937rem,
          1.25rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
    "dashBoardHeading": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1rem,
          1.5rem,
        ),
      ),
      "font-weight": 500,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "productDetailsTableHeader": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.75rem,
          1.25rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "productDetailsTableRecords": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.625rem,
          1.25rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "totalUnits": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.5rem,
          0.5rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "totalUnitsNumber": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.4375rem,
          1.5rem,
        ),
      ),
      "font-weight": 600,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "outOfStockHeading": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.625rem,
          0rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "outOfStockNumber": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.0625rem,
          1.5rem,
        ),
      ),
      "font-weight": 400,
      "letter-spacing": 0.1px,
    ),
    "productActiveStatus": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.5rem,
          0.68rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
    "productNavSearch": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.625rem,
          1.5rem,
        ),
      ),
      "font-weight": 300,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "productNavLocDrop": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.75rem,
          1.5rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "letter-spacing": 0.5px,
    ),
    "productNavTabBtn": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.75rem,
          1.25rem,
        ),
      ),
      "font-weight": 500,
      "letter-spacing": 0.1px,
    ),
    "productNavBtn": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.75rem,
          1.25rem,
        ),
      ),
      "font-weight": 400,
    ),
    "inventoryTransaction-table-record": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.75rem,
          1.25rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "inventoryTransaction-calender": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.75rem,
          1.25rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "toggleButtonLabel": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.875rem,
          1.25rem,
        ),
      ),
      "font-weight": 500,
      "letter-spacing": 0.1px,
    ),
    "inventoryQuarantinedLastRefresh": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.625rem,
          1.5rem,
        ),
      ),
      "font-weight": 500,
      "letter-spacing": 0.1px,
    ),
    "currentOpenOrder": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.75rem,
          1.5rem,
        ),
      ),
      "font-weight": 500,
      "letter-spacing": 0.1px,
    ),
    "reports-table-record": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.75rem,
          1.25rem,
        ),
      ),
      "font-weight": 500,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "wroReceivingTitle": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.1875rem,
          1.25rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
    ),
    "wroTitleDraft": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.8125rem,
          1.75rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
    ),
    "wroTableDataText" : (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.75rem,
          1.25rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "letter-spacing": 0.1px,
    ),
    "stepHeading" : (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.1875rem,
          1.25rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
    ),
    "stepLabel" : (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.125rem,
          1.6875rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
    ),
    "stepSubLabel" : (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.875rem,
          1.75rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
    ),
    "stepLabel-bold" : (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.125rem,
          1.6875rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
    ),
    "client-ref-no": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.0625rem,
          1.25rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
    ),
    "client-ref-bold": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.0625rem,
          1.25rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
    ),
    "file-type" : (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.75rem,
          1.75rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
    ),
    "warehouse-shipping-font" : (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.125rem,
          1.25rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
    ),

    "stepSubHeading" : (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.875rem,
          1.75rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
    ),
    "dragDrop-modal-subtext": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.125rem,
          1.5rem,
        ),
      ),
      "font-weight": 500,
      "letter-spacing": 0.1px,
    ),
    "shippingType": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.75rem,
          0.9375rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
    )
  ),
  "rewards": (
    "brand-name": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.625rem,
          2rem,
        ),
      ),
      "font-weight": 900,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
    "amount-subtitle": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.75rem,
          0.88rem,
        ),
      ),
      "font-weight": 900,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 3px,
    ),
    "amount-points": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.5rem,
          1.75rem,
        ),
      ),
      "font-weight": 800,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
    "brand-earned": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.43rem,
          1.62rem,
        ),
      ),
      "font-weight": 800,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
    "details-label": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1rem,
          1.255rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
    "label": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.75rem,
          1rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.4px,
    ),
    "value": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.25rem,
          1.5rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "brand-earned-lifetime": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.25rem,
          1.466rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
    "brand-earned-note": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1rem,
          1.25rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
    "brand-earned-value": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.25rem,
          1.5rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
      "letter-spacing": 0.1px,
    ),
    "available-amount": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          2.8125rem,
          1.6131,
        ),
      ),
      "font-weight": 800,
      "letter-spacing": 2px,
    ),
    "freeFreight-normal": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.75rem,
          1rem,
        ),
      ),
      "font-weight": 400,
      "letter-spacing": 0.4px,
    ),
    "freeFreight-bold": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.75rem,
          1rem,
        ),
      ),
      "font-weight": 700,
      "letter-spacing": 0.4px,
    ),
    "freeFreight-success": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.75rem,
          1.125rem,
        ),
      ),
      "font-weight": 700,
      "letter-spacing": 0.25px,
    ),
    "freeFreight-label": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.875rem,
          1.125rem,
        ),
      ),
      "font-weight": 700,
      "letter-spacing": 0.25px,
    ),
    "rewards-available": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.75rem,
          0.88rem,
        ),
      ),
      "font-weight": 900,
      "letter-spacing": 2px,
    ),
    "earned-credit": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1rem,
          1.255rem,
        ),
      ),
      "font-weight": 400,
    ),
    "record-text-subtitle": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.625rem,
          2.039rem,
        ),
      ),
      "font-weight": 900,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
    "reward-additional-text": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.8125rem,
          1.02rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
    "order-text": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.062rem,
          1.5rem,
        ),
      ),
      "font-weight": 700,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
    "order-brand-text": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.062rem,
          1.5rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
    "total-earned-label": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.625rem,
          2rem,
        ),
      ),
      "font-weight": 900,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
    "total-earned-val": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          1.437rem,
          1.686rem,
        ),
      ),
      "font-weight": 800,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
    "brand-earned-custom": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          2.625rem,
          2rem,
        ),
      ),
      "font-weight": 800,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
    "tooltip-text": (
      "font-family": "roboto",
      "font-size-map": (
        viewport-xs: (
          0.875rem,
          1.25rem,
        ),
      ),
      "font-weight": 400,
      "color": $base-10,
      "inverse-color": $base-20,
    ),
  ),
) !default;

.cursor-pointer {
  cursor: pointer;
}
// /* buttons styles*/
// $button-font-family: "roboto" !default;
// $button-font-weight: 700 !default;
// $button-primary-bg-color: var(--btn-primary-bg-color) !default;
// $button-primary-txt-color: var(--btn-primary-txt-color) !default;
// $button-primary-border-color: var(--btn-primary-border-color) !default;
// $button-primary-hover-bg-color: rgba($base-20, 0.2) !default;
// $button-primary-hover-txt-color: $base-20 !default;
// $button-primary-hover-border-color: rgba($base-20, 0.2) !default;

// $button-primary-inverse-bg-color: $base-20 !default;
// $button-primary-inverse-txt-color: $base-10 !default;
// $button-primary-inverse-border-color: $base-20 !default;
// $button-primary-inverse-hover-bg-color: rgba($base-10, 0.2) !default;
// $button-primary-inverse-hover-txt-color: $base-10 !default;
// $button-primary-inverse-hover-border-color: rgba($base-10, 0.2) !default;

// $button-secondary-bg-color: transparent !default;
// $button-secondary-txt-color: $base-10 !default;
// $button-secondary-border-color: $base-10 !default;
// $button-secondary-hover-bg-color: $base-10 !default;
// $button-secondary-hover-txt-color: $base-20 !default;
// $button-secondary-hover-border-color: $base-10 !default;

// $button-secondary-inverse-bg-color: transparent !default;
// $button-secondary-inverse-txt-color: $base-20 !default;
// $button-secondary-inverse-border-color: $base-20 !default;
// $button-secondary-inverse-hover-bg-color: $base-20 !default;
// $button-secondary-inverse-hover-txt-color: $base-10 !default;
// $button-secondary-inverse-hover-border-color: $base-20 !default;

// $button-inverse-border: 1px solid $base-20 !default;

// $button-cancel-link-bg-color: transparent !default;
// $button-cancel-link-txt-color: $base-10 !default;
// $button-cancel-link-border-color: transparent !default;

// $button-disabled-bg-color: $neutral-30 !default;
// $button-disabled-txt-color: $base-20 !default;
// $button-disabled-border-color: $neutral-30 !default;

// $button-transition: color 0.15s ease-in-out, background-color 0.25s ease-in-out,
//   border-color 0.15s ease-in-out !default;

// $button-sizes: (
//   "s": (
//     "font-size": 0.8125rem,
//     "line-height": 1rem,
//     "height": 2rem,
//     "padding": 1rem,
//     "border-radius": 22px,
//   ),
//   "m": (
//     "font-size": 1rem,
//     "line-height": 1.375rem,
//     "height": 40px,
//     "padding": 24px,
//     "border-radius": 27px,
//   ),
//   "l": (
//     "font-size": 1.125rem,
//     "line-height": 1.5rem,
//     "height": 3.375rem,
//     "padding": 2rem,
//     "border-radius": 2rem,
//   ),
// ) !default;

// /*link styles*/
// $link-text-color: var(--link-text-color) !default;
// $link-text-color-inverse: var(--link-text-color-inverse) !default;
// $link-font-weight: $font-weight-medium !default;
// $link-tertiary-button-font-family: "roboto" !default;
// $link-tertiary-button-color: $base-10 !default;
// $link-tertiary-button-font-size: 0.8125rem !default;
// $link-tertiary-button-font-weight: $font-weight-medium !default;
// $link-tertiary-button-hover-font-weight: $font-weight-medium !default;
// $link-tertiary-button-hover-color: $base-20 !default;
// $link-bottom-padding: 8px !default;
// $link-border: 2px solid var(--link-border-color) !default;

// /***
// Header Styles
// **/
// $header-primary-link-font-family: "roboto" !default;
// $header-primary-link-font-weight: $font-weight-regular !default;
// $header-primary-link-font-size: $font-s !default;
// $header-primary-link-line-height: $line-height-s !default;
// $header-primary-link-color: $neutral-40 !default;

// $header-primary-link-active-font-weight: $font-weight-medium !default;

// $header-primary-link-font-size-mobile: $font-m !default;
// $header-primary-link-color-mobile: $base-10 !default;

// $header-secondary-link-font-family: "roboto" !default;
// $header-secondary-link-font-weight: $font-weight-medium !default;
// $header-secondary-link-font-size: $font-xs !default;
// $header-secondary-link-line-height: $line-height-l !default;

// $global-nav-menu-font-family: "roboto" !default;
// $global-nav-menu-color: $base-20 !default;
// $global-nav-menu-font-size: $font-m !default;
// $global-nav-menu-font-weight: $font-weight-regular !default;

// /*icon variables*/
// $icon-color: $base-10 !default;
// $icon-color-alt: $neutral-40 !default;
// $icon-color-inverse: $base-20 !default;
// /*$icon-color-inverse-alt: TBD !default;*/
// $icon-color-decorative: var(--icon-color-decorative) !default;
// /*$icon-color-decorative-alt: TBD !default;*/

// /*forms*/
// $label-font-color: $neutral-40 !default;
// $label-font-size: $font-xs !default;
// $label-line-height: $line-height-xs !default;
// $label-font-weight: $font-weight-medium !default;

// $input-font-color: $neutral-40 !default;
// $input-font-size: $font-l !default;
// $input-line-heights: $line-height-xs !default;
// $input-font-weight: $font-weight-medium !default;

// $input-border-color: $neutral-30 !default;
// $input-border-focus-color: $base-10 !default;
// $input-padding-bottom: 1rem !default;
// $input-border-bottom-width: 2px !default;

// $form-error-color: $alert-10 !default;

// $checkbox-background: $base-20 !default;
// $checkbox-border: $base-10 !default;
// $checkbox-checked: $base-20 !default;
// $checkbox-background-checked: $base-10 !default;

// $radio-button-background: $base-20 !default;
// $radio-button-border: $base-10 !default;
// $radio-button-checked: $base-20 !default;
// $radio-button-background-checked: $base-10 !default;
