.theme-ptsite {
  --cx-color-primary: #004a8f;
  --cx-color-primary2: #72bef2;
  --cx-me-light-purple: #fdebff;
  --cx-color-white: #fff;
  --cx-color-secondary: #dc6a00;
  --cx-color-secondary2: #5c5c5c; 
  --cx-color-black: #000;
  --cx-color-green: #0b821e;
  --cx-color-organic-green: #2a8830; //#39b54a;
  --cx-color-darkred: #800514;
  --cx-color-darkOrange: #be4b00;
  --cx-color-darkBlue: #0b639f;
  --cx-color-circleBorder: #eeeeee;
  --cx-buttons-border-radius: 0.25rem;
  --mdc-list-list-item-label-text-line-height: 30px;
  --cx-p10: #e5f0fb;
  --cx-p20: #b2d4f4;
  --cx-p40: #7fb8ed;
  --cx-p60: #66aaea;
  --cx-p70: #fcddec;
  --cx-p80: #338ee3;
  --cx-p2-10: #f2f8fe;
  --cx-p2-20: #e6f2fb;
  --cx-p2-40: #cde3f8;
  --cx-p2-60: #b3d7f4;
  --cx-p2-80: #9bcaf1;
  --cx-p2-90: #7dc5f5;
  --cx-alert: #cd4b13; // #ef8354;
  --cx-info: #52afef;
  --cx-warn: #efd652;
  --cx-success: #52efa4;
  --cx-success-lite: #e7f1e3;
  --cx-success-dark: #205616;
  --cx-box-shadow: rgba(0 0 0 / 25%);
  --cx-warning-success: #2a7c1d;
  --cx-warning-success-light: #e7f1e3;
  --cx-warning-error-light: #ffeff1;
  --cx-warning-error: #be0017;
  --cx-rewards-bg-light: #fdebff;
  --cx-rewards-bg: #746caf;
  --cx-input-text-color: #49454f;
  --cx-mat-caret: #bcc8d0;
  --cx-mat-outline: #79747e;
  --cx-mat-input-color: #1c1b1f;
  --cx-p2-light-grey: #345666;
  --cx-p2-dark-grey: #1d192b;
  --cx-dark-grey: #455a64;
  --cx-shadow-grey: #eee;
  --cx-lt-primary-container: #b0bec5;
  --cx-table-row: #f4f5f9;
  --cx-table-border: #dfdfdf;
  --cx-table-cell-border: #0000001f;
  --cx-hr-border: #717171;
  --cx-multiple-order: #2a8830; //#39b54a;
  --cx-global-inventory: #eaeaea;
  --cx-drop-ship: #fff2ec;
  --cx-error-bar: #ffd6db;
  --cx-facet-count: #f9ece6;
  --cx-select-scroll: #757575;
  --cx-table-scroll: #c4c4c4;
  --cx-mat-option: #1d1d1d;
  --cx-table-order-col:var(--cx-color-primary);// #58afe2;
  --cx-table-header: #464f60;
  --cx-table-column: #0f1112;
  --cx-table-vertical-border: #b7b7b7;
  --cx-table-horizontal-border: #e8eaeb;
  --cx-mat-label: #cde3f8;
  --btn-primary-bg-color: var(--cx-color-primary);
  --btn-primary-txt-color: var(--cx-color-white);
  --btn-primary-border-color: var(--cx-color-primary);
  --btn-primary-hover-bg: var();
  --btn-primary-disabled-bg: rgba(0 0 0 / 12%);
  --link-text-color: var(--cx-color-primary);
  --link-text-color-inverse: var(--cx-color-white);
  --link-border-color: var(--cx-color-primary);
  --btn-secondary-bg-color: var(--cx-p2-40);
  --btn-secondary-txt-color: var(--cx-p2-dark-grey);
  --btn-secondary-border-color: var(--cx-p2-dark-grey);
  --btn-secondary-hover-bg: rgba(29 25 43 / 8%);
  --btn-secondary-focused-bg: rgba(29 25 43 / 12%);
  --btn-secondary-disabled-bg: rgba(31 31 31 / 12%);
  --btn-white-bg-color: var(--cx-color-white);
  --btn-white-txt-color: var(--cx-dark-grey);
  --btn-white-border-color: var(--cx-dark-grey);
  --btn-white-hover-bg: var(--cx-p2-20);
  --btn-white-focused-bg: rgba(171 171 171 / 12%);
  --btn-white-disabled-bg: var(--cx-color-white);
  --btn-disabled-txt-clr: var(--cx-mat-input-color);
  --dark-bg-color: var(--cx-color-primary);
  --light-bg-color: var(--cx-color-white);
  --main-content-background: var(--cx-p2-10);
  --common-box-shadow: 0 4px 4px rgba(0 0 0 / 25%);
  --exclamation-icon-color: #b3261e;

  --cx-pagination-border: #dfdfdf;
  --yellow-bg-color: #ffdc5f;
  --flag-blue: #0e21d0;

  --status-blue: #0c7bc6; // #1196f1;
  --status-orange: #f47d30;
  --status-grey: #565656;

  --link-color: #007bff;
  --cx-step-outline: #765ef6;

  --cx-view-all: var(--cx-color-primary);
  --cx-clearFilter: var(--cx-color-primary);
  --cx-header-sitelink-bg: var(--cx-p10);
  --cx-minicart: var(--cx-color-primary);
  --cx-header-sitelink: var(--cx-color-primary);
  --cx-footer-distribution: var(--cx-color-primary2);
  --cx-footer-contact-us: var(--cx-p10);
  --cx-footer-info-link: var(--cx-p10);
  --cx-modal-close: var(--cx-color-primary2);
  --cx-left-panel: var(--cx-p10);
  --business-addresses-sub-header: var(--cx-p10);
  --business-addresses-number: var(--cx-color-black);
  --header-sitelinks-signin: var(--btn-secondary-bg-color);
  --sr-search: var(--cx-color-white);
  --sr-sidenav: #f5f5f5;
  --sr-nav: #e1e1e1;
  --sr-alert-black: #18262f;
  --sr-green-tag: #62bb46;
  --sr-purple-tag: #bb4693;
  --sr-blue-tag: #004a8f;
  --grid-border:#d9d9d9;
  --save-amount: #d4ff00;
  --cx-free-freight: #c5ff4b;

  --cx-login-a: var(--cx-color-primary);
  --cx-number-color: var(--cx-color-white);
  --cx-row-view-show-option-button: var(--cx-color-primary);
  --cx-row-view-down-arrow: var(--cx-p60);
  --cx-cart:var(--cx-minicart);
  --btn-signIn-color:var(--btn-secondary-bg-color);
  --footer-distribution-text: var(--cx-color-black);
  --login-page-text: var(--cx-color-primary);
  --login-get-started-text: var(--cx-dark-grey);
  --row-view-close-option: var(--cx-color-black);
  --show-more-color: var(--cx-color-primary);
  --cx-color-keep-updated: var(--cx-color-black);
  --nav-color: var(--cx-color-white);
  --login-page-heading: var(--cx-color-primary);
  --searchterm-color: var(--cx-color-primary);
  --pagination-color: var(--cx-color-black);
  --navbar-bg: var(--cx-color-primary);
  --create-bus-btn: var(--cx-color-primary);
  --reports-bg: var(--cx-p2-40);
  --order-history-billTo: var(--cx-p2-60);
  --order-history-billTo-border: var(--cx-p2-60);
  --side-nav-border-bottom: var(--cx-color-primary2);
  --side-nav-close-button: var(--cx-color-primary);
  --address-number: var(--cx-color-primary2);
  --cx-row-view-up-arrow: var(--cx-p2-60);
  --hover-button-color: var(--cx-p80);
  --search-dropdown-bg: var(--cx-color-primary2);
  --edit-payment-btn: var(--cx-color-primary);
  --cx-rapidorder-sku: var(--cx-alert);
  --cx-table-tracking: #c44812;
  --cx-rewards-bg-light: #fdebff;
  --cx-rewards-bg: #746caf;
  --cx-reward-success: #dcf5e8;
  --cx-reward-error: #f5dcdc;
  --rewards-border-color: #746caf;
  --rewards-button-color: #72bef2;
  --cart-credit-modal-header: var(--cx-color-primary);
  --red-warning-color: #bb0000;
  .mat-mdc-list-base {
    --mdc-list-list-item-label-text-line-height: 30px;
  }
}
