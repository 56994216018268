body {
  .search-container {
    input {
      padding-left: 10px;
    }
  }
  .mdc-button.mdc-button--unelevated {
    &.createBusinessButton, &.removeClientShipTo {
      height: 39px;
    }
  }

  .createBusinessButton {
    .mdc-button__label {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2px;
      grid-gap: 2px;
    }
  }

  .searchIcon {
    position: absolute;
    top: 7px;
    right: 10px;
  }
  .closeIcon{
    position: absolute;
    top: 6px;
    right: 40px;
    width: 20px;
    height: 20px;
  }

  .paginate {
    border-top: 4px solid var(--side-nav-border-bottom); 
    background: var(--cx-color-white);
    box-shadow: var(--common-box-shadow);
    padding: 5px;
    min-height: 40px;
    .total {
      top: 8px;
      left: 30px;
    }
  }
  .inputBox {
    margin: 0;
    padding: 0 35px 0px 10px;
    height: 38px;
    max-width: 250px;
    border: solid 1px var(--cx-color-black);
    &.createClientButton{
      max-width: none;
      width: 373px;
    }
  }

  .add-address-icon-create-business-button{
    font-size: 30px;
  }
  .form-control:focus,
  input:focus {
    outline-style: none;
  }

  @include media-breakpoint-down(sm) {
    .inputBox {
      max-width: 100%;
      width: 100%;
    }
  }
}
