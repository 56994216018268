.mix-match-promotion-container {
  z-index: 99999;
  position: fixed !important; // Default CSS precedence is higher
  max-width: 650px !important; // Default CSS precedence is higher
  min-width: 320px;
  box-shadow: var(--common-box-shadow);
  background: var(--cx-color-white);
  right: 0;
  top: 0;
  height: 100vh;
  .mat-mdc-dialog-container {
    padding: 0 !important;
    .mat-mdc-dialog-surface {
      padding: 0 !important;
    }
  }
  .mixMatchAlert{
    width: 440px;
  }
  .mix-match-promotion {
    overflow: auto;
    height: 90vh;
    &-header {
      position: absolute;
      top: 0;
      z-index: 1;
      height: 74px;
      background-color: var(--cx-warning-success);
      gap: 16px;
      .promotion-icon-container {
        background-color: var(--cx-color-white);
      }
      .content-section {
        .promo-text,
        .promo-desc {
          color: var(--cx-color-white);
        }
      }
      .close-btn {
        width: 44px;
      }
    }
    &-description {
      gap: 16px;
      background-color: var(--cx-global-inventory);
      @include media-breakpoint-up(sm) {
        .promotion-description {
          width: 62%;
        }
      }
    }
    &-stepper {
      .step {
        .step-number-container {
          outline: 2px dashed var(--cx-step-outline);
          box-shadow: 0 2px 8px var(--cx-dark-grey);
          .step-number {
            width: 46px;
            height: 46px;
            color: var(--cx-color-white);
            background-color: var(--cx-p40);
            &.active {
              background-color: var(--btn-primary-bg-color);
            }

            .mat-icon[svgicon="check_white"] {
              width: 23px;
              height: 26px;
            }
            .mat-icon[svgicon="edit_white"] {
              width: 18px;
              height: 28px;
            }
          }
        }
        .step-text {
          text-shadow: 0 4px 12px var(--cx-dark-grey);
        }
      }
      .separator {
        border-bottom: 1px solid var(--cx-color-black);
        box-shadow: 0 3px 5px var(--cx-dark-grey);
        width: 40px;
      }
    }
    &-quantity {
      .quantity {
        border: 2px solid var(--cx-global-inventory);
        width: 44px;
      }
      .text {
        background-color: var(--status-grey);
        border: 2px solid var(--status-grey);
        color: var(--cx-color-white);
        &.highlight {
          background-color: var(--cx-warning-success);
          border-color: var(--cx-warning-success);
        }
      }
    }
    &-product-description {
      gap: 16px;
      border: 1px dashed var(--cx-table-vertical-border);
      .product-body {
        .sku {
          width: fit-content;
          border: 1px solid var(--cx-table-vertical-border);
          .sku-key {
            color: var(--cx-alert);
          }
        }
      }
      .quantity {
        .remove-product {
          box-sizing: border-box;
          width: 46px;
          height: 45px;
          background: var(--cx-color-white);
          border: 1px solid var(--cx-color-primary);
          border-radius: 5px 0 0 5px;
        }
        .current-product-quanity {
          width: 66px;
          height: 45px;
          border: 1px solid var(--cx-color-primary);
        }
        .add-product {
          @extend .remove-product;
          border-radius: 0 5px 5px 0;
        }
        input[type=number]::-webkit-inner-spin-button, 
        input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0; 
      }
      input {
        text-align: center;
        }
      }
    }
    &-footer {
      position: absolute;
      bottom: 0;
      background-color: var(--cx-global-inventory);
      gap: 16px;
    }
  }
}
