.line-promotion {
  border-top: 3px solid var(--cx-warning-error);
  background: var(--cx-color-white);
  box-shadow: 0px 4px 4px var(--cx-box-shadow);
  gap: 1rem;
  margin-top: -20px;
  .promotion-icon {
    &.mat-icon[svgicon="promotion"] {
      min-width: 46px;
      width: 46px;
      height: 28px;
    }
  }
  .content-section {
    .promo-text {
      color: var(--cx-multiple-order);
    }
    @include media-breakpoint-down(sm) {
      width: 70%;
    }
  }
  .apply-remove-promo {
    min-width: 140px;
    max-width: 160px;
    padding: 10px;
    margin-right: 0 !important;
    &.promo-outlined {
      border: 2px solid var(--cx-color-primary);
      background-color: var(
        --cx-color-white
      ) !important; // Default CSS precedence higher
      color: $base-10 !important; // Default CSS precedence higherd
    }
  }
}
