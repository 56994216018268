.product-checkout-container{
    padding: 0 calc((100% - var(--xl-max-width)) / 2);
    .product-checkout-detail {
        width: 68%;
    }
    .checkout-purchase-order{
        background: var(--cx-color-white);
        box-shadow: var(--common-box-shadow);
        border-radius: 7px;
        .checkout-po {
            width: 30%;
        }
    }
    .checkout-summary-order-main{
        height: fit-content;
        position: sticky;
        top: 50px;
        width: 30%;
        .shipping-icon {
            height: auto;
        }
    }
    .checkout-summary-order{
        background: var(--cx-color-white);
        box-shadow: var(--common-box-shadow);
        border-radius: 7px;
    }
    .purchase-order{
        border-bottom: 1px solid var(--cx-global-inventory);
    }
    .edit-btn{
        border: 1px solid var(--cx-color-primary);
        border-radius: 7px;
    }
    .address-icon{
        width: 18px;
    }
    .payment-logo{
        width: 50px;
    }
    .deliveryOptions{
        border: 1px dashed var(--side-nav-border-bottom); 
        border-radius: 7px;
    }
    .deliveryOptionsActive{
        border: 2px solid var(--cx-warning-success);
    }
    .address-type{
        width: max-content;
    }
    .edit-mat-toggle-status{
          margin-top: 2px;
          @extend .t-label-l;
    }
    .purchase-order-top{
        border-top: 1px solid var(--cx-global-inventory);
    }
    .order-btn-after{
        opacity:100%;
        padding: 10px 24px;
        background: var(--navbar-bg);
    }
    .ques_mark,
    .add-sign{
        height: 20px;
        width: 20px;
    }
    
    .wrap-mat-radio-label{
        white-space: normal;
    }
    .new-icon {
        position: relative;
        bottom: 2px;
        background: var(--cx-color-green);
        padding: 1px 5px;
        width: 34px;
        height: 13px;
        color: var(--cx-color-white);
    }
    button.mat-white, a.mat-white {
        height: 28px;
        padding: 0 8px;
        border: 2px solid var(--hover-button-color);
        border-radius: 7px;
        mat-icon {
          width: 18px;
        }
      }
    .expiration-input {
        width: 100%;

        @include media-breakpoint-up(lg) {
            width: 40%;
        }
    }
    .payment-icon {
        width: 47px;
        height: 29px;
    }

    .text-decoration-underline {
        text-decoration: underline;
        color: var(--cx-color-black);
    }
    @include media-breakpoint-down(md) {
        .product-checkout {
          flex-direction: column !important; // !important is needed to override the property 
        }
        .product-checkout-detail{
            width: 100% !important; // !important is needed to override the property
        }
        .checkout-summary-order-main{
            width: 100% !important; // !important is needed to override the property
        }
        .new-icon{
            display: flex;
            width: max-content;
            margin-left: 0 !important;
            margin-top: 4px;
        }
    }
    .mat-mdc-radio-touch-target {
        top: 0%;
        left: 0%;
        height: 10px;
        width: 10px;
      }
      .freeFreight-success{
        background-color: var(--cx-warning-success);
        color: var(--cx-color-white);
        padding: 0px 5px 0px 5px;
        height: 18px;
        width: auto;
    }
}
.checkout-main-section{
    background: var(--cx-color-primary);
    color: var(--cx-color-white);
    .checkout-left-section{
        border-right: 3px solid var(--cx-color-white);
    }
    .boxout-logo{
        width: 147px;
        height: 50px;
    }
}
