.tooltip-container {
    position: absolute;
}
.custom-tooltip {
  position: absolute;
  z-index: 50;
  left: 30%;
  bottom: -180%;
  width: 272px;
  display: none;
  height: 139px;

  .credit__info-container {
    gap: 10px;
    padding: 12px 8px;
    border-radius: 5px;
    background-color: var(--cx-color-white);
    box-shadow: var(--common-box-shadow);
    .close-btn {
      button {
        padding: 10px 24px;
        background-color: var(--cx-dark-grey);
        color: var(--cx-color-white);
        border-radius: 4px;
        font-weight: 500;
      }
    }
  }
}

.show {
  display: block;
}
