body {
  .address-status-block {
    background: var(--cx-color-primary);
    height: 40px;
    color: var(--cx-color-white);
  }

  .address-error-text {
    text-align: center;
    color: var(--cx-warning-error);
  }

  .address-error-border {
    background: var(--cx-warning-error-light);
    border: 1px dashed var(--cx-warning-error);
  }

  .address-box-container{
    .address-box {
      border: 1px solid var(--cx-color-primary);
      text-align: center;
      width: 302px;
      overflow-wrap: break-word;
    }
  }
  

  .use-class-button {
    height: 40px;
  }

  @include media-breakpoint-down(md) {
    .business-account-dialogs-style {
      max-width: 100% !important;
      height: 100% !important;
      width: 100% !important;
    }
  }
}
