// Todo: other css are present in _sr-homepage.scss
.sr-nav.bxd-nav {
  background-color: var(--cx-color-white);
  box-shadow: none;
}
.bxd-nav {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  &-items {
    width: 100%;
    background-color: var(--cx-color-white);

    &.nav-link, &.nav-link:last-of-type {
      box-shadow: none;
      border-bottom: none;
    }

    a.sidenav-accordion {
      width: 100%;
      background-color: var(--cx-color-white);
      border: none;
      outline: none;
      text-align: left;
      padding: 10px;
      color: var(--cx-color-black);
      cursor: pointer;
      transition: background-color 0.2s linear;
    }

    a.sidenav-accordion:hover,
    a.sidenav-accordion.is-open {
      background-color:var(--cx-color-white);
    }

    .accordion-content {
      background-color: var(--cx-color-white);
      border-left: 1px solid var(--cx-color-white);
      border-right: 1px solid var(--cx-color-white);
      display: none;
      overflow: hidden;
      transition: max-height 0.2s ease-in-out;

      .nav-name {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        text-decoration: underline;
        background-color: var(--cx-color-white);
      }
    }
  }
  &-line {
    width: 100%;
    hr {
      border: 1px solid var(--cx-color-black);
      margin: 0;
    }
  }
  .side__nav-link.nav-link {
    height: 41px;
    gap: 5px;
    padding: 10px !important;
    background-color: var(--cx-color-white);
    margin-bottom: 0;
    mat-icon {
      height: 18px;
      width: 18px;
      overflow: inherit;
    }
    &:not(.accordion-item):hover {
      background-color: var( --bxd-sidenav-bg);
    }
    &:not(.accordion-item):last-of-type {
      border-bottom: none;
      margin-bottom: 0; // Necessary for footer logout button
    }

    .expand-icon {
      margin-left: auto;
      height: 20px;
      width: 20px;
      transform: rotate(180deg);
      transition: transform 0.2s ease-in-out;
    }
    &.is-open > .expand-icon {
      transform: rotate(0deg);
    }
    
  }

  .side__nav-link.accordion-item  {
    .nav-name {
      padding: 0px 6px;
    }
    outline: none;
    &:hover {
      background-color: var(--bxd-sidenav-bg) !important;
      .nav-name {
        background-color: var(--bxd-sidenav-bg) !important;
      }
    }
    &:last-of-type {
      border-bottom: none;
      margin-bottom: 0; // Necessary for footer logout button
    }
  }
  .sidenav-common:last-of-type {
    border-bottom: 1px solid var(--cx-table-vertical-border);
    margin-bottom: 0; // Necessary for footer logout button
  }
  .sr-logout-button {
    gap: 14px;
    span {
      color: var(--cx-color-white);
    }
  }

  
  &.nav-link, &.nav-link:last-of-type {
    box-shadow: none !important;
    border-bottom: none !important;
  }
}

.sidenav-accordion.active {
  background-color: var(--bxd-sidenav-bg) !important;
  font-weight: 700;
}

.accordion-item.active {
  background-color: var(--cx-color-white) !important;
  font-weight: 400 !important;
}

.sidenav-highlight {
  background-color: var(--bxd-sidenav-bg) !important;
  font-weight: 700 !important;
}

.sr-home-content {
  .mat-drawer-backdrop.mat-drawer-shown {
    visibility: hidden !important;
  }
}

.BoxoutPortalHomeLeftNavBar {
  app-portal-bxd-drawer {
    mat-drawer-container {
      display: flex;
      .mat-drawer:not(.mat-drawer-side) {
        box-shadow: none;
      }
      mat-drawer {
        // position: relative;
        box-shadow: var(--common-box-shadow);
        height: inherit;
        max-height: 950px;
      }
      mat-drawer-content {
        width: 100%;

        @include media-breakpoint-down(md) {
          margin-left: 0;
        }
      }

      .mat-drawer-content-mobile {
        margin-left: 57px;
      }
      .mat-drawer-content-desktop {
        margin-left: 157px;
      }
    }
  }
}

.sr-home-layout-sidenav .sr-nav .nav-link.sidenav-link:last-of-type {
  box-shadow: none;
  border-bottom: none;
  margin-bottom: 0;
}

.sr-home-layout-sidenav .sr-nav .nav-link-section:last-of-type {
  border-bottom: 1px solid var(--cx-table-vertical-border);
  box-shadow: var(--common-box-shadow);
}

.mat-drawer-inner-container::-webkit-scrollbar {
  display: none;
}

.sr-nav.bxd-nav {
  .nav-link.accordion-item {
    .accordionActive {
        background-color: var(--cx-p2-20);
        font-weight: 700;
    }
    &:hover {
      background-color: var(--sr-nav) !important;
    }
  }
}