body {
    .grecaptcha-badge{
        visibility: hidden;
    }
    //Styles for Dialog box scroll
    .custom-modal {
        pointer-events: auto;  
        overflow: auto;
    }
  
    .mat-mdc-text-field-wrapper {
        @extend .pb-3;
    }

    .dialogCloseIcon{
        background-color: var(--create-bus-btn);
        color: var(--cx-color-white);
        border-radius: 50%;
    
    }
    //Styles for login Dialog
    
    .img-logo {
        width: 215px;
        height: auto;
    }
   
    .login-dialog{  
        &-emailValidate{
            font-size: 13px;
            margin-top: -24px;
            padding-left: 24%;
            position: relative;
            color: var(--cx-warning-error);
        }
        &-main-heading{
            overflow-wrap: break-word;
            max-width: 250px;
        }
        // Anchor tag color
        a{
            color: var(--cx-login-a);
        }
        hr{
            border-color: var(--cx-lt-primary-container);
        }
        &-input-outline{
            &:focus{
                outline: none;
            }
        }
        &-image{
            width: 206px;
        }
        &-field-width{
            width: 59%;
        }
        
        &-mat-form-field-appearance-outline .mat-form-field-wrapper{
            @extend .pb-0;
        }
        &-sign-In-Button{
            width: 70%;
        }
        &-custom-margin{
            margin-left: 3.5rem;
            margin-right: 3.5rem;
        }
        &-send-Link{
            // color: var(--cx-color-primary);
            &:focus{
                outline: none;
            }
        }
        &-recaptcha-text{
            span{
                color: var(--cx-login-a);
            }
        }    
    }
    //Styles for swtich dialog
    .switch-account-dialog{
        // Switch account
        &-myTopClass.mat-mdc-select-panel {
            //margin-top: 33px;
            //margin-left: -17px;
            width: 100%;
            max-height: 200px;
            //max-width: 324px;
        }
        &-tab-padding{
            width: 600px;
        }
       
        .mat-mdc-form-field-flex{
            width: 150%;
        }
        
        .mat-select-value{
            padding-left: 20px;
        }
        &-option-custom-left-padding{
            padding-left: 52px !important;
        }
        &-continue-Button{
            @extend .login-dialog-sign-In-Button;
        }
        
        mat-select[aria-expanded=true] .mat-select-arrow-wrapper {
            transform: rotateX(180deg);
        }
       
        .mat-form-field-appearance-outline .mat-form-field-prefix{
            top: 0;
        }
        
        .mat-form-field-appearance-outline .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
            .mat-form-field-outline .mat-form-field-outline-start {
                width: 36px !important;
            }
        }

        .mat-mdc-option {
            padding: 5px;
        }
    }
    //Styles for password dialog
    .password-dialog{  
        // Anchor tag color
        a{
            color: var(--cx-login-a);
        }   
        &-passwordValidate{
            font-size: 13px;
            margin-top: -19px;
            padding-left: 21%;
            position: relative;
            color: var(--cx-warning-error);
        }
        &-image{
            @extend .login-dialog-image;
        }                                                      
        &-field-width{
            width: 65%;
        }
        &-input-outline{
            @extend .login-dialog-input-outline;
        }
        &-password-Button{
            @extend .login-dialog-sign-In-Button;
        }
        &-send-Link{
            @extend .login-dialog-send-Link;
        }
        h4:before,
        h4:after {
            content: "";
            flex: 1 1;
            border-bottom: 1px solid var(--cx-lt-primary-container);
            margin: auto;
        }
        h4:before{
            margin-right: 10px;
        }
        h4:after{
            margin-left: 10px;
        }
        &-custom-margin{
            @extend .login-dialog-custom-margin;
        }
        &-custom-padding{
            padding: 0 15%;
            span{
                color: var(--cx-dark-grey);
            }
        }
        &-recaptcha-text{
            @extend .login-dialog-recaptcha-text;
        }  
    }
    //Styles for forgot password dialog
    .forgot-password-dialog{
        a{
            color: var(--cx-login-a);
            text-decoration: underline;
        } 
        &-field-width{
            width: 51%;
            mat-error {
                text-wrap: nowrap;
            }
        }
        h4:before,
        h4:after {
            content: "";
            flex: 1 1;
            border-bottom: 1px solid var(--cx-lt-primary-container);
            margin: auto;
        }
        h4:before{
            margin-right: 10px;
        }
        h4:after{
            margin-left: 10px;
        }
        hr{
            border-color: var(--cx-lt-primary-container);
        }
        &-emailValidate{
            @extend .login-dialog-emailValidate;
            padding-left: 26%;
        }
        &-input-outline{
            @extend .login-dialog-input-outline;
        }
        &-image{
            @extend .login-dialog-image;
        } 
        &-forgotPassword-Button{
            width: 61%;
        }
        &-custom-padding{
            padding: 0 20%;
            span{
                color: var(--cx-dark-grey);
            }
        }
        &-custom-margin{
            margin-right: 5.35rem;
            margin-left: 5.35rem;
        }
        ul{
            list-style-position: inside;
        }
        &-send-Link{
            @extend .login-dialog-send-Link;
        }
        &-recaptcha-text{
            @extend .login-dialog-recaptcha-text;
        }
        &-text-decoration-underline{
            text-decoration: underline;
            color: var(--cx-login-a);
        }

        & .bxd-style{
            color: var(--cx-dark-grey);
            font-weight: 700;
        }
        & .bxd-style-underline{
            color: var(--cx-dark-grey);
            text-decoration: underline;
        }
    }
    @include media-breakpoint-down(md) {
        .custom-dialog-size{
            max-width: 100% !important;
            height: 100% !important;
            width: 100% !important;
        }
        .login-dialog{
            // For full width of Tabs and Ipad
            &-field-width {
                width: 34%;
            }
            &-sign-In-Button {
                width: 40%;
            }
            &-emailValidate{
                padding-left: 34%;
            }
            //================================          
            &-custom-margin {
                margin-left: 29%;
                margin-right: 29%;
            }
        }
        .password-dialog{
            &-field-width {
                width: 34%;
            }
            &-passwordValidate{
                padding-left: 33%;
                color: var(--cx-warning-error);
            }
            // &-custom-margin {
            //     margin-left: 7.5rem;
            //     margin-right: 7.5rem;
            // }
            &-custom-padding {
                padding: 0 30%;
            }
        }
        .forgot-password-dialog{
            &-custom-margin {
                margin-left: 29%;
                margin-right: 29%;
            }
            &-field-width {
                width: 34%;
            }
            &-forgotPassword-Button {
                width: 40%;
            }
            &-custom-padding {
                padding: 0 30%;
            }
        }
        .switch-account-dialog {
            &-continue-Button{
                width: 40%;
            }
            &-field-alignment{
                text-align: center;
                padding-right: 7rem;
            }
        }
    }
    
    @include media-breakpoint-down(sm) {
        .login-dialog{
            &-mobile-width{
                width: 47% !important;
            }
            &-mobile-icon-padding{
                padding-left: 8.5rem!important;
            }
            // For full width of Tabs and Ipad
            &-field-width {
                width: 59%;
            }
            &-sign-In-Button {
                width: 70%;
            }
            &-emailValidate{
                padding-left: 24%;
            }
            //================================  
            &-custom-margin {
                margin-left: 3.5rem;
                margin-right: 3.5rem;
            }
        }
        .password-dialog{
            &-mobile-width{
                width: 47% !important;
            }
            &-mobile-icon-padding{
                padding-left: 8.5rem!important;
            }
            &-custom-margin {
                margin-left: 3.5rem;
                margin-right: 3.5rem;
            }
            &-field-width {
                width: 59%;
            }
            &-passwordValidate{
                padding-left: 21%;
            }
            &-custom-padding {
                padding: 0 15%;
            }
        }
        .forgot-password-dialog{
            &-mobile-width{
                width: 47% !important;
            }
            &-mobile-icon-padding{
                padding-left: 8.5rem!important;
            }
            &-field-width {
                width: 59%;
            }
            &-forgotPassword-Button {
                width: 70%;
            }
            &-custom-padding {
                padding: 0px 15%;
            }
            &-custom-margin{
                margin-right: 3rem;
                margin-left: 3rem;
            }
            &-custom-font-size{
                font-size: 80%!important;
            }
        }
        .switch-account-dialog {
            .mat-mdc-form-field-flex{
                width: 120%;
            }
            
            .mat-select-value {
                padding-left: 22px;
            }
            &-continue-Button{
                width: 70%;
            }
            &-field-alignment{
                padding-right: 0;
            }
        }
    }
    @include media-breakpoint-down(xs) {
        .switch-account-dialog {
            .mat-mdc-form-field-flex {
                width: 98%;
            }
            &-option-custom-left-padding {
                padding-left: 30px !important;
            }
        }
    }

    // specific for galaxy tab S8 
    @media screen and (min-width: 750px) and (max-width: 754px) {
        .login-dialog{
            // For full width of Tabs and Ipad
            &-field-width {
                width: 34%;
            }
            &-sign-In-Button {
                width: 40%;
            }
            //================================ 
            &-custom-margin {
                margin-right: 13rem;
                margin-left: 13rem;
            }
        }
        .password-dialog{
            // &-custom-margin {
            //     margin-right: 6.5rem;
            //     margin-left: 6.5rem;
            // }
            &-field-width {
                width: 34%;
            }
            &-passwordValidate{
                padding-left: 33%;
            }
            &-custom-padding
            {
                padding: 0 30%;
            }
        }
        .forgot-password-dialog{
            &-field-width {
                width: 34%;
            }
            &-custom-margin {
                margin-right: 13rem;
                margin-left: 13rem;
            }
            &-custom-padding {
                padding: 0 30%;
            }
            &-forgotPassword-Button {
                width: 40%;
            }
        }
        .switch-account-dialog{
            &-myTopClass.mat-mdc-select-panel {
                max-width: 256px;
            }
            &-tab-padding{
                padding-left: 2rem;
            }
            &-field-alignment{
                text-align: center;
                padding-right: 5rem;
            }
            &-continue-Button {
                width: 42%;
            }
        }
    }
    @media (max-width: 500px) {
        .switch-account-dialog {
            &-tab-padding{
                width: 263px;
            }
           
            .mat-mdc-form-field-flex {
                width: 100%;
            }
            &-option-custom-left-padding {
                padding: 5px 5px 5px 30px !important;
                //padding-left: 30px !important;
            }
        }
     }
    // Adding css for reusable switch account dialog box
    .reusable-switch-dialog , .switch-account-dialog {
        .mat-form-field-appearance-outline {
            .mat-mdc-form-field-icon-prefix {
                top: 0.25rem;
            }

            .mat-mdc-form-field-icon-prefix, .mat-mdc-form-field-icon-suffix {
                .mat-icon {
                    padding: 6px 12px 12px 6px;
                }
            }

            // .mdc-text-field--outlined.mat-mdc-text-field-wrapper {
            //     .mat-mdc-form-field-icon-prefix {
            //         mat-icon {
            //             padding: 6px;
            //         }
            //     }
            // }
        }
    }

    .separate-line {
        border-top: 1px solid var(--cx-p2-40);
        color: var(--login-page-text);
    }
}