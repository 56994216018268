.bxd-reports {
  .inventory-transaction-table {
    outline: none;
  }
  
  table {
    // width: 1140px;
    tr {
      th:first-of-type {
        width: 100px;
      }
      th:nth-of-type(2) {
        width: 70px;
      }
      th:nth-of-type(3) {
        width: 90px;
      }
      th:nth-of-type(4) {
        width: 300px;
      }
      th:nth-of-type(5) {
        width: 139px;
      }
    }

    td:first-of-type {
      color: var(--hyperlink-blue);
      text-decoration: underline;
    }
  }
}
