.add-edit-container {
  z-index: 99999;
  position: fixed;
  width: 368px;
  box-shadow: var(--common-box-shadow);
  background: var(--cx-color-white);
  right: 0;
  top: 0;
  height: 100vh;

  .add-edit  {
      padding: 10px;
      height: 44px;
      border-bottom: 1px solid var(--cx-color-black);
      .cancel-img{
        width: 25px;
      }
      .mat-mdc-unelevated-button.mat-primary {
        background-color: var(--side-nav-border-bottom);
        padding: 5px;
        &.sitelinks-close {
            min-width: 25px;
            padding: 0;
            span {
                color: var(--cx-color-white);
            }
            .mat-icon {
              font-size: 1.525rem;
            }
        }
        &.cdk-program-focused , &.cdk-focused {
            color: var(--cx-color-white);
            background-color: var(--cx-color-primary2);
        }
    }
  }

  .edit-list-name-input {
    mat-mdc-form-field {
      display: block;
    }
  }
  
  .advance-list-style {
    .delete-fav-list{
       cursor: pointer;
    }
    background: var(--cx-p2-10);
    height: 226px;

    .mat-mdc-checkbox {
      label {
        @extend .t-productTile-description;
      }
    }
  }

}

.mdc-dialog__container {
  .sitelinks-close.mdc-button.mdc-button--unelevated {
    .mat-icon {
      font-size: 1.525rem;
    }
  }
}
