.detailsBlock {
  max-width: 50%;
  @include media-breakpoint-down(sm) {
    max-width: 90%;
  }
  border: 1px dashed var(--side-nav-border-bottom);
  .emailData{
    word-wrap: break-word;
  }
}
