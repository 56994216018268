.wro-details {
  display: grid;
  gap: 20px;

  .pt-10px{
    padding-top: 10px;
  }

  .section{
    background-color: var(--cx-color-white);
    box-shadow: var(--common-box-shadow);
    padding: 12px;
  }

  .section1 {
    .top {
      gap: 1px;

      .customerRefNo {
        color: var(--cx-wro-sub-heading);
      }
    }

    .bottom{
      .track{
        height: 12px;
        border-radius: 6px;

        .ball{
          height: 8px;
          width: 8px;
          border-radius: 4px;
          margin-right: 2px;
          background-color: var(--cx-color-white);
        }
      }

      .progressTrack1{
        background-color: var(--cx-progress-track);
      }

      .progressTrack2{
        background-color: var(--cx-progress-track2);
      }

      .cancelled{
        margin-left: 6%;
      }
    }
  }

  .section2{
    gap: 12px;
    display: grid;

    .width-fit-content{
      width: fit-content;
    }
  }

  .section3{
    gap: 6px;
    display: grid;

    .address_card{
      flex: 1;

      .data{
        padding: 10px;
        background-color: var(--cx-drop-ship);
        flex: 1;

        .address{
          width: 80%;
        }
      }
    }
  }

  .section4{
    overflow-x: auto;
    
    .table-container{
      table{
        box-shadow: none;
      }

      table, th, td {
        border-bottom: 1px solid var(--cx-table-border);
      }

      .id_link{
        color: var(--status-blue-2);
      }
    }
  }

  .section5{
    gap: 12px;
    display: grid;

    .add_btn{
      height: 30px;
    }

    .tracking-hyperlink{
      color: var(--cx-color-black);
    }

    table{
      width: 100%;
      tr {
        td:first-of-type {
          width: 30px;
          min-width: 30px;
        }
      }
      td:nth-of-type(2) {
        width: 45%;
        min-width: 35%;
      }
      td:nth-of-type(3) {
        width: 20%;
        min-width: 10%;
      }
    }
  }

  .section6{
    gap: 12px;
    display: grid;

    .message{
      gap: 4px;

      .browse_btn{
        border-color: var(--cx-color-primary);
        color: var(--cx-color-primary);
      }

      .errorMessage{
        color: var(--cx-warning-error);
      }
    }

    .fileTable{
      border: 1px solid var(--cx-hr-border);
      color: var(--cx-wro-table-text);

      tr:nth-child(even) {
        background: var(--cx-table-row);
      }

      th, td{
        height: 44px;
        border-top: 1px solid var(--cx-hr-border);
        border-bottom: 1px solid var(--cx-hr-border);
      }
    }

    table{
      width: fit-content;
      tr {
        td:first-of-type {
          min-width: 250px;
        }
        td:nth-of-type(2) {
          min-width: 100px;
        }
        td:nth-of-type(3) {
          min-width: 40px;
        }
      }
    }
  }

  .section7{
    gap: 12px;
    display: grid;

    .add_btn{
      height: 30px;
    }

    table{
      width: 100%;
      tr {
        td:first-of-type {
          width: 30px;
          min-width: 30px;
        }
        td:nth-of-type(2) {
          width: 45%;
          min-width: 35%;
        }
        td:nth-of-type(3) {
          width: 20%;
          min-width: 10%;
        }
      }
    }
  }

  .popup{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,50%);
    z-index: 2;
    
    .popup_card{
      min-width: 670px;
      gap: 30px;
      display: grid;

      .mat-form{
        margin-bottom: -24px;
      }

      .note_form{
        min-height: 149px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .section3{
      flex-direction: column;
    }

    .hide-mobile{
      display: none;
    }
  }

  @include media-breakpoint-up(md) {
    .hide-laptop{
      display: none;
    }
  }
}