#{globalParent()} {
    background-color: $base-background-color;
    color: $base-font-color;
    font-family: $base-font-family;

    strong,
    b {
        font-weight: $font-weight-bold;
    }
}
