.theme-check {
  color: var(--cx-color-primary);
  font-size: $h1-font-size;
}

.m-button-primary {
  background: --btn-primary-bg-color;
}

.quick-order-container {
  z-index: 99999;
  position: absolute;
  width: 285px;
  box-shadow: 4px 4px 4px rgba(0 0 0 / 25%);
  background: var(--cx-color-white);
  &.quick-order-modal {
    margin-left: -7%;
  }
  .orderData{
    color: var(--cx-color-darkred);
  }

  .header-searchbar-modal-container {
    display: flex;

    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }

    mat-error {
      word-wrap: break-word;
    }

    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-input-control {
        height: inherit;
      }
    }
  }

  .fixed {
    width: 200px;

    .mat-mdc-form-field {
      width: 177px;
      .mat-mdc-form-field-infix {
        height: 48px;
        padding: 6px;
      }
     
      .mat-mdc-text-field-wrapper {
        padding-bottom: 0 !important;
      }
    }
  }

  .flex-item {
    flex-grow: 1;

    .mat-mdc-form-field {
      width: 56px;
      .mat-mdc-form-field-infix {
        height: 48px;
        padding: 6px;
      }

      .mat-mdc-text-field-wrapper {
        padding-bottom: 0 !important;
      }
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}
