.theme-spasite {
  .mat-mdc-unelevated-button , .mat-mdc-raised-button {
    &.mat-primary {
      background-color: var(--navbar-bg);
      color: var(--cx-color-white);
      &:disabled {
        color: var(--btn-disabled-txt-clr) !important;
      }
      path {
        stroke: var(--cx-color-white);
      }
    }
}
}
