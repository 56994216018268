body{
  .cart-ship-to-style{
    .mat-mdc-dialog-container {
      width: 581px;
      border-radius: 10px;
      overflow: hidden;
      padding: 16px;
    }
    .client-patient-business-shipTo-icon{
      width: 80px;
      height: 80px;
    }
  }
}