.sr-login {
  height: 100%;
  background-color: var(--cx-color-white);
  &-left-section {
    max-width: 1300px; // All the min & max width are necessary for design
    @include media-breakpoint-down(lg) {
      max-width: 500px;
      app-custom-banner {
        img {
          height: 500px;
          object-fit: cover;
        }
      }
    }
  }
  &-right-section {
    max-width: 700px;
    min-width: 550px;
    @include media-breakpoint-down(lg) {
      max-width: 500px;
      min-width: 450px;
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
      min-width: 320px;
    }
    .logo-section {
      .SalesRepSiteLogoComponentPosition,
      .SiteLogo {
        cx-banner {
          // No specific class present on element (necessary)
          img {
            // No specific class present on element (necessary)
            max-width: 311px;
            min-width: 100px;
            max-height: 200px;
            min-height: 70px;
          }
        }
      }
    }
    .sr-login-form-section {
      .header-section {
        .heading {
          color: var(--login-page-heading);
        }
        .sub-heading {
          color: var(--login-page-text);
        }
      }
      .get-started-section-top-half {
        border-top: 1px solid var(--cx-p2-40);
      }
      .get-started-section {
        border-top: 1px solid var(--cx-p2-40);
        border-bottom: 1px solid var(--cx-p2-40);
        color: var(--login-page-text);
        a.get-started-link {
          color: var(--login-get-started-text);
          text-decoration: underline;
        }
      }
      a.get-contact{
        color: var(--login-page-text);
      }
    }
    .copyright-text {
      color: var(--login-page-heading);
    }
    .footer-links {
      color: var(--cx-color-primary);
      .SalesRepFooterComponentPosition,.FooterLinksSlot {
        :not(:first-child) {
          padding-left: 12px;
          padding-right: 12px;
        }
        :not(:last-child) {
          padding-left: 12px;
          padding-right: 12px;
          border-right: 2px solid var(--cx-color-primary);
        }
        :first-child {
          padding-left: 0;
        }
        :last-child {
          padding-right: 0;
        }
      }
    }
  }
}

.smartedit-page-uid-salesRepLogin , .smartedit-page-uid-sales-rep {
  app-header , app-footer {
    display: none;
  }

  app-sr-homepage {
    display: block !important;
  }
}
