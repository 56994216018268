.manage-frequently-purchased-product-list-container {
  background: var(--cx-color-white);
  box-shadow: var(--common-box-shadow);

  .manage-check{
    margin-left: -8px;
  }

  .manage-favorite-action {
    .ena {
      cursor: pointer;
      color: var(--cx-color-primary);
    }
    .dis {
      pointer-events: none;
      cursor: default;
      @extend .link-color;
    }
  }

  .sort-field {
    .mat-form-field-appearance-outline {
      .mat-mdc-text-field-wrapper {
        max-width: 120px;
        height: 52px;
      }
    }
  }

  .manage-fav-list-notes {
    text-align: center;
  }

  .selectProduct {
    .mat-mdc-select-value {
      display: none;
    }
  }

}

body .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--cx-color-black);
}