.cart-credit-dialogue {
  max-width: 95vw !important;
  max-height: 95vh;

  .mat-mdc-dialog-surface.mdc-dialog__surface {
    padding: 16px;
    border-radius: 10px;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      margin: 12px 0 12px 0;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--cx-table-scroll);
      border-radius: 10px;
    }

    @include media-breakpoint-down(sm) {
      &::-webkit-scrollbar {
        width: 3px;
      }
    }
  }
}
.cart-info {
  border-radius: 10px;
  gap: 16px;

  .modal-heading {
    color: var(--cx-color-black);

    p {
      text-align: center;
    }
  }

  .modal__container {
    gap: 9px;

    .modal__sub-heading {
      background-color: var(--cx-p2-10);
      padding: 5px;
    }

    .modal__credit-table {
      .tableModal {
        border-collapse: separate;
        border-spacing: 0 5px;
        padding-left: 21px;
        outline: none;

        .table-hr {
          border: 1px solid var(--cx-color-black);
        }

        &-head {
          margin-left: auto;
          th {
            width: 108px;
            color: var(--cx-color-white);
            padding: 5px;
          }
  
          th:nth-child(1) {
            background-color: var(--cart-credit-modal-header);
          }
  
          th:nth-child(2) {
            background-color: var(--status-blue);
          }
  
          th:nth-child(3) {
            background-color: var(--cx-color-organic-green);
          }
  
          th:nth-child(4) {
            background-color: var(--cart-credit-modal-header);
          }
        }
        
        &-body {
          margin-left: auto;
          max-height: 140px;
          overflow-y: auto;
          display: block;
          tr {
            // margin-top: 5px;
            td {
              width: 108px;
              margin-top: 5px;
              overflow-wrap: break-word;  
              word-wrap: break-word; 
              word-break: break-word;
            }
          }
  
          &::-webkit-scrollbar {
            width: 4px;
            box-shadow: inset 0 0 4px var(--cx-p10);  
          }
          
          &::-webkit-scrollbar-track {
            background: var(--cx-p10);
          }
          
          &::-webkit-scrollbar-thumb {
            background: var(--cx-table-scroll);
            border-radius: 10px;
          }
        }
  
        &-foot {
          margin-left: auto;
          tr {
            td {
              text-align: right;
              padding-left: 8px;
              padding-top: 8px;
              padding-bottom: 8px;
              width: 108px;
              border-top: 1px solid var(--cx-color-black);
              overflow-wrap: break-word;  
              word-wrap: break-word; 
              word-break: break-word;
            }
          }
        }

        @include media-breakpoint-down(sm) {
          padding-left: 0;

          &-head th:nth-child(2) {
            display: none;
          }

          &-body td:nth-child(2) {
            display: none;
          }

          &-foot td:nth-child(2) {
            display: none;
          }
        }
      }
    }

    .modal__hint-section {
      padding: 5px;
      background-color: var(--cx-me-light-purple);
      p {
        word-wrap: break-word;
        margin-left: auto;
      }

      .hint-section {
        width: 430px;
        height: auto;
        
        @include media-breakpoint-down(sm) {
          max-width: 306px;
          width: 100%;
        }
      }

      @include media-breakpoint-down(sm) {
        height: auto;
      }
    }

    .modal-learnMore {
      span {
        width: 100%;

        .learnMore-link {
          text-decoration: none;
          outline: none;
          color: var(--cx-color-darkOrange);
        }
      }
    }
  }

  .modal-closeBtn {
    padding: 16px 0;
    background-color: var(--cx-global-inventory);

    button {
      padding: 10px 24px;
      border-radius: 5px;
      border: 2px solid var(--cx-color-primary);
      background-color: var(--cx-color-white);
      color: var(--cx-color-black);
    }
  }
}
