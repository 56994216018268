body {
  .select-client-type-dialogs-style {
    &.container{
      padding: 16px !important;
    }
    .mat-mdc-dialog-container {
      width: 794px;
      .mat-mdc-dialog-surface {
        padding: 24px 0;
        border-radius: 0;
      }

      @include media-breakpoint-down(md) {
        max-width: 100% !important;
        height: 100% !important;
        width: 100% !important;
      }
    }

    .select-client-type-submit-button {
      & button {
        background-color: var(--create-bus-btn);
        color: var(--cx-color-white);
      }
    }

    .select-client-type-input-display {
      .mat-mdc-form-field-type-mat-select {
        .mat-mdc-form-field-infix {
          width: 214px;
        }
      }
    }
  }
}