.sr-change-password-dialog {
  max-width: 95vw !important; // Necessary for design

  .mat-mdc-dialog-container {
    padding: 0;
  }

  .sr-change-password {
    min-width: 320px;
    @include media-breakpoint-up(sm) {
      width: 476px;
    }

    .model-close {
      top: 0;
      right: 0;
    }

    &-heading {
      color: var(--cx-dark-grey);
    }
    &-form {
      .mat-form-field-subscript-wrapper {
        position: relative;
      }
      .create-password-button {
        height: 44px;
      }
      .password-validation-list {
        padding-left: 0;
        li {
          margin-left: 16px;
        }
      }
      .active-validation-message {
        color: var(--cx-warning-success);
      }
    }
    .security-service-provider-text {
      max-width: 250px;
      color: var(--cx-color-primary);
    }

    &-rule {
      border-top: 1px solid var(--cx-hr-border);
    }
  }
}
