body {

.order-history {

  &-recent {
    background: var(--cx-color-white);

    input:focus {
      outline: none !important;
      border-color: none;
      box-shadow: none;
    }

    input::placeholder {
      font-size: 16px;
    }

    .flex-item-left {
      flex: 10%;
    }

    .search-icon-wrapper {
      margin-top: 6px;
      position: absolute;
      right: 20px;

      mat-icon.search-icon {
        width: 20px;
        height: 20px;
      }
    }

    .cancel-icon-wrapper {
      margin-top: 6px;
      position: absolute;
      right: 50px;

      mat-icon.cancel-icon {
        width: 20px;
        height: 20px;
      }
    }

    /* Responsive layout - makes a one column-layout instead of a two-column layout */
    @media (max-width: 768px) {

      .flex-item-right,
      .flex-item-left {
        flex: 100%;
      }

      .box {
        box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
      }

    }

    .order-list {
      width: 100%;
    }

    .mat-mdc-raised-button {
      bottom: 5px;
    }

    .view-order-btn {
      @include media-breakpoint-down(sm) {
        width: 100px;
      }
    }

    .view-order-btn:hover {
      background-color: var(--hover-button-color);
    }

    .example-full-width {
      width: 273px;
    }
    
    .mat-form-field-appearance-outline .mat-mdc-form-field-infix {
      padding-top: 6px;
      padding-bottom: 6px;
    }
  }

  .custom-dd {
    @extend .material-override;
  }

  &-filter-section {
    box-shadow: var(--common-box-shadow);
    background-color: var(--cx-color-white);

    &-search {
      height: 40px;
    }
  }

  &-search-form {

    &-daterange-input {
      width: 140px;
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
    &-startdate-input,
    &-enddate-input {
      width: 140px;
      .mat-mdc-text-field-wrapper.mdc-text-field {
        padding: 0 2px 0 12px;
        .mat-mdc-form-field-error {
          line-height: normal;
        }
      }
      @include media-breakpoint-down(md) {
        width: 50%;
      }
    }

    .mat-mdc-text-field-wrapper {
      width: 95%;
      .mat-mdc-form-field-infix {
        height: 40px;
        padding-top: 10px;
        padding-bottom: 10px;
        min-height: auto;
      }
      .mat-mdc-form-field-flex .mat-mdc-floating-label {
        top: 21px;
        &.mdc-floating-label--float-above {
          top: 28px;
        }
      }
    }

    .mat-mdc-form-field-type-mat-select {
      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }
    }

    .mat-datepicker-toggle {
      .mat-mdc-icon-button.mat-mdc-button-base {
        width: 38px;
        height: 38px;
        padding: 7px;
      }
    }

    .order-number {
      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }

    .account , .order-status , .account-name {
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }

  &-detail {
    box-shadow: var(--common-box-shadow);
    background-color: var(--cx-color-white);

    &-status {
      background-color: var(--cx-p10);
      border: 1px solid var(--cx-p20);
      border-radius: 7px 7px 0 0;
    }

    &-bill-to {
      background-color: var(--order-history-billTo);
      border-right: 1px solid var(--order-history-billTo-border);
      border-left: 1px solid var(--order-history-billTo-border);
    }

    &-ship-to {
      background-color: var(--cx-p2-20);
    }

    &-address {

      &-section {
        border: 1px solid var(--cx-p20);
        border-top: 0;
      }

      &-details {
        width: 200px;
      }
    }

    &-see-details {
      height: 34px;
      .mdc-button__label {
        line-height: 1rem;
      }
    }
  }

  // custom scss ends //

  .other-statuses {
    margin: 0px;
    color: var(--cx-color-white);
    background-color: var(--status-blue);
  }

  .processing,
  .approved,
  .open,
  .order.recieved,
  .created,
  .unapproved {
    margin: 0px;
    color: var(--cx-color-white);
    background-color: var(--status-blue);
  }

  .shipped,
  .completed,
  .shipping {
    color: var(--cx-color-white);
    background-color: var(--cx-color-organic-green);
    margin: 0px;
  }

  .partially.shipped,
  .backordered,
  .waiting.return {
    background-color: var(--status-orange);
    color: var(--cx-color-white);
    margin: 0px;
  }

  .suspended {
    background-color: var(--status-grey);
    color: var(--cx-color-white);
    margin: 0px;
  }

  .cancelled,
  .rejected,
  .modified.cancelled,
  .on.hold,
  .pending.approval {
    background-color: var(--cx-warning-error);
    margin: 0px;
    color: var(--cx-color-white);
  }
  
}

.order-history-detail{
.returnStat{
  background-color: var(--cx-color-secondary);
  color: var(--cx-color-white);
}
}
}
