.free-freight-modal {
  width: 336px;
  margin: auto;
  
  .continue-btn {
    width: 105px;
    height: 44px;
    padding: 10px 24px;
    border-radius: 5px;
  }
  .cancel-btn {
    width: 92px;
    height: 44px;
    padding: 10px 24px;
    border-radius: 5px;
    border: 1px solid var(--cx-color-primary);
  }
  /* Mobile styles */
  @include media-breakpoint-down(md) {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .continue-btn,
    .cancel-btn {
      width: auto;
      padding: 12px;
      margin-bottom: 10px;
    }
  }
}
