.rewards {
  &-details-page {
    .rewards-credits-header {
      .lifetime-earned {
        padding-top: 2px;
      }
    }

    & .paginate {
      padding: 16px;
      border-radius: 0 0 7px 7px;

      .total {
        top: 35%;
        left: 16px;
      }

      @include media-breakpoint-down(sm) {
        margin-top: 10px;
      }
    }

    & > .paginate:last-of-type {
      border: none;
    }

    @include media-breakpoint-down(sm) {
      margin: 1rem;
    }
  }
  &-record-list-container {
    gap: 15px;
  }
  // for rewards record list section
  &-record {
    background-color: var(--cx-color-white);
    gap: 28px;
    padding: 10px 16px;

    &-left {
      flex-basis: 60%;
      gap: 10px;

      .icon-section {
        &-icon {
          mat-icon {
            width: 42px;
            height: 42px;
            background-color: var(--cx-reward-success);
            border-radius: 50%;
            svg {
              height: 14px;
              width: 14px;
            }

            @include media-breakpoint-down(sm) {
              width: 24px;
              height: 24px;

              svg {
                height: 10px;
                width: 10px;
              }
            }
          }

          mat-icon.error-icon {
            background-color: var(--cx-reward-error);
            svg {
              height: 27px;
              width: 27px;

              @include media-breakpoint-down(sm) {
                height: 14px;
                width: 14px;
              }
            }
          }
        }
      }

      .record-text {
        &-title {
          gap: 7px;
          span:nth-child(1),
          span:nth-child(2) {
            font-weight: 700;
          }

          span.expired {
            font-weight: normal;
          }

          @include media-breakpoint-down(sm) {
            font-size: 14px;
            line-height: 17.75px;
          }
        }

        &-subtitle {
          span {
            @include media-breakpoint-down(sm) {
              font-size: 23px;
              line-height: 28.87px;
            }
          }
        }

        &-additionalText {
          gap: 16px;
          position: relative;

          span.showTooltip {
            color: var(--cx-color-black);
            text-decoration: underline;
            font-weight: 700;
            outline: none;
          }
        }
      }
    }

    &-right {
      flex-basis: 40%;
      padding: 3px 5px;
      min-width: 150px;

      .order-details {
        padding: 10px;

        &-btn {
          text-decoration: none;
          outline: none;
          padding: 10px;
          border: 1px solid var(--cx-color-primary2);
          background-color: var(--cx-color-white);
          color: var(--cart-credit-modal-header);
          border-radius: 7px;

          &.viewOrderDetails-btn {
            width: 130px;
          }
        }
      }

      .amount-section {
        margin: 0 10px;
        padding: 10px;
        width: 130px;
        // max-width: 130px;
        min-width: 100px;
        height: auto;
        border: 1px solid var(--cx-rewards-bg);
        overflow-wrap: break-word;
        // Todo: use the color code, its used in my component.

        @include media-breakpoint-down(sm) {
          margin: 0;
          min-width: 140px;
        }
      }

      @include media-breakpoint-down(sm) {
        justify-content: end;
        padding: 20px 0px 0px 10px;
      }
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      gap: 0;
    }
  }
}
