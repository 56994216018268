.product-tile-view{
    gap: 16px;
    @include media-breakpoint-down(md) {
        column-gap: 32px;
    }
}
    .product-tile{
        flex: 33.33%;
        max-width: 275px;
        @include media-breakpoint-down(md) {
            max-width: none;
        }
        @include media-breakpoint-down(sm) {
            max-width: none;
        }
       
        .mat-checkbox-layout .mat-checkbox-label {
            line-height: 17px;
        }
       
        mat-checkbox span.mat-checkbox-inner-container{
            margin-top: 0;
            margin-right: 5px;
        }
        &-section {
            box-shadow: var(--common-box-shadow);
            background-color: var(--cx-color-white);
            height: 100%;
            position: relative;
        }
        mat-icon.product-tile-new-arrival{
            width: 86px;
            height: 13px;
        }
        &-choose-option-button{
            position: absolute;
            bottom: 0;
        }
        &-image{
            position: relative;
            &:hover{
                .mat-mdc-unelevated-button.product-tile-hover-viewDetails-button{
                    display: block;
                }
            }
            img{
                width: 213px;
                min-height: 210px;
                max-height: 210px;
            }
        } 
        .mat-mdc-unelevated-button.product-tile-hover-viewDetails-button{
            display: none;
            position: absolute;
            padding: 3px 33px;
            top: 77%;
            background:  var(--cx-color-white);
            left: 23%;
            border: 1px solid var(--cx-color-primary);
            border-radius: 5px;
            &:hover{
                background-color: var(--cx-p2-20);
                text-decoration: none;
            }
          }
        &-description{
            margin-top: 10px;
            .t-productTile-description.description{
                height: 40px;
                overflow: hidden;
                line-height: 1.2em;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            .t-productTile-brand.brand{
                height: 23px;
            }
            .t-productTile-productGroup.group{
                height: 20px;
            }
            .globalInventory{
                height: 48px;
            }
        }
        p{
            margin-bottom: 0;
        }
        &-lastPurchased{
            padding: 2px 0;
            background: var(--cx-drop-ship);
        }
        .t-productTile-productGroup{
            padding: 2px;
        }
        &-globalInventory{
            background: var(--cx-global-inventory);
        }
        &-summary{
            &.teaser{
                height: 60px;
                .t-productTile-teaser.teaser{
                    overflow: hidden;
                    line-height: 1.2em;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                }
            }
            margin: 0 2px;
            hr:first-of-type{
                margin-top:12px;
                margin-bottom: 5px;
            }
        }
        hr{
            margin-top:5px;
            margin-bottom: 12px;
            border-color: var(--cx-hr-border);
        }
        &-flag{
            height: 25px;
        }
        .min-mul-flags{
            height: 23px;
        }
        &-currency{
            top: -8px;
        }
        &-figure{
            top: 0;
        }
        span.product-tile-boldPrice{
            padding: 2px 4px;
            background: var(--cx-color-secondary);
            color: var(--cx-color-white);
        }
        &-was{
            margin-left: 5px;
        }
        &-dash{
            top: -2px;
            margin: 0 5px;
        }
        &-calculatingPrice{
            margin-top: 21px;
        }
        &-inStock{
            .mat-icon.product-tile-inStock-icon{
                margin-right: 8px;
                width: 23px;
                //height: 16px;
            }
            span.product-tile-inStock-text{
                color: var(--cx-multiple-order);
            }
        }
        .mat-icon.product-tile-backOrdered-icon{
            width: 14px;
            // height: 20px;
            // margin-right: 12px;
            margin-left: 5px;
        }
        &-backOrdered{
            span.product-tile-backOrdered-text {
                color: var(--cx-warning-error);
            }
        }
        .mat-icon.product-tile-discontinued-icon{
            width: 23px;
            // height: 19px;
            // margin-right: 7px;
            margin-left: 2px;
        }
        &-discontinued{
            span.product-tile-discontinued-text{
                color: var(--cx-warning-error);
            }
        }
        &-specialorder{
            span.product-tile-specialorder-text {
                color: var(--cx-multiple-order);
            }
        }
        .mat-icon.product-tile-specialorder-icon,
        .mat-icon.product-tile-promo-icon
        {
            margin-right: 9px;
            margin-left: 2px;
            width: 20px;
            height: 20px;
        }
        &-promo{
            span.product-tile-promo-text {
                color: var(--cx-multiple-order);
            }
        }
        .mat-icon.product-tile-promo-icon{
            height: 12px;
        }
        &-addToCart{
            margin-left: 2px;
        }
        .mat-mdc-unelevated-button.product-tile-addToCart-button.mat-mdc-unelevated-button{
            padding: 10px 20px;
            color: var(--cx-color-white);
        }
        &-like-icon{
            margin-left: 16px;
        }
        .mat-icon.product-tile-like-icon{
            width: 20px;
        }
        &-multiple-order{
            background: var(--cx-multiple-order);
            padding: 0 5px;
            p{
                color: var(--cx-color-white);
            }
        }
        &-quantity-error{
            background: var(--cx-warning-error);
            padding: 0 5px;
            p{
                color: var(--cx-color-white);
            }
        }
        &-max-quantity{
            @extend .product-tile-multiple-order;
            background: var(--cx-color-secondary);
        }
        &-chooseOption,
        &-keepmeUpdated,
        &-viewDetails,
        &-consultation,
        &-substitutes,
        &-options,
        &-promo,
        &-specialorder,
        &-discontinued,
        &-backOrdered,
        &-inStock,
        &-multiple-order,
        &-addToCart
        {
            margin-top: 12px;
        }
        .mat-mdc-unelevated-button.product-tile-chooseOption-button,
        .mat-mdc-unelevated-button.product-tile-keepmeUpdated-button
        {
            padding-top: 8px;
            padding-bottom: 8px;
            background-color: var(--side-nav-border-bottom);
            color: var(--row-view-close-option);
        }
        .mat-mdc-unelevated-button.product-tile-keepmeUpdated-button{
            background-color: var(--cx-color-secondary);
            color: var(--cx-color-keep-updated);
            path {
                fill: var(--cx-color-keep-updated)
            }
        }
        .mat-icon.product-tile-bell-icon{
            margin: 0 10px 0 0;
            width: 18px;
            height: 22px;
        }
        .mat-mdc-unelevated-button.product-tile-viewDetails-button{
            text-decoration-line: underline;
        }
        .mat-mdc-unelevated-button.product-tile-button{
            white-space: normal;
            word-wrap: break-word;
            border: 1px solid var(--cx-color-primary2);
        }
        &-global-message{
            box-shadow: var(--common-box-shadow);
            background: var(--cx-global-inventory);
        }
    
        // Input Section Styling
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    
        /* Firefox */
        input[type=number] {
            -moz-appearance: textfield;
        }
        .quantity {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            margin-top: 7px;
          }
          .quantity__minus{
            box-sizing: border-box;
            width: auto;
            height: 45px;
            padding-right: 12px;
            padding-left: 12px;
            background: var(--cx-color-white);
            border: 1px solid var(--cx-color-primary);
            border-radius: 5px 0 0 5px;
          }
          .quantity__plus {
            @extend .quantity__minus;
            border-radius: 0 5px 5px 0;
          }
          .quantity__input {
            width: 52px;
            height: 45px;
            text-align: center;
            margin: 0 2px;
            border: 1px solid var(--cx-color-primary);
          }
    
          @include media-breakpoint-down(md) {
            .wishlist-side-drawer {
              .mat-mdc-dialog-container {
                padding: 0;
                width: 285px;
        
                .mat-mdc-unelevated-button.mat-primary {
                  background-color: var(--cx-color-primary2);
                  padding: 5px;
        
                  &.sitelinks-close {
                    min-width: 25px;
                    padding: 0;
        
                    span {
                      color: var(--cx-color-white);
                    }
                  }
        
                  &.cdk-program-focused,
                  &.cdk-focused {
                    color: var(--cx-color-white);
                    background-color: var(--cx-color-primary2);
                  }
                }
              }
            }
        
            .wishlist-side-drawer {
              height: 100%;
            }
          }

          .save-amount {
            background: var(--save-amount);
            outline: 1px solid var(--save-amount);
            border: 1px solid var(--cx-color-black);
          }

          .product-tile-addToCart-button.mat-mdc-unelevated-button.mat-primary {
            padding: 10px 20px;
          }
    }

.row-view{
    max-width: 869px;
    p{
        margin-bottom: 0;
    }
    &-section {
        box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
        border-radius: 7px;
        background-color: var(--cx-color-white);
    }
    mat-icon.row-view-new-arrival{
        width: 86px;
        height: 13px;
    }
    
    .mat-checkbox-inner-container{
        height: 18px;
        width: 18px;
    }
    .mat-mdc-unelevated-button.row-view-addToCart-button , .mat-mdc-unelevated-button.product-tile-addToCart-button{
        padding: 10px 20px;
        color: var(--cx-color-white);
        height: 40px;
        min-width: 80px;
        @include media-breakpoint-down(md) {
            padding: 10px;
        }
    }
    .mat-mdc-unelevated-button.row-view-viewDetails-button{
        text-decoration-line: underline;
    }
    .product-tile-viewDetails .btn{
        height: 38px;
    }
    .btn.row-view-close-option-button {
        background-color: var(--side-nav-border-bottom);
        color: var(--row-view-close-option);
        padding: 9px 38px 9px 38px;
    }
    .btn.row-view-show-option-button {
        background-color: var(--cx-row-view-show-option-button);
        color: var(--cx-color-white);
        padding: 9px 30px 9px 30px;
    }
    .btn.row-view-down-arrow{
        background-color: var(--cx-row-view-down-arrow);
        border-radius: 0 5px 5px 0 !important;
    }
    .btn.row-view-up-arrow{
        background-color: var(--cx-row-view-up-arrow);
        border-radius: 0 5px 5px 0 !important;
    }
    .mat-icon.row-view-downArrow-icon, .mat-icon.row-view-up-arrow-icon {
        height: 16px;
        width: 19px;
        font-size: 1rem;
    }
   
    mat-checkbox span.mat-checkbox-inner-container{
        margin-right: 5px;
    }
    &-image{
        position: relative;
        &:hover{
            .mat-mdc-unelevated-button.product-tile-hover-viewDetails-button{
                display: block;
            }
        }
        margin-left: 10px;
        margin-right: 37px;
        img{
            max-width: 151px;
            height: 151px;
        }
    }
    .mat-mdc-unelevated-button.product-tile-hover-viewDetails-button{
        display: none;
        position: absolute;
        padding: 3px 33px;
        bottom: 20%;
        background:  var(--cx-color-white);
        border: 1px solid var(--cx-color-primary);
        border-radius: 5px;
        &:hover{
            background-color: var(--cx-p2-20);
            text-decoration: none;
        }
    }
    &-line{
        border-right: 1px solid var(--cx-color-black);
    }
    &-flex-margin{
        margin-left: 26px;
        margin-right: 26px;
        width: 348px;
    }
    &-right-section {
        @include media-breakpoint-down(md) {
            min-width: 220px;
        }
    }
    &-price-margin{
        margin-top: 2px;
    }
    &-brand{
        margin-top:2px;
    }
    &-globalInventory{
        background: var(--cx-global-inventory);
        width: fit-content; // To fit the background color to content width
    }
    hr:first-of-type{
        margin-bottom: 6px;
        margin-top: 0;
    }
    hr{
        margin-top: 6px;
        border-color: var(--cx-hr-border);
    }
    &-inStock{
        margin-top: 15px;
        .mat-icon.product-tile-inStock-icon{
            margin-right: 8px;
            width: 23px;
            //height: 16px;
        }
        span.row-view-inStock-text{
            color: var(--cx-multiple-order);
        }
    }
    .mat-mdc-unelevated-button.row-view-keepmeUpdated-button
    {
        padding-top: 8px;
        padding-bottom: 8px;
        background-color: var(--cx-color-primary2);
        color: var(--cx-color-keep-updated);
        path {
            fill: var(--cx-color-keep-updated);
        }
    }
    .mat-mdc-unelevated-button.row-view-keepmeUpdated-button{
        background-color: var(--cx-color-secondary);
    }
    &-multiple-order{
        margin-top: 12px;
        height: 16px;
        width: 73%;
        background: var(--cx-multiple-order);
        padding: 0 5px;
        p{
            color: var(--cx-color-white);
            top: -3.5px;
            position: relative;
        }
    }
    
    // Input Section Styling
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
    .quantity {
        // display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin-top: 7px;
      }
      .quantity__minus{
        box-sizing: border-box;
        width: 46px;
        height: 45px;
        background: var(--cx-color-white);
        border: 1px solid var(--cx-color-primary);
        border-radius: 5px 0 0 5px;
      }
      .quantity__plus {
        @extend .quantity__minus;
        border-radius: 0 5px 5px 0;
      }
      .quantity__input {
        width: 52px;
        height: 45px;
        text-align: center;
        margin: 0 2px;
        border: 1px solid var(--cx-color-primary);
      }
    &-line-design{
        height: 73px;
        width: 5px;
        background: var(--cx-color-primary2);
    }
    &-margin-top{
        margin-top: 18px;
    }
    &-padding-left{
        padding-left: 11px;
        padding-right: 100px;
        p{
            margin-bottom: 3px;
        }
    }
    &-padding-left-two{
        @extend .row-view-padding-left;
        padding-right: 58px;
    }
    &-margin-left-two{
        // @extend .row-view-margin-left;
        margin-right: 58px;
    }
    &-sigleVariantPrice{
        margin-top: 7px;
    }
    &-qty-input{
        height: 38px;
        width: 51px;
        border-radius: 4px;
        margin-left: 9px;
        margin-right: 12px;
        .mat-mdc-form-field-infix {
            padding-top: 4px;
            padding-bottom: 4px;
        }
    }

    @include media-breakpoint-down(md) {
        .wishlist-side-drawer {
          .mat-mdc-dialog-container {
            padding: 0;
            width: 285px;
    
            .mat-mdc-unelevated-button.mat-primary {
              background-color: var(--cx-color-primary2);
              padding: 5px;
    
              &.sitelinks-close {
                min-width: 25px;
                padding: 0;
    
                span {
                  color: var(--cx-color-white);
                }
              }
    
              &.cdk-program-focused,
              &.cdk-focused {
                color: var(--cx-color-white);
                background-color: var(--cx-color-primary2);
              }
            }
          }
        }
    
        .wishlist-side-drawer {
          height: 100%;
        }
      }

      .save-amount {
        background: var(--save-amount);
        outline: 1px solid var(--save-amount);
        border: 1px solid var(--cx-color-black);
      }
}
.row-view-card{
    max-width: 833px;
    box-shadow: var(--common-box-shadow);
    .sub-cards{
        border-top: 1px solid var(--cx-color-primary2);
    }
    .mat-mdc-unelevated-button.row-view-cart-button{
        padding: 9px 16px;
        color: var(--cx-color-white);
        top: -5px;
    }
    &-drop-ship{
        margin-top: 9px;
        background: var(--cx-drop-ship);
        padding: 2px 13px;
        border-bottom: 1px solid var(--cx-color-secondary);
    }
    &-drop-ship-two{
        background: var(--cx-drop-ship);
        padding: 2px 13px;
    }
    &-separator{
        border: 1px solid var(--cx-color-secondary);
        transform: rotate(90deg);
    }
    p{
        margin: 0;
    }
    &-error-notification{
        margin-bottom: 2px;
        margin-top: 7px;
        padding: 2px 6px;
        background: var(--cx-error-bar);
    }
    &-keepmeUpdated {
        margin-top: 12px;
        width: 220px;
    }
    .mat-icon.row-view-card-bell-icon{
        margin: 0 10px 0 0;
        width: 18px;
        height: 22px;
        svg path {
            fill: var(--cx-color-black);
        }
    }
    .mat-mdc-unelevated-button.row-view-card-keepmeUpdated-button
    {
        padding: 10px;
        background-color: var(--cx-color-primary2);
        color: var(--cx-color-black);
        background-color: var(--cx-color-secondary);
    }
    .product-tile-specialorder,.product-tile-promo{
        margin-top: 7px;
    }
    .mat-mdc-unelevated-button.product-tile-button{
        max-width: 254px;
        white-space: normal;
        word-wrap: break-word;
        border: 1px solid var(--cx-color-primary2);
    }
}