//recently viewed product style

.recently-viewed-product {

  width: 275px;
  height: 100%;
  background: var(--cx-color-white);
  box-shadow: 0 4px 4px var(--cx-box-shadow);
  border: 1px solid var(--cx-global-inventory);
  border-radius: 8px 8px 0px 0px;

  .product-tag {
    width: 86px;
    height: 13px;
    background: var(--cx-warning-success);
    border-radius: 8px;

        font-size: 10px;
        text-align: center;
        letter-spacing: 1.1px;
        color: var(--cx-color-white);
  }

  .t-productTile-description.description{
    height: 40px;
    overflow: hidden;
    line-height: 1.2em;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .t-productTile-brand.brand{
    height: 23px;
  }
  .product-tile-image{
    position: relative;
    &:hover{
        .mat-mdc-unelevated-button.product-tile-hover-viewDetails-button{
            display: block;
        }
    }
    img{
        width: 213px;
        min-height: 210px;
        max-height: 210px;
    }
      .product-tile-hover-viewDetails-button{
      display: none;
      position: absolute;
      padding: 3px 33px;
      bottom: 10%;
      background:  var(--cx-color-white);
      left: 20%;
      border: 1px solid var(--cx-color-primary);
      border-radius: 5px;
      &:hover{
          background-color: var(--cx-p2-20);
          text-decoration: none;
      }
    }
  }

  // Input Section Styling
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  // Firefox 
  input[type="number"] {
    -moz-appearance: textfield;
  }
  
  .quantity__minus {
    box-sizing: border-box;
    width: 46px;
    height: 45px;
    background: var(--cx-color-white);
    border: 1px solid var(--cx-color-primary);
    border-radius: 5px 0 0 5px;
  }
  .quantity__plus {
    @extend .quantity__minus;
    border-radius: 0 5px 5px 0;
  }
  .quantity__input {
    width: 52px;
    height: 45px;
    text-align: center;
    margin: 0 2px;
    border: 1px solid var(--cx-color-primary);
  }

  .mat-mdc-unelevated-button.product-tile-addToCart-button.mat-mdc-unelevated-button {
    padding: 12px 20px;
    color: var(--cx-color-white);
  }
  .mat-mdc-unelevated-button.product-tile-choose-options-button
    {
        padding-top: 8px;
        padding-bottom: 8px;
        background-color: var(--side-nav-border-bottom);
        color: var(--row-view-close-option);
    }

    .rvp-instock{
      .rvp-instock-icon{
        width: 23px;
        height: 16px;

      }

      .rvp-instock-text{
        font-size: 17px;
        letter-spacing: 0.25px;
        font-weight: 900;
        color: var(--cx-multiple-order);
      }  

    }

    .rvp-outOfStock{
      .rvp-outOfStock-icon{
        width: 23px;
        height: 16px;

      }

      .rvp-outOfStock-text{
        font-size: 17px;
        letter-spacing: 0.25px;
        font-weight: 900;
        color: var(--cx-warning-error);
      }  

    }

    .rvp-backOrdered{
      .rvp-backOrdered-icon{
        width: 23px;
        height: 16px;

      }

      .rvp-backOrdered-text{
        font-size: 17px;
        letter-spacing: 0.25px;
        font-weight: 900;
        color: var(--cx-warning-error);
      }  

    }

    .rvp-discontinued{
      .rvp-discontinued-icon{
        width: 23px;
        height: 16px;

      }

      .rvp-discontinued-text{
        font-size: 17px;
        letter-spacing: 0.25px;
        font-weight: 900;
        color: var(--cx-warning-error);
      }  

    }

    .rvp-specialorder{
      .rvp-specialorder-icon{
        width: 23px;
        height: 16px;

      }

      .rvp-specialorder-text{
        font-size: 17px;
        letter-spacing: 0.25px;
        font-weight: 900;
        color: var(--cx-multiple-order);
      }  

    }

   

    .rvp-qty-error-box-enter-qty{
     
      height: 16px;
      color: var(--cx-color-white);
      text-align: center;
      background: var(--cx-warning-error);
    }
    .rvp-qty-error-box-max{
    
      height: 16px;
      color: var(--cx-color-white);
      text-align: center;
      background: var(--cx-color-secondary);
    }
    .rvp-qty-error-box-multiple{
     
      height: 16px;
      color: var(--cx-color-white);
      text-align: center;
      background: var(--cx-multiple-order);
    }
    .add-cart-rvp-qty-error-box-multiple{
      height: 16px;
      color: var(--cx-color-white);
      text-align: center;
      background: var(--cx-warning-error);
    }

    .rvp-offer{
      width: 62px;
      height: 16px;
      background: var(--cx-alert);
      color: var(--cx-color-white);
      text-align: center;
    }

 
}

.recently-viewed-products-wrapper {
  @include media-breakpoint-down(sm) {
    justify-content: center;
  }
}

