.cart-summary {
  .cart-summary-section {
    .cart-summary-enteries {
      .promotion-discount {
        background-color: var(--cx-success-lite);
        color: var(--cx-success-dark);
      }

      .shipping-icon {
        height: auto;
      }
    }
  }
}

.clear-cart-dialog {
  .mat-mdc-dialog-container {
    overflow: hidden;
  }
}
