body {
  .order-detail {
    &-info {
      background: var(--cx-color-white);
      box-shadow: var(--common-box-shadow);
    }

    &-sub {
      max-width: 80%;

      @include media-breakpoint-down(sm) {
        max-width: 100%;
      }
    }

    &-headerInfo {
      border-bottom: 5px solid var(--cx-color-primary);
    }

    &-address {
      background-color: var(--main-content-background);
      .width-62 {
        @include media-breakpoint-up(lg) {
          width: 62%;
        }
      }
    }

    &-shipTo {
      min-width: 49%;
      background: var(--cx-color-white);
      box-shadow: var(--common-box-shadow);
    }

    &-address-text {
      background-color: var(--cx-p10);
    }

    &-total-head {
      border-bottom: 2px solid var(--cx-color-black);
    }

    &-total {
      background: var(--cx-color-white);

      .pb-4 {
        padding-bottom: 1.56rem !important;
      }

      box-shadow: var(--common-box-shadow);
    }

    &-tax {
      width: 275%;
      border-bottom: 1px solid var(--cx-color-black);
    }

  
  }
}

@media print {

  .header-searchbar {
    display: none;
  }

  .header-search-icon {
    display: none;
  }

  .order-list {
    &-status {
      display: none;
    }
  }

  .order-status {
    display: none;
  }

  .order-detail-info {
    border-bottom: 2px solid var(--cx-color-black);
  }

  .order-rewards-credits {
    display: block;
    padding: 6rem 0 0 0;
    height: 25vh;
  }

  .order-detail-history-print {
    height: 30vh;
  }

  .account-num {
    display: block !important;
    z-index: 111;
  }

  .NavigationBar {
    display: none !important;
  }

  .header-sticky {
    display: none !important;
  }

  .header-user {
    padding: 10rem 0 0 0;
    display: flex !important;
    flex-direction: row;
  }

  .mat-badge-content {
    display: none !important;
  }

}