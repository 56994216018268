.order-promotion {
  .all-promotions {
    background-color: var(--cx-warning-success-light);
    border-radius: 7px;
    .inner-body {
      border: 1px dashed var(--cx-warning-success);
      border-radius: 7px;
      gap: 1rem;
      .promotion-icon-container {
        background-color: var(--cx-warning-success);
        border-radius: 50%;
        width: 45px;
        height: 44px;
        .mat-icon[svgicon="order_promotion"] {
          width: 26px;
          height: 26px;
        }
      }
      .apply-remove-promo {
        position: relative;
        min-width: 50px;
        background-color: var(--cx-warning-success);
        color: var(--cx-color-white);
      }
      .arrow {
        position: relative;
        top: 6px;
        span {
          display: inline-block;
          width: 10px;
          height: 10px;
          border-bottom: 3px solid var(--cx-warning-success);
          border-right: 3px solid var(--cx-warning-success);
          transform: rotate(-45deg);
          margin: 0 1px;
          animation: animate 2s infinite;
        }
      }
    }
  }
  .applied-promotions {
    .applied-order-promo {
      background-color: var(--cx-color-white);
      border: 1px dashed var(--side-nav-border-bottom);
      border-radius: 7px;
      gap: 1rem;
      .promotion-icon-container {
        background-color: var(--cx-warning-success);
        border-radius: 50%;
        .mat-icon[svgicon="applied_promo"] {
          width: 12px;
          height: 12px;
          line-height: 12px;
        }
      }
      .apply-remove-promo {
        min-width: 50px;
        border: 1px solid var(--cx-warning-success);
        background-color: var(--cx-color-white);
        color: $base-10 !important;
      }
    }
  }
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(-45deg) translate(-10px, -14px);
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
    transform: rotate(-45deg) translateX(1px);
  }
}
