.sr-search {
  max-width: 1200px; // Looking good in this width
  &-user-info {
    .user-details {
      box-shadow: var(--common-box-shadow);
      background-color: var(--cx-color-white);
      border-radius: 5px 5px 0 0;

      .top-section {
        .right-section {
          gap: 12px;
          .manage-button {
            height: 30px;
          }
          .toggle-button {
            @extend .manage-button;
            background-color: var(--search-dropdown-bg);

            @include media-breakpoint-down(xs) {
              width: 76px; // Necessary for design
            }

            .mat-icon {
              width: 15px;
              height: 19px;
            }
            &.active {
              .mat-icon {
                transform: rotate(180deg);
              }
            }
          }
        }
      }
      .bottom-section {
        .email-and-phone {
          gap: 24px;
          @include media-breakpoint-down(md) {
            gap: 7px;
          }
          .email {
            text-decoration-line: underline;
          }
        }

        .account-type {
          .tag {
            border-radius: 100px;
            color: var(--cx-color-white);
            &.sub-account {
              background-color: var(--status-blue);
            }
            &.direct-account {
              background-color: var(--sr-purple-tag);
            }
            &.parent-account {
              background-color: var(--cx-multiple-order);
            }
          }
        }
      }
    }
    .other-details {
      //width: 96%; // Design needs
      //margin-left: 4%; // Design needs
      background-color: var(--sr-sidenav);
      box-shadow: var(--common-box-shadow);
      border-radius: 0 0 5px 5px;

      .alert-section {
        min-height: 23px;
        background-color: var(--cx-color-darkOrange);
        color: var(--cx-color-white);
      }
      .address-and-billing-section {
        border-radius: 0 0 5px 5px;

        .primary-bill-to,
        .primary-ship-to,
        .license,
        .billing {
          color: var(--status-grey);
        }
        .left-section {
          width: 50%;

          @include media-breakpoint-down(sm) {
            width: 100%;
          }
        }
        .right-section {
          @extend .left-section;

          .credit-card-holder {
            height: 20px;
            background-color: var(--yellow-bg-color);
          }
        }
      }
    }
  }
}
