.drag-drop-file-modal {
  max-width: 600px !important;

  .mdc-dialog__surface {
    padding: 0 !important;
    margin: 0 !important;
  }

  .mat-mdc-dialog-surface.mdc-dialog__surface {
    border-radius: 10px;
  }
}

.drag__main-container {
  padding: 16px;
  color: var(--cx-color-black);
  .drag {
    gap: 16px;

    &__close {
      .dialogCloseIcon {
        background-color: var(--cx-color-primary2);
        color: var(--cx-color-white);
        height: 30px;
        width: 30px;
      }
    }

    &__subText {
      text-align: center;
    }

    .file-upload {
      padding: 16px;
      max-width: 300px;

      &__section {
        border-radius: 10px;
        background-color: var(--cx-p2-10);
        padding: 16px;
        gap: 16px;
        color: var(--cx-color-black);
        transition: all 0.3s ease;

        // Below code is for custom border in drop area.
        background-image: repeating-linear-gradient(
            -1deg,
            var(--cx-color-primary),
            var(--cx-color-primary) 10px,
            transparent 10px,
            transparent 27px,
            var(--cx-color-primary) 27px
          ),
          repeating-linear-gradient(
            89deg,
            var(--cx-color-primary),
            var(--cx-color-primary) 10px,
            transparent 10px,
            transparent 27px,
            var(--cx-color-primary) 27px
          ),
          repeating-linear-gradient(
            179deg,
            var(--cx-color-primary),
            var(--cx-color-primary) 10px,
            transparent 10px,
            transparent 27px,
            var(--cx-color-primary) 27px
          ),
          repeating-linear-gradient(
            269deg,
            var(--cx-color-primary),
            var(--cx-color-primary) 10px,
            transparent 10px,
            transparent 27px,
            var(--cx-color-primary) 27px
          ) !important;
        background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px !important;
        background-position: 0 0, 0 0, 100% 0, 0 100% !important;
        background-repeat: no-repeat !important;
      }

      &__logo {
        mat-icon {
          height: 45px;
          width: 40px;
        }
      }

      &__subText {
        text-align: center;
        .upload-btn {
          color: var(--flag-blue);
        }
      }
    }
  }
}
