@mixin hideWrapperDesktop {
  display: none !important;
}

@mixin showWrapperDesktop {
  display: flex !important;
}

body {

  // OOTB nav-wrapper styles
  %nav-wrapper {
    .wrapper {
      cursor: default;
    }

    &.flyout {
      .wrapper {
        height: 0;
        overflow: hidden;
        background: transparent;
        color: var(--cx-color-black);

        .explore-all-section {
          background-color: var(--cx-color-white);
        }

        nav {
          background-color: var(--cx-color-white);

          span,
          cx-generic-link {
            background-color: var(--cx-color-white);
          }
        }
      }
    }
  }

  %nav-back-button {
    &.flyout {
      .back {
        display: none;
      }

      .back {
        display: initial;

        span {
          text-transform: capitalize;
          justify-content: flex-start;
        }
      }

      &:not(.is-open) {
        .back {
          display: none;
        }
      }
    }
  }

  .NavigationBar {
    //width: 65%;
    line-height: 10px;

    @include media-breakpoint-down(md) {
      width: 100%;
      line-height: normal;
    }

    //Navigation Component in header starts
    app-custom-category-navigation {

      //flex: 1 1 var(--cx-flex-basis, 100%);
      @include media-breakpoint-up(lg) {
        &:first-child {
          app-header-navigation-ui {
            margin-left: -20px !important; // overriding bootstarp classes with important *** This padding is to accomodate nav hover state
          }
        }
      }

      @include media-breakpoint-up(lg) {
        app-header-navigation-ui {
          // Adding width in header , this is needed to wrap the individual nodes after max width
          // min-width: 700px;
          // max-width: 750px;
          //margin-left: -16px; // *** This margin is to accomodate nav hover state
          margin-top: 3px;
        }
      }

      app-header-navigation-ui {
        display: flex;
        flex-wrap: wrap;

        @extend %nav-heading; // including OOTB nav-heading stles
        @extend %nav-wrapper;


        &.flyout {
          flex-direction: column;
          color: var(--cx-color-black);

          &.is-open {
            li.is-open {
              >span {
                display: none;
              }
            }
          }

          cx-icon,
          mat-icon {
            cursor: pointer;

            svg {
              height: 24px;
              width: 24px;
            }
          }

          .nav-link-wrapper {
            width: 100%;
          }

          .mat-icon.back-arrow {
            display: none;
          }

          @include media-breakpoint-down(md) {
            .explore-all-btn {
              display: none;
            }
          }

          @include media-breakpoint-up(lg) {

            // styles to always show the first main navigation node horizontally in desktop starts
            .main-nav-0 {
              padding-bottom: 12px;
              display: initial !important; // In desktop th First Nav Is always visibile so using important

              &.onlyMobile {
                display: none !important; // In desktop the onlyMobile Nav Is always hidden so using important
              }

              .main-nav-span-0 {
                display: initial !important; // In desktop th First Nav Span Is always visibile so using important
                color: var(--nav-color);
                line-height: normal;
                // to avoid css breaks for content pages
                p {
                  color: var(--nav-color);
                  font-family: 'Roboto';
                }

                &.onlyMobile {
                  display: none !important; // In desktop the onlyMobile Nav Span Is always hidden so using important
                }

                cx-icon,
                mat-icon {
                  display: none; // for first node in desktop arrows are always hidden
                }

                a.custom-nav-link {
                  color: var(--nav-color); // for first node in desktop links are always white color
                }
              }

              &.is-nav:hover {
                background-color: var(--cx-color-white);
                border-radius: 5px 5px 0 0;

                span.main-nav-span-0 {
                  color: var(--cx-color-secondary2); // on Hover the color of first Node changes
                  p {
                    color: var(--cx-color-secondary2); // on Hover the color of first Node changes
                  }
                }
              }
            }

            .title-0 {

              cx-icon,
              mat-icon {
                display: none;
              }
            }
          }

          // styles to always show the first main navigation node horizontally in desktop ends

          // styles to handle show hide of child-nodes on click of parent node starts 
          // On Immediate parent node class is-open is applied using js and on the main parent node class is-opened is applied using js
          &.is-open {
            >nav {
              display: none; // when flyout is initially opened setting all the navs to display none

              cx-generic-link.custom-nav-link.all {
                display: none; // when flyout is initially opened setting explore all div to display none
              }

              .nav-title {

                cx-icon,
                mat-icon {
                  display: none; // when flyout is initially opened setting all the icons to display none
                }
              }
            }

            nav.is-open {
              display: initial; // when a nav is clicked setting that navs to display inital

              cx-generic-link.custom-nav-link.all {
                display: initial;
              }

              >.wrapper {
                height: auto;
                display: flex;
              }
            }

            nav.is-opened {
              display: initial;

              >.wrapper>.childs>nav {
                display: none;

                &.is-open , &.is-opened {
                  display: initial;
                }
              }

              >span {
                display: none;
              }

              >.wrapper {
                height: auto;
                display: flex;
              }
            }

            // NAV 1 OPENED - setting the nav-1 back arrow to display initial and right arrow to display block 
            .main-nav-1.is-open {
              .nav-title.title-1 {

                cx-icon,
                mat-icon {
                  display: inline-block;
                  margin-right: 8px;
                }
              }

              mat-icon.right-arrow-1 {
                display: none;
              }
            }

            // NAV 2 OPENED - setting the nav-2 back arrow to display initial and right arrow to display block 
            .main-nav-2.is-open {
              .nav-title.title-2 {

                cx-icon,
                mat-icon {
                  display: inline-block;
                  margin-right: 8px;
                }
              }

              mat-icon.right-arrow-2 {
                display: none;
              }
            }

            // IN MOBILE NAV 0 OPENED - setting the nav-0 back arrow to display initial and right arrow to display block 
            @include media-breakpoint-down(md) {
              .main-nav-0.is-open {
                .nav-title.title-0 {

                  cx-icon,
                  mat-icon {
                    display: inline-block;
                    margin-right: 8px;
                  }

                  .explore-all-btn {
                    display: inline-block;
                    padding: 0 10px;
                    height: 28px;
                    background-color: var(--create-bus-btn);

                    cx-generic-link {
                      border: 0;

                      a {
                        color: var(--nav-color);
                      }
                    }

                    span {
                      padding: 0;
                      border: 0;
                    }
                  }
                }

                mat-icon.right-arrow-0 {
                  display: none;
                }
              }
            }
          }

          nav.is-opened>.wrapper>.childs[depth='4'] {
            .main-nav-1.is-opened {
              display: initial;
            }

          }

          @include media-breakpoint-up(lg) {

            // Show dropdowns via hover when no nav is focused
            >nav {
              position: relative;

              >.wrapper {
                position: absolute; // This is the hover Nav and needs to be absolute
                z-index: 99999;
                height: auto;
                top: 35px;
                left: 0;
                padding: 0;
                min-height: 600px;

                &.hasCoops {
                  background: var(--cx-color-white); // when we have coops we need white Background
                }

                &.noCoops {
                  background: transparent; // if no coops configured we need transparent background
                }

                @include hideWrapperDesktop();

                .nav-link-wrapper {
                  width: 275px;
                }

                .coops-wrapper {
                  background-color: var(--cx-p2-20);

                  // styles for horizontal coops
                  &-section.horizontal {
                    margin: 24px 20px;
                    background-color: var(--cx-color-white);
                    width: 550px;
                    height: 160px;

                    img {
                      width: 550px;
                      height: 160px;
                    }

                    &.section-0 {
                      margin-top: 30px;
                    }

                    &.section-2 {
                      margin-bottom: 30px;
                    }

                    @include media-breakpoint-up(xl) {
                      width: 785px;

                      img {
                        width: 785px;
                      }
                    }
                  }

                  // styles for vertical coops
                  &-section.vertical {
                    margin: 24px 20px;
                    background-color: var(--cx-color-white);
                    width: 295px;
                    height: 540px;

                    img {
                      width: 295px;
                      height: 540px;
                    }
                  }
                }
              }

              // We only treat the first wrapper as a collapsible container
              &:hover {
                >.wrapper {
                  @include showWrapperDesktop();
                }
              }

              span {
                padding: 0;
              }
            }

            // Show dropdowns via focus only once a nav is clicked
            &:focus-within {
              >nav {

                &:focus,
                &.is-open:focus-within {
                  >.wrapper {
                    @include showWrapperDesktop();
                  }
                }
              }
            }

            >nav {
              >.wrapper {
                transition: opacity 0.4s;
              }
            }

            nav nav>span {
              // @extend .t-menucategory-normal; // Giving error You may not @extend selectors across media queries.
              font-size: 0.875rem;
              line-height: 1.25rem;
              font-weight: 700;
            }

            // styles for explore all section in each Nav Node starts
            nav.main-nav-0 {
              .explore-all {
                &-section {
                  display: flex;
                  padding: 5px 10px 5px 0;
                  background-color: var(--cx-color-white);
                  border-bottom: 1px solid var(--side-nav-border-bottom);

                  span {
                    height: auto;
                  }
                }

                &-span {
                  width: 70%;
                  border: 0;
                  padding: 10px;
                  margin: 0;

                  &:hover {
                    color: var(--cx-color-black);
                  }
                }

                &-btn {
                  padding: 0 10px;
                  height: 28px;
                  background-color: var(--navbar-bg);
                  color: var(--nav-color);

                  span,
                  cx-generic-link.custom-nav-link {
                    border: 0;
                    padding: 0;

                    a {
                      color: var(--nav-color);

                      &:hover {
                        color: var(--nav-color);
                      }
                    }
                  }
                }
              }
            }

            &.is-open {
              nav.main-nav-0.is-opened {
                .explore-all-section {
                  display: none !important;
                }
              }
            }

            nav.main-nav-0.is-opened {
              .explore-all-section {
                display: flex !important;
              }
            }

            // styles for  explore all section in each Nav Node ends

          }

          nav {
            outline: none;

            span,
            cx-generic-link.custom-nav-link {
              border-bottom: 1px solid var(--side-nav-border-bottom);
              display: flex;
              padding: 10px;
              width: 100%;
              justify-content: space-between;
              outline: none;
              white-space: normal;

              a {
                padding: 0;
                color: var(--cx-color-black);
                outline: none;
                white-space: normal;

                &:hover {
                  color: var(--cx-color-black);
                }
              }

              // p {
              //   margin-top: 2px !important; // adding imp to overridde the bootstrap class margin
              // }
            }

            cx-generic-link.custom-nav-link {
              padding: 12px 10px;
            }

            @include media-breakpoint-up(lg) {
              .main-nav-span-0 {
                border: 0;
                padding: 0 !important;
              }

              .main-nav-2:not(.is-open) , .main-nav-3:not(.is-open) {

                span,
                a {
                  text-transform: none;
                  // @extend .t-subcategory-normal; This is giving errors You may not @extend selectors across media queries.
                  font-size: 0.8125rem;
                  line-height: 1.25rem;
                  color: var(--cx-color-black);
                  font-weight: 400;
                }
              }
            }

            @include media-breakpoint-down(md) {

              span,
              a {
                text-transform: none;
                // @extend .t-subcategory-normal; This is giving errors You may not @extend selectors across media queries.
                font-size: 0.8125rem;
                line-height: 1.25rem;
                color: var(--cx-color-black);
                font-weight: 400;
                p {
                  font-size: 0.8125rem;
                  color: var(--cx-color-black);
                  font-family: 'Roboto'; // To avoid breaks from migrated content
                }
              }

              .nav-node-link {
                border: 0;
              }
            }
          }

          @include media-breakpoint-down(md) {

            span,
            cx-generic-link.custom-nav-link {
              display: flex;
              align-items: center;
              white-space: nowrap;
              color: var(--cx-color-black);

              &:hover {
                color: var(--cx-color-black);
              }

              &:focus {
                z-index: initial;
                position: relative;
              }

              a {
                display: block;
                width: 100%;
                padding: 10px;

                &:focus {
                  z-index: initial;
                  position: relative;
                }
              }
            }

            .explore-all-section {
              display: none;
            }

          }

          // styles to handle show hide of child-nodes on click of parent node ends

        }
      }


    }
  }

  //Chat Component in header starts
  app-support-chat {
    width: 15%;
    .support-chat-comp {
      padding-bottom: 5px;
    }

    cx-page-slot.PhoneNumberSlot {
      width: auto;
    }
  }

  .live-chat-link,
  .support-num-link {
    border-right: 2px solid var(--cx-color-white);

    p {
      padding: 0;
      margin: 0;
    }

    a {
      color: var(--cx-color-white);
    }

    &:hover {
      color: var(--link-text-color-inverse);
    }
  }

  @include media-breakpoint-down(lg) {
    .support-num-link {
      border: 0;
    }
  }

  .LiveSupportSlot {
    custom-paragraph {
      display: none;
    }
  }

  .PhoneNumberSlot {
    @extend .support-num-link;
    border-right: 0;

    a {
      @extend .t-title-light-inverse-m;
      @extend .pl-2;
      color: var(--nav-color);
    }
  }

  //Chat Component in header ends
}