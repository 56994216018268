.revision-history{
  box-shadow: var(--common-box-shadow);
  background: var(--cx-color-white);
  .table-bordered {
    border: 1px solid var(--cx-color-black);
  }
  .table thead th{
        text-align: start;
        color:var(--cx-color-black);  
        border-bottom: 2px solid var(--cx-table-vertical-border);
        text-transform: none;
  }
  
}