table.bxd-portal-table {
  background-color: var(--cx-color-white);
  width: 100%;
  margin: 0;
  border-color: var(--cx-table-vertical-border);
  box-shadow: var(--common-box-shadow);

  tr:first-of-type {
    border-bottom: 3px solid var(--cx-color-black);
    height: 35px;
  }

  th,
  td {
    border-right: 1px solid var(--cx-table-vertical-border);
    border-bottom: 2px solid var(--cx-color-white);
    text-align: center !important;
    vertical-align: middle !important;
    padding: 10px;
  }

  td {
    border-bottom: 2px solid var(--cx-color-white);
  }

  tr:nth-child(odd):not(:first-of-type) {
    background: var(--cx-product-table-bg);
  }
}

.bxd-scroll-bar {
  &::-webkit-scrollbar {
    height: 7px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    margin: 12px 0 12px 0;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--cx-table-scroll);
    border-radius: 10px;
  }

  @include media-breakpoint-down(sm) {
    &::-webkit-scrollbar {
      height: 3px;
    }
  }
}
