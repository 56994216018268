.register-main {
  .mat-step-text-label {
    margin-left: 15px;
  }


  a{
    color:var(--cx-color-black);
    &:hover{
        color:var(--cx-color-black);
    }
}

  @include media-breakpoint-down(sm) {
    .mat-stepper-vertical-line::before {
      border-left-color: var(--cx-color-primary2);
      margin: 2px;
    }

    .mat-step-header .mat-step-label {
      margin-left: 18px;
    }

    .mat-step-header.mat-step-icon-selected {
      margin: 0px -10px;
    }

    .mat-step-header.mat-step-icon {
      margin: 0px -10px;
    }

    .mat-step-text-label {
      margin-left: 0px;
    }
  }

  .mat-step-icon {
    height: 45px;
    width: 45px;
  }

  .mat-step-header .mat-step-icon {
    background-color: var(--cx-p40);
    margin: 0px -8px;
  }

  .mat-step-header .mat-step-icon-state-done {
    background-color: var(--cx-color-primary);
    margin: 0px -8px;
  }

  .mat-step-header .mat-step-icon-selected {
    background-color: var(--cx-color-primary);
    margin: 0px -8px;
  }

  .mat-step-text-label {
    font-size: 16px;
  }

  .stepperClass {
    background-color: var(--cx-p2-10);

    .mat-horizontal-content-container {
      padding: 0;
    }

    .mat-horizontal-stepper-header-container {
      background: var(--cx-color-white);
      box-shadow: var(--common-box-shadow);
      margin-bottom: 55px;
      height: 99px;
      padding: 0 16px;
    }
  }

  .register-account-main-container {
    .rightBottomSlot {
      background: var(--cx-color-white);
      box-shadow: var(--common-box-shadow);
    }

    .content-slot-left {
      background: var(--cx-color-white);
      box-shadow: var(--common-box-shadow);
      width: 48%;
      border-radius: 6px;

      .mat-mdc-form-field {
        width: 97%;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }

    .content-slot-right {
      width: 48%;
    }

   

    .content-slot-right-step2 {
      background: var(--cx-p2-10);
      width: 48%;
    }

    .rightTopSlot {
      background: var(--cx-color-white);
      box-shadow: var(--common-box-shadow);
    }

    .mat-step-icon .mat-icon[svgIcon] {
      height: 24px;
    }

    .register-account-form {
      padding: 0 16px 50px 0;
    }
    .register-account-custom {
      .t-title-l {
        font-size: $font-size24;
      }

      .paragraphSlot {
        p {
          margin-bottom: 1.5rem;
          line-height: 1.28;
        }
      }
    }
    .mat-form-field-label, .mat-mdc-floating-label {
      font-weight: $font-weight-medium;
    }
  }
}