.portal-bxd-user-setting {
  &-password-box {
    max-width: 100%;
    box-shadow: var(--common-box-shadow);
    background-color: var(--cx-color-white);
    .password {
      background-color: var(--sr-nav);
    }
    .change-password-cta {
      border-radius: 8px;
      height: 36px;
      color: var(--cx-mat-input-color) !important; // Necessary for design
      border-color: var(--sr-nav);
    }
  }

  &-changePassword-btn{
    padding: 6px 16px !important;
  }

  &-width-80 {
    max-width: 80%;
  }

  .active-validation-message{
    color: var(--cx-warning-success);
  }

  .portal-bxd-change-password {
    min-width: 320px;
    @include media-breakpoint-up(sm) {
      width: 476px;
    }

    .model-close {
      top: 0;
      right: 0;
    }

    &-heading {
      color: var(--cx-color-black);
    }
    
    &-form {
      .create-password-button {
        height: 44px;
      }
      .password-validation-list {
        padding-left: 0;
        li {
          margin-left: 16px;
        }
      }
    }
  }
}
