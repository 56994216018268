body {
  .userid-preferences {
    &-header {
      background: var(--cx-p2-60);
    }

    &-heading {
      height: 92px;
    }

    &-main-block {
      background: var(--cx-color-white);
      box-shadow: 0 4px 4px rgba(0 0 0 / 25%);
    }

    &-block {
      box-shadow: 0;
    }

    &-sub {
      border: 0;
    }

    &-card {
      border: none;
    }

    .table thead tr th {
      text-transform: inherit;
      color: var(--cx-color-black);
    }

    .table thead tr th:first-of-type {
      width: 80%;
    }

    .table tbody tr {
      border: 1px solid var(--cx-table-border);
    }

    &-account-block {
      border: 1px dashed var(--cx-color-primary);
      padding: 24px;
    }

    .gap-16 {
      gap: 16px;
      display: grid;
    }
  
    .gap-5 {
      gap: 5px;
      display: grid;
    }

  }

  .edit-email-modal {
    mat-dialog-container {
      padding-left: 0;
    }

    input {
      outline: none;
    }

    .password-dialog-password-validate {
      padding-left: 12px;
      font-size: 12px;
    }
  }

  .tooltip {
    &-warning {
      margin-left: 10px;
      width: 95%;
    } 
    &-icon {
      width: 50px;
      height: 50px;
    }
  }
}
