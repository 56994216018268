.outlined-badge-success {
  border-radius: 7px;
  border: 1px solid var(--cx-warning-success);
  padding: 2px 5px;
}

.outlined-badge-secondary-2 {
  border-radius: 7px;
  border: 1px solid var(--cx-color-secondary2);
  padding: 2px 5px;
}

// For NGX Pagination
.ngx-pagination {
  display: flex;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  align-items: flex-end;
  -webkit-align-items: flex-end;
  margin: 0;
  padding-left: 0;

  li {
    display: flex !important; // Library CSS have greater precedence
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 2px !important; // Library CSS have greater precedence
    margin-left: 10px;
    border: none;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      min-width: 30px;
      line-height: 30px;
      text-decoration: none;
      text-align: center;
    }

    &.pagination-previous {
      border: 1px solid var(--cx-pagination-border);
      background-color: var(--cx-pagination-border);
      cursor: pointer;

      &.disabled {
        background-color: transparent;
        cursor: not-allowed;

        &::before {
          content: url("./../../../assets/pagination-next-disabled.svg");
          margin-right: 0rem !important; // Library CSS have greater precedence
          line-height: 25px;
          transform: rotate(180deg);
        }
      }

      a::before {
        content: url("./../../../assets/pagination-next.svg");
        margin-right: 0rem !important; // Library CSS have greater precedence
        line-height: 25px;
        transform: rotate(180deg);
      }
    }

    &.pagination-next {
      border: 1px solid var(--cx-pagination-border);
      background-color: var(--cx-pagination-border);
      cursor: pointer;

      &.disabled {
        cursor: not-allowed;
        background-color: transparent;

        &::after {
          content: url("./../../../assets/pagination-next-disabled.svg");
          margin-left: 0rem !important; // Library CSS have greater precedence
          line-height: 25px;
        }
      }

      a::after {
        content: url("./../../../assets/pagination-next.svg");
        margin-left: 0rem !important; // Library CSS have greater precedence
        line-height: 25px;
      }
    }

    &.current {
      padding-left: 5px;
      padding-right: 5px;
      width: auto;
      background-color: var(--cx-color-primary2);
      border: 1px solid var(--cx-color-primary2);
    }

    &.small-screen {
      display: none !important; // Library CSS have greater precedence
    }
  }
}

// For NGX Pagination

// For Button
button:disabled {
  opacity: 0.5;
}

// For Button

// For Dialog Scrollbar width
.mat-mdc-dialog-container {
  &::-webkit-scrollbar {
    width: 7px;
    background: var(--cx-p10);
    box-shadow: inset 0 0 4px var(--cx-p10);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--cx-table-scroll);
  }
}

// For Dialog Scrollbar width

//for homepage global fix for top empty p tag margin

.LandingPage2Template,.spaAboutUsPageTemplate,.ContentPage1Template,.ErrorDetailsPageTemplate,.knowledgeTrainersListingPageTemplate,.contactUsPageTemplate,.faqPageTemplate,
.knowledgePageTemplate,.knowledgeTrainerDetailsPageTemplate,.whatHotPageTemplate,.privacyPolicyPageTemplate,.ourCulturePageTemplate,.wbcArticlePage2Template,.livingHealthyPageTemplate,
.gymsClubsCrossFitBoxesPageTemplate,.siteMapPageTemplate,.knowledgeTrainingTrendsPageTemplate,.distributorPageTemplate,.returnPolicyPageTemplate,
.trainerAdvantageProgramPageTemplate {
  custom-paragraph p{
    margin-bottom: 0;
  }
}