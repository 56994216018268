.order-reward {
    &-credit-label {
       color: var(--cx-warning-error) !important;
    }
        
    &-border {
       border-bottom: 1px solid var(--cx-table-vertical-border);
    }
}

