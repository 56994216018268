.shipto-cart-businessAccount {
  overflow-y: auto;
  padding: 0px;

  .mat-mdc-dialog-container {
    padding: 0px;
  }
}

.ship-to-business-account-container {
  a{
    color:var(--cx-color-black);
    &:hover{
        color:var(--cx-color-black);
    }
}

  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 7px;
    background: var(--cx-p10);
    box-shadow: inset 0 0 4px var(--cx-p10);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--cx-table-scroll);
    border-radius: 10px;
  }



  z-index: 9999;
  position: fixed;
  width: 368px;
  box-shadow: var(--common-box-shadow);
  background: var(--cx-color-white);
  right: 0;
  top: 0;
  height: 100%;

  .ship-to-business {
    &-header {
      cursor: pointer;
      padding: 10px 10px;
      height: 44px;
      border-bottom: 1px solid var(--cx-color-black);

      .cancel-img {
        width: 25px;
      }

      .mat-mdc-unelevated-button.mat-primary {
        background-color: var(--cx-color-primary2);
        padding: 5px;

        &.sitelinks-close {
          min-width: 25px;
          padding: 0;

          span {
            color: var(--cx-color-white);
          }
        }

        &.cdk-program-focused,
        &.cdk-focused {
          color: var(--cx-color-white);
          background-color: var(--cx-color-primary2);
        }
      }
    }

    &-add {
      .mat-icon {
        width: 15px;
        margin: 0 10px;
      }

      .mat-mdc-unelevated-button {
        padding: 5px 10px;
      }
    }

    &-address {
      border: 1px dashed var(--side-nav-border-bottom);
      border-radius: 7px;

      &-end {
        border-bottom: 1px solid var(--cx-color-black);
      }

      &-action {
        .mat-mdc-unelevated-button {
          padding: 5px 10px;
          border-radius: 7px;
        }
      }

      &-count {
        height: 25px;
        width: 25px;
        background-color: var(--cx-warning-error);
        border-radius: 18px;
        text-align: center;
        color: var(--cx-color-white);
        border: 1px solid var(--cx-color-circleBorder)
      }

      &-new {
        height: 25px;
        width: 25px;
        background-color: var(--cx-color-primary);
        border-radius: 18px;
        color: var(--cx-color-white);
        border: 1px solid var(--cx-color-circleBorder)
      }
    }
  }

  .paginationPosition {
    position: fixed;
    bottom: 0px;
    width: 368px;
    z-index: 1000;
  }
}