.quick-orders-container {
  padding: 0 calc((100% - var(--xl-max-width)) / 2);

  // Below css is for the heading section of quick order
  .quick-order-heading-section {
    background: var(--cx-color-white);
    box-shadow: var(--common-box-shadow);
  }

  .quick-order-main-section {
    display: flex;
    flex-direction: row;
  }

  .bulk-order-section {
    background-color: var(--cx-color-white);
    flex: 50%;
    height: max-content;
    box-shadow: var(--common-box-shadow);
    &-text {
      height: 434px;
    }
    .mat-mdc-form-field {
      height: inherit;
      .mat-mdc-text-field-wrapper {
        height: inherit;
      }
    }
  }

  .quick-order-section {
    flex: 50%;
  }

  .quick-orders-items {
    display: flex;
    flex-direction: row;
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }

  .mat-mdc-text-field-wrapper {
    border-radius: 4px;
    padding-bottom: 0 !important;
  }

  .order-items {
    background-color: var(--cx-color-white);
    width: 100%;

    .mat-mdc-form-field {
      width: 100%;
      .mat-mdc-text-field-wrapper {
        padding-bottom: 0 !important;
        .mat-mdc-form-field-infix {
          height: 45px;
          min-height: auto;
        }
      }
    }
  }

  .order-qtys {
    background-color: var(--cx-color-white);

    .mat-mdc-form-field {
      width: 60px;
      .mat-mdc-text-field-wrapper {
        padding-bottom: 0 !important;
        .mat-mdc-form-field-infix {
          height: 45px;
          min-height: auto;
        }
      }
    }
  }

  @media (max-width: 800px) {
    .quick-order-main-section {
      flex-direction: column;

      .bulk-order-section {
        margin-left: 0 !important;
        margin-top: 32px;
      }
    }
  }

  .set-color {
    color: var(--cx-color-primary);
  }

  .exclamation-icon {
    color: var(--exclamation-icon-color);
  }

  .mat-mdc-form-field-icon-suffix .mat-icon{
    padding-bottom: 4px;
  }

  .order-opacity {
    opacity: 0.6;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}
