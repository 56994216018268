.online-bill-pay{
  &-rightHeaderSection-headerSectio{
    h1{
      font-size:28px ;
      font-weight: 400px;
      line-height: 36px;
    }
  }
  &-header,&-headerSection{
    background-color: var(--cx-color-white);
    box-shadow: var(--common-box-shadow);
  }
  &-heroLogoSection{
    cx-media img {
      max-width: 75%;
    }
  }
  &-summarySection{
    h2{
      font-size:22px ;
      font-weight: 400px;
      line-height: 28px;
    }
  }
  &-logoSection{
    cx-media img {
    height: 250px;
    width: 500px;
    }
  }
  &-contentSection{
    .content-container{
      .container{
        padding:0px;
        .grid{
          .col-12{
          padding:0px;
          .firstSection{
            padding: 0.5rem;
            background-color: var(--cx-color-white);
            box-shadow: var(--common-box-shadow);
          }
          .secondSection{
            padding: 0.5rem;
            background-color: var(--cx-color-white);
            box-shadow: var(--common-box-shadow);
          }
          }
          .container{
            padding:0.5rem;
            background-color: var(--cx-color-white);
            box-shadow: var(--common-box-shadow);
          }
        }
      }
    }
    .category-row{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(3, 1fr);
    }
    @include media-breakpoint-down(sm) {
      grid-template-columns: repeat(2, 1fr);
    }
    }
  }
}