.register-user-dialog{
  .phoneLink{
    a{
      color:var(--cx-color-black);
      &:hover{
        color:var(--cx-color-black);
      }
    }
  }
  @include media-breakpoint-down(sm) {
  
  .mat-mdc-dialog-container{
    padding: 10px;
    width: 90%;
    margin-left: 20px;
    margin-top: 20px;
    padding: 20px 5px 0 5px;
  }
  min-width: fit-content;
  margin:10px;

}
 .logoIcon{
    height:40px;
  } 

}

.borderColor{
  border-bottom:solid 1px var(--cx-lt-primary-container);
  width:90%;
 }