$font-family: "Roboto";
$font-url: "https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700" !default;

$font-xs: 0.75rem !default;
$font-s: 0.875rem !default;
$font-m: 1rem !default;
$font-l: 1.125rem !default;
$font-xl: 1.25rem !default;
$font-xxl: 1.375rem !default;
$font-xxxl: 1.5rem !default;
$font-1x: 2.125rem !default;
$font-2x: 2.25rem !default;
$font-3x: 3.75rem !default;
$font-4x: 5.625rem !default;

/* Line Height palette
    **************** */
$line-height-xs: 1rem !default;
$line-height-s: 1.125rem !default;
$line-height-m: 1.25rem !default;
$line-height-l: 1.375rem !default;
$line-height-xl: 1.5rem !default;
$line-height-xxl: 1.625rem !default;
$line-height-xxxl: 2.375rem !default;
$line-height-1x: 2.75rem !default;
$line-height-2x: 4rem !default;

/* Font weight variations
    ************************* */
$font-weight-regular: 500 !default;
$font-weight-medium: 700 !default;
$font-weight-bold: 900 !default;
