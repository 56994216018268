/* Border */
body {

  .btn {
    max-height: 48px;
    min-height: auto;
    //padding: 11px 12px;
  }

  .mat-mdc-list-base {
    --mdc-list-list-item-label-text-weight: 500;
    --mdc-list-list-item-label-text-color: var(--cx-color-primary);
    --mdc-list-list-item-focus-label-text-color: var(--cx-color-primary);
    --mdc-list-list-item-hover-label-text-color: var(--cx-color-primary);
  }

  .mat-mdc-radio-button.mat-accent {
    --mdc-radio-selected-focus-icon-color: var(--cx-color-black);
    --mdc-radio-selected-hover-icon-color: var(--cx-color-black);
    --mdc-radio-selected-icon-color: var(--cx-color-black);
    --mdc-radio-selected-pressed-icon-color: var(--cx-color-black);
    --mat-mdc-radio-checked-ripple-color:var(--cx-color-black);
  }


  .mat-form-field-appearance-outline .mat-form-field-outline {
    color: var(--cx-mat-outline);
  }

  .mat-form-field-appearance-outline .mat-mdc-text-field-wrapper {
    color: var(--cx-mat-outline);
  }

  /* Font color */
  input.mat-mdc-input-element {
    color: var(--cx-mat-input-color);
  }

  /* Thick Outline on Focus */
  .mat-form-field-appearance-outline.mat-focused {

     
    .mat-form-field-label,
    .mat-form-field-outline-thick {
      color: var(--cx-color-primary);
    }
  }

  .mat-mdc-input-element {
    caret-color: var(--cx-mat-caret);
  }

  .mat-mdc-button.mat-primary,
  .mat-mdc-icon-button.mat-primary,
  .mat-mdc-outlined-button.mat-primary {
    color: var(--link-text-color);
  }

  // Alert color
   
  .mat-mdc-form-field.mat-form-field-invalid .mat-form-field-label {
    color: var(--cx-warning-error);
  }

   
  .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
    color: var(--cx-warning-error);
  }

  .mat-mdc-form-field-error {
    color: var(--cx-warning-error);
  }

  .mat-mdc-fab.mat-primary,
  .mat-mdc-mini-fab.mat-primary {
    background-color: var(--btn-primary-bg-color);
  }

  .mat-primary .mat-mdc-option.mat-selected:not(.mat-option-disabled) {
    color: var(--cx-color-black);
  }

  .mat-mdc-form-field.mat-focused.mat-primary .mat-select-arrow {
    color: var(--cx-mat-outline);
  }

  /*** Mat Buttons Designs starts ***/

  .mat-mdc-unelevated-button,
  .mat-mdc-raised-button {
    padding: 10px 24px;
    @extend .t-label-l;
    cursor: pointer;

    &.mat-primary {
      color: var(--btn-primary-txt-color);
      background-color: var(--btn-primary-bg-color);
      border-color: var(--btn-primary-border-color);

      &:hover {
        opacity: 0.8;
      }

      &:focus,
      &:pressed {
        background-color: var(--btn-primary-focused-bg);
      }

      &:disabled {
        background-color: var(--btn-primary-disabled-bg);
        color: var(--btn-disabled-txt-clr);
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.38;
      }
    }

    &.mat-accent {
      color: var(--btn-secondary-txt-color);
      background-color: var(--btn-secondary-bg-color);
      border-color: var(--btn-secondary-border-color);

      &:hover {
        background-color: var(--btn-secondary-hover-bg);
      }

      &:focus,
      &:pressed {
        background-color: var(--btn-secondary-focused-bg);
      }

      &:disabled {
        background-color: var(--btn-secondary-disabled-bg);
        color: var(--btn-disabled-txt-clr);
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.38;
      }
    }

    &.mat-green {
      color: var(--cx-color-white);
      background-color: var(--cx-warning-success);
    }
    
    &.mat-lightBlue {
      background-color: var(--rewards-button-color);
    }

    &.mat-white {
      border: 1px solid;
      color: var(--btn-white-txt-color);
      background-color: var(--btn-white-bg-color);
      border-color: var(--btn-white-border-color);

      &:hover {
        background-color: var(--btn-white-hover-bg);
      }

      &:focus,
      &:pressed,
      &:focus-visible {
        background-color: var(--btn-white-focused-bg);
      }

      &:disabled {
        background-color: var(--btn-white-disabled-bg);
        color: var(--btn-disabled-txt-clr);
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.38;
      }
    }

    @include media-breakpoint-down(md) { 
      padding: 10px 12px;
    }
  }

  /*** Mat Buttons Designs ends ***/


  /*** Mat Dateicker stylings start***/

  .mat-calendar-body-selected {
    background-color: var(--cx-color-primary);
    color: var(--cx-color-white);
  }

  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: var(--cx-p20);
  }

  .mat-datepicker-toggle-active {
    color: var(--cx-color-primary);
  }

  /*** Mat Dateicker stylings end***/

  /*** Mat Checkbox Stylings start***/
  .mat-mdc-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: var(--cx-color-primary) !important; // important needed to override material default styles
  }

  .mat-checkbox-ripple .mat-ripple-element {
    background-color: var(--cx-color-primary) !important; // important needed to override material default styles
  }

  .mat-mdc-checkbox .mdc-checkbox--selected~.mdc-checkbox__ripple {
    background-color: var(--cx-color-primary) !important;
  }

  .mat-mdc-checkbox .mdc-checkbox {

    .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
    .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
    .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
      background-color: var(--cx-color-primary) !important;
      border-color: var(--cx-color-primary) !important;
    }
  }

  /*** Mat Checkbox Stylings ends***/

  //MAT Toggle button styles
   
  .mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: var(--cx-color-primary);
  }

   
  .mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: var(--cx-color-white);
  }

   
  .mat-slide-toggle-bar,
  .mat-slide-toggle-thumb {
    margin: 5px 2px;
  }

   
  .mat-slide-toggle-bar {
    height: 24px;
    width: 45px;
    border-radius: 13px;
  }

   
  .mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
    transform: translate3d(21px, 0, 0);
  }

   
  .mat-mdc-slide-toggle .mat-slide-toggle-ripple {
    height: auto;
  }

  .ngx-pagination .current {
    color: var(--pagination-color);
  }

  .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: var(--cx-warning-error);
    letter-spacing: normal;
  }

  .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading, 
  .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch, 
  .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
    color: var(--cx-warning-error);
    border-color: var(--cx-warning-error);
  }

  .mdc-text-field--focused.mdc-text-field--invalid {
    &:not(.mdc-text-field--disabled) {
      .mdc-notched-outline {
        .mdc-notched-outline {
          &__leading , &__notch , &__trailing {
            border-color: var(--cx-warning-error);
          }
        }
      }

      .mdc-floating-label {
        color: var(--cx-warning-error);
      }
    }
  }

  .mat-mdc-form-field-subscript-wrapper,
  .mat-mdc-form-field-bottom-align::before {
    line-height: var(--mdc-typography-caption-line-height, 25px);
  }

  .mat-mdc-form-field-required-marker {
    display: none;
  }

  .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label,
  .mdc-text-field--focused:not(.mdc-text-field--invalid) .mdc-floating-label {
    color: var(--cx-color-primary);
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid).mdc-text-field--focused  {
    .mdc-notched-outline {
      &__trailing , &__notch, &__leading {
        border-color: var(--cx-color-primary);
      }
    }
  }

  // .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
  //   .mdc-text-field__input , .mat-mdc-select {
  //     padding: 0 0 0 4px;
  //   }
  // }

  .mdc-button,
  // .mdc-floating-label,
  // .mat-mdc-form-field,
  // .mat-mdc-floating-label,
  .mat-mdc-form-field-subscript-wrapper,
  .mat-mdc-form-field-bottom-align::before {
    -webkit-font-smoothing: auto;
  }

  .mat-mdc-unelevated-button,
  .mat-mdc-raised-button,
  .mat-mdc-outlined-button {
    .mat-icon {
      margin-left: 0;
      margin-right: 0;
      font-size: 1.125rem;
      height: 24px;
      width: 24px;
    }
  }

  .mat-primary {
    .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) {
      .mdc-list-item__primary-text {
        color: inherit;
      }
    }
    .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
      display: none;
    }
  }

  .mat-mdc-form-field {
      --mat-mdc-form-field-floating-label-scale: 0.76;
  }

  //stepper styles 
  .mat-step-label-selected {
    font-weight: 500;
  }

  //mat-text-box
  .mat-mdc-form-field-input-control.mdc-text-field__input {
    @extend .t-body-l;
  }

  .mat-mdc-form-field-bottom-align {
    margin-top: -2px;
  }


  .mat-mdc-slide-toggle.mat-primary {
    --mdc-switch-selected-focus-state-layer-color:  var(--cx-color-primary);
    --mdc-switch-selected-handle-color:  var(--cx-color-primary);
    --mdc-switch-selected-hover-state-layer-color:  var(--cx-color-primary);
    --mdc-switch-selected-pressed-state-layer-color:  var(--cx-color-primary);
    --mdc-switch-selected-focus-handle-color: var(--cx-color-primary);
    --mdc-switch-selected-hover-handle-color: var(--cx-color-primary);
    --mdc-switch-selected-pressed-handle-color: var(--cx-color-primary);
    --mdc-switch-selected-focus-track-color: var(--cx-p20);
    --mdc-switch-selected-hover-track-color: var(--cx-p20);
    --mdc-switch-selected-pressed-track-color: var(--cx-p20);
    --mdc-switch-selected-track-color: var(--cx-p20);
    .mdc-switch {
      width: 40px;
      .mdc-switch__track {
        height: 17px;
        border-radius: 13px;
      }
    }
    label {
      margin-bottom: 0;
      @extend .t-label-l;
    }
  }

  
  .mat-mdc-checkbox {
    .mdc-form-field {
      label {
        padding: 0;
        margin: 0;
      }
    }
  }

  .mat-mdc-select-panel {
    .mat-pseudo-checkbox {
      display: none;
    }

    .mdc-list-item__primary-text {
      color: var(--cx-color-black);
    }
  }

  .mat-mdc-form-field-type-mat-input {
    width: 100%;
  }

  .mat-mdc-radio-button {
    .mdc-form-field {
      label {
        margin: 0;
      }
    }
  }

  .link-color {
    color: var(--cx-color-primary);
  }

  //Warning errors
  
  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label, 
  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label--float-above{
    color: var(--cx-color-primary);
  }

  .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label, 
  .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label--float-above{
    color: var(--cx-warning-error);
  }

  .mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow{
    color: var(--cx-warning-error);
  }

  .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label,
  .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-Label--float-above{
    color: var(--cx-warning-error);
  }

  .mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__input {
    caret-color: var(--cx-warning-error);
  }


  .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-text-field--invalid .mdc-notched-outline__notch {
    border-left: none;
  }
  .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-text-field--invalid .mdc-notched-outline__notch,
  .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-text-field--invalid .mdc-notched-outline__trailing,
  .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-text-field--invalid .mdc-notched-outline__leading {
    border-width: 2px;
  }
  
  .mat-mdc-form-field.mat-focused .mat-mdc-select-arrow {
    color: var(--mat-select-enabled-arrow-color);
  }

  --mdc-outlined-text-field-error-hover-label-text-color: var(--cx-warning-error);
  --mdc-outlined-text-field-error-hover-outline-color:  var(--cx-warning-error);

}
