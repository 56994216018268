body {
  .userid-preferences {
    &-header {
      background: var(--cx-p2-60);
    }

    &-heading {
      height: 92px;
    }

    &-main-block {
      background: var(--cx-color-white);
      box-shadow: 0 4px 4px rgba(0 0 0 / 25%);
    }

    &-block {
      box-shadow: 0;
    }

    &-sub {
      border: 0;
    }

    &-card {
      border: none;
    }

    .table thead tr th {
      text-transform: inherit;
      color: var(--cx-color-black);
    }

    .table thead tr th:first-of-type {
      width: 80%;
    }

    .table tbody tr {
      border: 1px solid var(--cx-table-border);
    }
  }

  .edit-email-modal {
    mat-dialog-container {
      padding-left: 0;
    }

    input {
      outline: none;
    }

    .password-dialog-password-validate {
      padding-left: 12px;
      font-size: 12px;
    }
  }
}
