.create-wro {

  .red-border {
    border: 1px solid var(--cx-warning-error) !important;
  }
  
  .wro-gap-22 {
    gap: 22px;

    table.bxd-portal-table-step5 {


      background-color: var(--cx-color-white);
      width: 100%;
      margin: 0;
      border-color: var(--cx-table-vertical-border);

      tr:first-of-type {
        border-bottom: 3px solid var(--cx-color-black);
        height: 35px;
      }

      th,
      td {
        border-right: 1px solid var(--cx-table-vertical-border);
        border-bottom: 1px solid var(--cx-table-vertical-border);
        text-align: center;
        vertical-align: middle;
        padding: 10px;
      }

      tr:first-of-type:not(.step5-table-header) {
        border-bottom: 1px solid var(--cx-table-vertical-border);
      }

      td {
        border-bottom: 1px solid var(--cx-table-vertical-border);
      }
    }
  }

  .fifthStepManufacturereAddress {
    padding: 0px 12px 0px 12px;
    gap: 1px; // In figma it was 10 but not look wise not good with 10px.
  }

  .addressGap {
    gap: 1px;
  }

  .wroHeader {
    margin: -15px;
    box-shadow: var(--common-box-shadow);
    background-color: var(--cx-color-white);
  }

  .transitIcon {
    gap: 7px;
  }

  .wro-header-button {
    gap: 11px;
    padding: 5px;

    .wroDiscard {
      height: 30px;
      color: var(--cx-warning-error);
      border: 1px solid var(--cx-warning-error);
      background-color: var(--cx-color-white);
      border-radius: 5px;
    }

    .finishLater {
      height: 30px;

      @include media-breakpoint-down(sm) {
        height: 35px;

        .mdc-button__label {
          padding: 10px;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      display: flex;
      flex-direction: column;
      align-items: end;
    }
  }

  .create-wro-steps {
    gap: 16px;

    div.eachStep {
      // overflow: auto;
      position: static;
      box-shadow: var(--common-box-shadow);
      gap: 10px;
      padding: 16px;
      border-radius: 7px;

      .stepsGap {
        gap: 22px;
      }

      &>div {
        .stepNumber {
          width: 40px;

          .stepCount {
            width: 30px;
            height: 30px;
            padding: 10px;
            border-radius: 100px;
            background-color: var(--cx-color-primary);
            color: var(--cx-color-white);
          }

          .done-icon {
            height: 30px;
            width: 30px;
          }
        }

        .wro-steps {
          width: calc(100% - 40px);
        }

      }
    }

    .inner__step-content {
      gap: 25px;

      &-width {
        max-width: max-content;
      }

      .step4-checkbox {
        input[type='checkbox'] {
          accent-color: var(--cx-color-primary);
        }
      }

      .saveAndContinue-btn {
        padding: 5px 12px;
      }

      .searchListInput {
        width: 90%;

        @include media-breakpoint-down(sm) {
          width: 90%;
        }
      }

      .vendorListDropdown {
        width: 308px;

        @include media-breakpoint-down(sm) {
          width: 90%;
        }
      }

      .adjust-gap{
        margin-bottom: -25px;
      }

      .search-results {
        position: relative;

        .searchedItem {
          position: absolute;
          top: 100%;
          left: 0;
          width: 90%;
          padding: 10px;
          z-index: 99;
          background-color: var(--cx-color-white);
          box-shadow: var(--common-box-shadow);
        }

        .btnWhite {
          background-color: var(--cx-color-white);
        }
      }

      .shipping-box {
        max-width: 266px;
        gap: 16px;

        .large-icon {
          width: 70px;
          height: 80px;
        }

        .shipping-para {
          background-color: var(--cx-drop-ship);
          padding: 10px;
        }
      }

      .step5Icon {
        height: 83px;
        width: 72px;
      }

      .highlight-address {
        max-width: 350px;
        background-color: var(--cx-drop-ship);
        padding: 10px;
      }

      .ship-address-icon {
        height: 24px;
        width: 24px;
        margin-right: 10px;
      }

      .calendar-wro {
        max-width: 300px;

        // max-height: 43px;
        .mat-mdc-text-field {
          background-color: var(--cx-color-white);
          border-radius: 5px;
          border-color: var(--cx-mat-outline);
        }

        .mat-mdc-form-field-hint {
          white-space: nowrap;
          color: var(--cx-mat-outline);
        }

        .mat-mdc-form-field-hint-wrapper {
          padding-left: 0px;
          padding-top: 4px;
        }
      }

      .wro-file-upload {
        .browse-btn {
          padding: 10px 24px;
          white-space: nowrap;
          border-radius: 5px;
          border: 1px solid var(--cx-color-primary);
          color: var(--cx-color-primary);
        }

        .table-responsive-sm {
          border: 1px solid var(--cx-color-black);
          /* Outer border */
          border-collapse: collapse;
          width: fit-content;
        }

        .file-uploaded-table {
          width: 100%;
          border: none;
        }

        .file-uploaded-table th,
        .file-uploaded-table td {
          text-align: left;
          padding: 8px;
          border-bottom: 1px solid var(--cx-table-border);
        }

        .even-row {
          background-color: var(--cx-table-row);
          /* Color for even rows */
        }

        .odd-row {
          background-color: var(--cx-color-white);
          /* Color for odd rows */
        }

        .error-message {
          color: var(--cx-warning-error);
        }
      }
      .importFile-hyperlink{
        color: var(--hyperlink-blue);
      }
      @include media-breakpoint-down(sm) {
        .flatFileHideInMobile {
          display: none;
        }
      }
    }

    .wro-steps {
      gap: 25px;
      width: 100%;
      &.wro-steps-first {
        gap:5px;
      }
      @extend .reusable-alert-box-style;

      .productWarn {
        @extend .searchListInput;
        .productWarnCloseIcon {
          width: 18px;
          height: 24px;
        }
      }

    }

    .cancelWroCreate {
      color: var(--cx-color-white);
      border: 1px solid var(--cx-warning-error);
      background-color: var(--cx-warning-error);
      border-radius: 5px;
      padding: 5px 12px;
    }

    .step-three {
      gap: 22px;
    }

    .customVendorListDropdown {
      margin-top: -20px;
      color: var(--cx-input-text-color);
    }
  }

  .searchedItem {
    overflow: auto;
    height: auto;
    max-height: 270px;
    box-shadow: var(--common-box-shadow);
    width: 100%;
    padding: 10px;

    .searchList {
      gap: 23px;
      border-bottom: 1px dashed var(--cx-color-black);
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      margin: 12px 0 12px 0;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--cx-table-scroll);
      border-radius: 10px;
    }

    @include media-breakpoint-down(sm) {
      &::-webkit-scrollbar {
        width: 3px;
      }
    }

  }

  .highlight-address {
    max-width: 350px;
    background-color: var(--cx-drop-ship);
    padding: 10px;

    .button-container {
      border: 0px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      /* Aligns items to the start (left) of the container */
      text-align: left;
      /* Ensures text inside the button is left-aligned */
      gap: 1px;

      /* Adjust the gap as needed */
      .button-color {
        color: var(--cx-edit-address);
        background-color: transparent;
      }
    }
  }

  .ship-address-icon {
    height: 24px;
    width: 24px;
    margin-right: 10px;
  }

  // step 5
  .gap6 {
    gap: 6px;
  }

  .gap10 {
    gap: 10px;
  }

  .gap16 {
    gap: 16px;
  }

  .gap5 {
    gap: 5px;
  }

  .editButton {
    color: var(--cx-color-primary) !important;
    border: 1px solid var(--cx-color-primary);
    width: 46px;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .editButtonMargin {
    margin-top: 36px;
  }

  .submit-warehouse {
    margin: 11px;
  }

  .subitFinalStepBtn {
    background-color: var(--cx-warning-success) !important;
  }

  .wroWarningDiv {
    margin-top: 30px;
  }

  .cancelSubmitWroDialog {
    border: 1px solid var(-cx-color-primary) !important;
    border-radius: 5px;
    padding: 10px 24px;
  }

  .confirmSubmiktWro {
    padding: 10px 24px;
  }

  .submitConfirmationWindowIcon {
    height: 79px;
    width: 79px;
  }

  .vendorChangeDialogCloseIcon {
    background-color: var(--cx-color-primary2);
    color: var(--cx-color-white);
    height: 30px;
    width: 30px;
    border-radius: 50%;
  }

  .cancelChangeVendor {
    color: var(--cx-color-primary);
    border: 1px solid var(--cx-color-primary);
    background-color: var(--cx-color-white);
    border-radius: 5px;
    padding: 10px 24px;
  }

  .hide-default-padding {
    padding: 0px !important;
  }
}

.tableWro {
  overflow: auto;

  .wroStepOneInventoryAnchor {
    color: var(--hyperlink-blue);
    text-decoration: underline;
    cursor: pointer;
  }

  table {
    tbody {
      tr:first-of-type {
        border-bottom: 1px solid var(--cx-table-vertical-border) !important;
      }
    }

    tr {
      background-color: var(--cx-color-white) !important;

      th:first-of-type {
        min-width: 110px;
        width: 130px;
        max-width: 250px;
      }

      th:nth-of-type(2) {
        min-width: 110px;
        width: 130px;
      }

      th:nth-of-type(3) {
        min-width: 250px;
        width: 350px;
      }

      th:nth-of-type(4) {
        min-width: 94px;
        width: 100px;
      }

      th:nth-of-type(5) {
        min-width: 110px;
        width: 268px;
      }

      th:nth-of-type(6) {
        min-width: 50px;
        width: 50px;
      }

      th:not(:first-of-type):not(:nth-of-type(2)):not(:nth-of-type(3)):not( :nth-of-type(4)):not(:nth-of-type(5)):not(:nth-of-type(6)) {
        min-width: 52px;
        max-width: 52px;
      }

      td {
        border-bottom: 1px solid var(--cx-table-vertical-border);
      }
    }

    .qtySendingInput {
      border: 1px solid var(--cx-p20);
      border-radius: 5px;
      padding: 10px;
      height: 27px;
    }

    .removeIcon {
      margin-top: 2px;
    }

    .addRemoveLotButtonCustom {
      background: var(--cx-color-white);
      height: 24px;
      padding: 0;
    }
  }

  .quantityWidth {
    .qty-sending__input {
      width: auto;

      input {
        width: 100%;
      }

      input[placeholder="-"] {
        text-align: center;
      }

      .qtySendingInputWidth {
        min-width: 30px;
      }
    }
  }

  .lotExpiration {
    .lotExpiration__input {
      input {
        width: 100%;
        text-align: center;
      }

      .lotNumberInputWidth {
        min-width: 30px;
      }
    }
  }

  .addLotCursorPinter {
    cursor: pointer;
  }
}


// Colos were getting fedded.
.BoxoutPortalHomeLeftNavBar {
  app-portal-bxd-drawer {
    mat-drawer-container {
      @include media-breakpoint-down(sm) {
        .mat-drawer-content {
          overflow: hidden;
        }
      }
    }
  }
}