.edit-order {
    .order-detail-info {
        background: var(--cx-color-white);
        box-shadow: var(--common-box-shadow);
    }

    .quick-order-heading-section {
        background: var(--cx-color-white);
        box-shadow: var(--common-box-shadow);
    }

    .set-color {
        color: var(--cx-color-primary);
    }

    .quick-orders-items {
        display: flex;
        flex-direction: row;
    }
    
    .mat-mdc-text-field-wrapper {
        border-radius: 4px;
        padding-bottom: 0 !important;
    }

    .order-items {
        background-color: var(--cx-color-white);
        width: 100%;
        .mat-mdc-form-field {
            width: 100%;
            .mat-mdc-text-field-wrapper {
                padding-bottom: 0 !important;
                .mat-mdc-form-field-infix {
                    height: 45px;
                    min-height: auto;
                  }
            }
        }
    }

    .order-qtys {
        background-color: var(--cx-color-white);

        .mat-mdc-form-field {
            width: 56px;
            .mat-mdc-text-field-wrapper {
                padding-bottom: 0 !important;
                .mat-mdc-form-field-infix {
                  height: 45px;
                  min-height: auto;
                }
              }
        }
    }

    @media (max-width: 800px) {
        .quick-order-main-section {
            flex-direction: column;
        }
    }

    .set-color {
        color: var(--cx-color-primary);
    }

    .exclamation-icon {
        color: var(--exclamation-icon-color);
    }

    .order-opacity {
        opacity: 0.6;
    }

    &-add-to-order.mdc-button.mdc-button--unelevated {
        max-height: 42px;
        padding: 0;
        width: 125px;
    }

    // Input Section Styling
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
}

.order-approval-modal {
    &-input .mat-mdc-form-field {
        .mat-mdc-text-field-wrapper {
            height: 91px;
        }
    }

    &-cancel-button.mat-mdc-unelevated-button {
        border: 1px solid var(--btn-primary-border-color);
    }

    &-width {
        max-width: 60%;
    }

    &-close.mat-mdc-unelevated-button.mat-primary {
        border-radius: 14px;
    }
}