body {
  .register-error-dialog {
    @include media-breakpoint-up(lg) {
      max-width: 500px;
    }
    @include media-breakpoint-down(lg) {
      max-width: 95vw !important;
      height: 95vh;
    }
  }
  .register-form {
     
    .mat-slide-toggle-bar,
    .mat-slide-toggle-thumb {
      margin: 7px 2px;
    }
    .mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-bar {
      background-color: var(--cx-color-primary);
    }
     
    .mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-thumb {
      background-color: var(--cx-color-white);
    }
     
    .mat-slide-toggle-bar {
      height: 32px;
      width: 66px;
      border-radius: 18px;
    }
     
    .mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
      transform: translate3d(35px, 0, 0);
    }
     
    .mat-mdc-slide-toggle .mat-slide-toggle-ripple {
      height: auto;
    }
     
    .mat-slide-toggle-thumb {
      height: 24px;
      width: 24px;
      border-radius: 50%;
    }
  }
}
