body {
  .btn:hover {
    color: var(--cx-color-white);
  }

  .breadcrumb {
    margin-left: 46px;
  }

  .payment-types {
    &-header {
      background: var(--cx-p2-60);
    }

    &-editButton {
      width: 90px;
      height: 26px;
      background-color: var(--edit-payment-btn);
      color: var(--cx-color-white);
      border-radius: 4px;
    }

    &-addButton {
      height: 42px;
      background-color: var(--create-bus-btn);
      color: var(--cx-color-white);
      padding: 10px 24px;
      border-radius: 5px;
    }

    &-main-block {
      background: var(--cx-color-white);
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

      .card {
        border: 0;

        .payment-types-header {
          border-radius: 5px 5px 0 0;

        }

        // svgs added newly .adjusting width and height as per design
        .payment-types-block {
          
          .mat-icon {
            width: 50px;
            height: 30px;
          }
        }
      }
    }

    &-buttonToggle {
      min-width: 100px;
    }

    &-block {
      box-shadow: 0;
    }

    &-sub {
      border: 0;
    }

    .mat-mdc-slide-toggle.mat-disabled {
      opacity: 1;
    }
  
  


  @include media-breakpoint-down(md) {
    .payment-types-addMobileButton {
      background-color: var(--cx-color-primary);
      color: var(--cx-color-white);
      padding: 10px 20px;
      height: 40px;
    }

    .payment-types-buttonToggle {
      width: auto;
    }

    .container {
      max-width: 100%;
      padding: 0;
    }

    .card-blocks {
       .card {
          border: 1px solid rgba(0, 0, 0, 0.125);
          border-radius: 5px 5px 0px 0px;
       }
    }
  }

  @include media-breakpoint-down(sm) {
    .AccountPageTemplate {
      cx-page-slot.BodyContent {
        padding: 0;
      }
    }
    
    .container {
      max-width: 100%;
      padding: 0;
    }
  }
}
}