.secondToolbar {
    position: fixed;
    background: #eee;
    // top: 0;
    height: 0;
    opacity: 0;
    // transition: opacity .25s ease-out, height .15s ease;
    cursor: default;
    user-select: none;
  }

  .text-underline {
    text-decoration: underline;
  }

.header-sticky{
    z-index: 1000;
    width: 100%;
    display: none;
    align-items: center;
    padding: 0 calc((100% - var(--xl-max-width)) / 2);
    height: 45px;
    @include media-breakpoint-down(lg) {
        padding: 0 24px;
    }
    @include media-breakpoint-down(md) {
        padding: 0;
    }
    background-color: var(--cx-color-primary);
    height: 45px;
    .header-search-icon {
        margin-top: 9px;
        position: absolute;
        right: 70px;
    }
    .header-search {
        position: relative;
        height: 43px;
        border-bottom: 1px solid var(--cx-dark-grey);
        .header-searchbar {
            border-radius: 5px;
            padding: 8px;
            border: 1px solid var(--cx-color-primary);
            width: 299px;
            margin-left: 120px;
            margin-right: 65px;
        }
    }
    .header-main-right-section .mat-icon{
        margin-right: 24px;
    }
    .header-hamburger {
        border-right: 1px solid var(--cx-color-white);
        height: inherit;
        .hamburger-pipe{
            margin-top: 10px;
        }
    }
    .header-main-logo {
        img {
            height: 35px;
            width: 130px;
        }
    }
    @include media-breakpoint-down(md) {
        .header-search .header-searchbar{
            width: 204px;
        }
    }
    @include media-breakpoint-down(sm) {
        .header-search .header-searchbar{
            width: 201px;
            margin-left: 0;
            margin-right: 0;
        }
        .header-search-icon {
            right: 5px;
        }
        .header-main-right-section{
            margin-left: 50px;
            .mat-icon{
            height: 24px;
            width: 24px;
            margin-right: 16px;
            }
            a {
                min-width: auto;
                min-height: auto;
            }
        }
        .header-hamburger {
            border-right: none;
        }
    }
    @include media-breakpoint-down(xs) {
        .header-main-right-section{
            margin-left: 15px;
            .mat-icon{
                margin-right: 12px;
            }
        }
    }
    &.sticky {
        height: 45px;
        opacity: 1;
        display: flex;
        transition: opacity .25s ease-out, height .15s ease;
        width: 100%;
        top: 0;
      }
    
    .mat-badge-warn .mat-badge-content {
        color: var(--cx-color-black);
    }
}

// custom scss starts //
.header-common {
    display: flex;
    align-items: center;
    padding: 0 calc((100% - var(--xl-max-width)) / 2);
    height: 45px;
    @include media-breakpoint-down(lg) {
        padding: 0 24px;
    }
    @include media-breakpoint-down(md) {
        padding: 0;
    }
}

body {
    header {
        background: var(--cx-color-white);
        cx-page-slot{
            width: 100%;
        }
        .header {
            padding: 0;
            padding-top: 0 !important; // THis is to fix content page extra padding issue
            max-width: 100%;
            color: var(--link-text-color);
            .SiteLinkFlexSlot {
                background-color: var(--cx-header-sitelink-bg);
                @extend .header-common;
                cx-link {
                    padding: 0;
                    a {
                       margin: 0;
                    }
                    &:last-child {
                        a {
                            margin-right: 0 !important; // overriding bootstarp classes with important
                            padding-right: 0 !important; // overriding bootstarp classes with important
                        }
                    }
                }
                cx-generic {
                    &-link {
                        a {
                            @extend .t-title-m;
                            @extend .px-3;
                            @extend .mr-1;
                            color: var(--cx-header-sitelink);
                        }
                    }
                }

                .header-sitelinks {
                    &-btn {
                        button {
                            line-height: 20px;
                            padding: 5px 12px;
                            svg {
                                height: 14px;
                                width: 14px;
                                margin-right: 10px
                            }
                            
                            background-color: var(--header-sitelinks-signin);
                            &:hover {
                                background-color: var(--btn-secondary-bg-color);
                            }
                        }
                    }
                    &-signIn-profileLogin{
                                height: 22px !important;
                                width: 20px !important;
                                margin-right: 10px
                    }
                    &-signin {
                        color: var(--cx-p2-dark-grey);
                    }
                }

                .header-profilesitelinks {
                    &-btn {
                        button {
                            line-height: 20px;
                            padding: 5px 12px;
                            svg {
                                height: 22px;
                                width: 20px;
                                margin-right: 10px
                            }
                            
                            background-color: var(--btn-signIn-color);
                            &:hover {
                                background-color: var(--btn-signIn-color);
                            }
                        }
                    }
                    &-signin {
                        color: var(--cx-p2-dark-grey);
                    }
                }
            }
            .navigation-section {
                background-color: var(--navbar-bg);
                flex-wrap: nowrap;
                @extend .header-common;
                padding: 7px calc((100% - var(--xl-max-width)) / 2) 0;
                @include media-breakpoint-down(lg) {
                    padding: 0 24px 0 0;
                }
                min-height: 45px;
                height: auto;
            }
            .NavigationBar {
                // background-color: var(--cx-color-primary);
                flex-wrap: nowrap;
                min-height: auto;
                line-height: normal;
                @include media-breakpoint-down(lg) {
                    padding: 7px 24px 0;
                }
                @include media-breakpoint-down(md) {
                    padding: 0;
                }

                height: auto;

                .live-chat-link, .support-num-link {
                    border-right: 2px solid var(--cx-color-white);
                    p {
                        padding: 0;
                        margin: 0;
                    }
                    a {
                        color: var(--cx-color-white);
                    }
                    &:hover {
                        color: var(--link-text-color-inverse);
                    }
                }

                @include media-breakpoint-down(lg) {
                    .support-num-link {
                        border: 0;
                    }
                }
            }
            .ProfileTopHeaderSlot {
                .profile-header {
                    @extend .header-common;
                    .header-rep {
                        &-name {
                            color: var(--cx-p2-light-grey);
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            max-width: 250px; 
                        }
                    }
                    .header-user {
                        cx-generic-link, .account-label {
                            color: var(--cx-60);
                             a {
                                min-height: auto;
                             }
                        }
                        &-data, .unit-name {
                            color: var(--cx-color-primary);
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            max-width: 250px; 
                        }
                        .account-num{
                            color: var(--cx-color-primary);
                        }
                        .vertical-line {
                            border-left: 2px solid var(--cx-color-secondary);
                            height: 20px;
                            margin: 0 10px;
                        }
                        &-account {
                            white-space: nowrap;
                        }

                    }
                }
            }
            .HeaderPlaceholderFlexSlot {
                .header-main-logo {
                    img {
                        height: 45px;
                        max-width: 250px;
                        @include media-breakpoint-down(md) {
                            height: 35px;
                            width: 130px;
                            margin-top: -10px;
                        }
                    }
                }
                .header-searchbar {
                    height: 50px;
                    width: 320px;
                }
                .header-main {
                    &-dropdowns {
                        .account , .quick-order {
                            color: var(--cx-color-primary);
                        }
                    }
                    &-minicart {
                        .mat-mdc-unelevated-button.mat-primary {
                            padding: 10px 5px 10px 10px;
                        }
                        svg path {
                            fill: var(--cx-color-white);
                        }
                        .mat-badge-warn .mat-badge-content {
                            color: var(--cx-color-black);
                        }
                    }
                    .header-hamburger {
                        border-right: 1px solid var(--cx-color-white);
                        height: inherit;
                        .hamburger-pipe{
                            margin-top: 10px;
                        }
                    }

                    @include media-breakpoint-down(md) {
                        align-items: baseline;
                    }
                }

            }
            &-main {
                @extend .header-common;
                height: 80px;
                @include media-breakpoint-down(md) {
                    background-color: var(--cx-color-primary);
                    height: 45px;
                }
            }
            &-search{
                position: relative;
            }

            .SiteLogo {
                width: auto;
            }

        }

        @include media-breakpoint-down(md) {
            cx-page-slot {
                width: 100%;
            }
            .header{
                .header-search-icon {
                    margin-top: 5px;
                }
            }
        }

        .hide-logo {
            display: none;
        }
        .header-searchbar {
            border-radius: 5px;
            padding: 14px;
            border: 1px solid var(--cx-color-primary);
        }
        .header-search-icon {
            margin-top: 12px;
            position: absolute;
            right: 13px;
        }
    }

    @include media-breakpoint-down(md) {
        .header-links , .mobile-navigation {
            .mat-mdc-dialog-container {
                padding: 0;
                width: 285px;
                .mat-mdc-unelevated-button.mat-primary {
                    background-color: var(--navbar-bg);
                    padding: 5px;
                    &.sitelinks-close {
                        min-width: 25px;
                        padding: 0;
                        span {
                            color: var(--cx-color-white);
                        }
                    }
                    &.cdk-program-focused , &.cdk-focused {
                        color: var(--cx-color-white);
                        background-color: var(--cx-color-primary2);
                    }
                }
                .mobile-sitelinks {
                    &-action {
                        //margin: 15px 10px 20px;
                        .action {
                            &-section {
                                height: 40px;
                            }
                            &-icon {
                                width: 40px;
                            }
                            &-text {
                                line-height: 24px;
                                color: var(--cx-color-primary);
                                text-decoration: underline;
                            }
                        }
                        .signin {
                            background-color: var(--cx-p10);
                            margin-bottom: 10px;
                            .action-icon {
                                height: 40px;
                                background-color: var(--cx-color-primary);
                            }
                        }
                        .register {
                            background-color: var(--cx-p2-10);
                            .action-icon {
                                height: 40px;
                                background-color: var(--side-nav-border-bottom);
                            }
                        }
                        .header-sitelinks {
                            height: 40px;
                            border-top: 1px solid var(--side-nav-border-bottom);
                        }
                        .about-boxout {
                            height: 45px;
                            background-color: var(--cx-p10);
                        }
                        &.link-section {
                            border-bottom: 4px solid var(--cx-color-primary);
                        }
                    }
                }
                .mdc-dialog__surface {
                    padding: 0;
                }
            }
        }

        .header-links {
            height: 100%;
        }

        .mobile-navigation {
            @include media-breakpoint-down(md) {
                .mat-mdc-dialog-container {
                    padding: 0;
                    width: 275px;
                    height: 100vh;
                    .mat-mdc-unelevated-button.mat-primary {
                        background-color: var(--navbar-bg);
                        padding: 5px;
                    }
                    .mobile-sitelinks-main {
                        border-bottom: 1px solid var(--side-nav-border-bottom);
                    }
                    nav {
                        width: 100%;
                    }
                }
            }
        }

        .MobileSearchBarSlot {
            .header-search {
                height: 55px;
                border-bottom: 1px solid var(--cx-dark-grey);
                box-shadow: 0 1px 2px rgb(0 0 0 / 25%);
                .header-searchbar {
                    width: 100%;
                    height: 40px;
                    padding: 8px 14px;
                    background-size: auto 80%;
                }
            }

        }
    }

    //BCGI2-287: Restricting Global Message component
    cx-global-message{
        display: none;
    }
}

.search-backdrop{
    position: absolute;
     top: 170px;
     bottom: 0;
     left: 0;
     right: 0;
     z-index: 1000;
     pointer-events: auto;
     -webkit-tap-highlight-color: transparent;
     transition: opacity .4s cubic-bezier(.25,.8,.25,1);
     opacity:1;
     background: #00000052;
     &.asm-user-active {
      top: 205px;
     }
  }
    .sticky-search-backdrop{
        position: fixed;
        top: 45px;
    }
  .bg-custom {
    position: relative;
    min-height: 40vh;
    .custom-backdrop{
        @include media-breakpoint-up(lg) {
            &.show-backdrop {
                display: block;
            }
            &.hide-backdrop {
                display: none;
            }
            // adding the common backdrop styles used for adding custom Backdrop.
            // Not keeping a reusable class as unable to use @extend inside media queries
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1000;
            pointer-events: auto;
            -webkit-tap-highlight-color: transparent;
            transition: opacity .4s cubic-bezier(.25,.8,.25,1);
            opacity:1;
            background: #00000052;
        }
    }
  }
// custom scss ends //

// Adding global styles for mat modal close button
.mat-mdc-dialog-container {
    .mat-mdc-unelevated-button.mat-primary.modal-close {
        background-color: var(--cx-modal-close);
        padding: 5px;
        &.modal-close {
            min-width: 25px;
            padding: 2px;
            height: 28px;
            span {
                color: var(--cx-color-white);
            }
        }
        &.cdk-program-focused , &.cdk-focused {
            color: var(--cx-color-white);
            background-color: var(--cx-modal-close);
        }
    }
}

@include media-breakpoint-down(sm) {
    .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
        right: -7px;
        top: -7px;
        margin: 0px;
        left: 9px;
        bottom: 9px;
    }
}

