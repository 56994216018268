$cell-border: solid 2px var(--cx-p40);
.account-table {
  width: 100%;
  border-collapse: collapse;
  th {
    background-color: var(--sr-nav);
  }
  th, td {
    text-align: center;
    border-right: $cell-border;
    border-left: $cell-border;
    overflow: hidden;
    white-space: nowrap;
  }
  th:first-child, td:first-child {
    border-left: none; /* Removes left border for first column */
  }
  th:last-child, td:last-child {
    border-right: none; /* Removes right border for last column */
  }
}
.business-account {
  @extend .account-table;
  .vendor{
    width: 10%;
  }
  .description {
    width: 45%;
  }
}
.customer-account {
  @extend .account-table;
  .name, .type {
    width: 37%;
  }
  .account, .created-date {
    width: 10%;
  }
}
.users-account {
  @extend .account-table;
  .created-date {
    width: 30%;
  }
  .description {
    width: 30%;
  }
  .user {
    width: 30%;
  }
}

table{
  position: relative;
}