.reusuable__filter-container {
  gap: 6px;

  .section-bottom.sortRight {
    gap: 16px;
  }

  .searchedChip {
    gap: 16px;
    background-color: var(--chip-background);
    .chipRemoveIcon {
      width: 8px;
      margin-top: 2px;
    }
    .searchedTextPadding {
      padding: 2px 0px 2px 3px;
    }
  }

  .filter__item-section {
    position: relative;

    .section-bottom {
      .width-fit-content{
        width: fit-content;
      }
      .calender {
        // height: 20px;
        gap: 9px;
        .date-section {
          padding: 0 6px;
          background-color: var(--bxd-calender-date-bg);
        }

        .date-last-days-section {
          button.last-days-btn {
            padding: 0 6px;
            border-radius: 0;
            height: 20px !important;
            background-color: var(--bxd-calender-date-bg);
            color: var(--cx-color-black);
          }
        }

        .date-range {
          mat-date-range-input {
            width: 130px;
            margin-right: 10px;
          }
        }
        mat-datepicker-toggle {
          button {
            background-color: var(--cx-color-white);
            outline: none;
            padding: 0;
            margin: 0;
            height: 24px;
            width: 24px;

            .mat-mdc-button-touch-target {
              height: 24px;
              width: 24px;
            }

            span {
              color: var(--cx-color-black);
            }

            mat-icon {
              height: 24px;
              width: 24px;
            }
          }
        }
      }
      @include media-breakpoint-down(sm) {
        // flex-direction: column;

        mat-form-field.view-select {
          width: 85%;
          align-self: flex-start;
        }
        
        // .expand-icon {
        //   position: absolute;
        //   right: 0;
        //   top: -12px;
        // }
        
        .calender {
          width: 100%;
        }

        .responsiveSM {
          display: none;
        }
      }
    }
  }

  .customFilterBTNStyle {
    background-color: var(--cx-warning-success);
    align-items: center;
    color: var(--cx-color-white);
    border-radius: 4px;
    padding: 5px 12px;
    gap: 10px;

    mat-icon {
      margin: 0px !important;
    }
  }
  
  .section-mid {
    @include media-breakpoint-down(sm) {
      justify-content: start !important;
      flex-wrap: wrap;
      .responsiveSM {
        display: none;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .filter__item-section {
      flex-wrap: wrap;
      
      .expand-icon {
        position: relative;
      }
    }

    .hide-mobile{
      display: none !important;
    }
  }
  
  @include media-breakpoint-up(md) {
    .hide-laptop{
      display: none;
    }
  }
}
