body {
    .manage-create-user {
        &-header {
            background: var(--cx-p2-60);
        }

        &-heading {
            height: 92px;
        }

        &-main-block {
            background: var(--cx-color-white);
            box-shadow: var(--common-box-shadow);
        }

        &-block {
            box-shadow: 0;
        }

        &-sub {
            border: 0;
        }

        &-card {
            border: none;
        }

        &-cancel.mat-mdc-unelevated-button:not(:disabled) {
            color: var(--cx-color-primary);
        }

        &-save.mat-mdc-unelevated-button:not(:disabled) {
            color: var(--cx-color-white);
            background: var(--create-bus-btn);
        }

        &-table {
            overflow-x: auto;
            display: block;
        }

        // Input Section Styling
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    
        /* Firefox */
        input[type=number] {
            -moz-appearance: textfield;
        }
        &-spend-amount mat-mdc-form-field {
            width: 300px;
        }

        &-spend-per-order mat-mdc-form-field {
            width: 245px;
        }

        &-purchase-restriction-box {
            border: 2px solid var(--cx-color-primary2);
            max-width: 662px;
        }

        &-search {
            position: relative;
        }

        &-searchbar {
            border-radius: 5px;
            padding: 8px;
            border: 1px solid var(--cx-color-primary);
            width: 200px;
            height: 39px;
            margin-right: 65px;
        }

        &-search-icon {
            top: 8px;
            position: absolute;
            left: 170px;
        }

        &-closeIcon {
            width: 20px;
            top: 8px;
            position: absolute;
            left: 145px;
        }

        &-search-box {
            padding: 10px;
            gap: 16px;
            width: 227px;
            /* Primary */
            border: 0.5px solid var(--cx-color-primary2);
        }

        .checkbox-position {
            position: unset;
        }

        //scroll for search text
        .scroll {
            max-height: 226px;
            overflow: auto;
            width: 207px;

            &::-webkit-scrollbar {
                width: 14px;
                height: 154px;
                overflow-y: scroll;
                background: var(--cx-table-scroll);
                box-shadow: inset 0 0 4px var(--cx-p2-10);
            }

            &::-webkit-scrollbar-thumb {
                background: var(---cx-table-scroll);
                border-radius: 10px;
            }

            input[type=checkbox]:checked {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                /* Styling checkbox */
                background: no-repeat center;
                background-size: 22px 22px;
                background-image: url(../../../assets/check-icon.svg)
            }
        }

        .switch-account-dialog-field-alignment {
            .switch-account-dialog-tab-padding {
                width: 400px;
            }
        }
    }
}