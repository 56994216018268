.sign-in-security-container {
  background: var(--cx-color-white);
  box-shadow: 0 4px 4px var(--cx-box-shadow);


/* style for SignInAndSecurityComponent */

.password-validation-list {
  padding-left: 20px;
}

.sign-in-security-dialog {
  &-input-outline {
    &:focus {
      outline: none;
    }
  }
}


  .sign-in-input-display {
    & mat-mdc-form-field {
      display: block;
    }
  }

  .security-text-display {
    color: var(--cx-warning-success);

    &-list {
      // needed for aligning success tick
      margin-left: -16px;
    }
  }

  .mat-error-text {
    color: var(--cx-warning-error);
    font-size: 12px;
  }


.error-msg {
  padding-left: 11px;

  // needed for aligning error text from backend
  margin-top: -17px;
}

.sign-in-submit-button {
  & button {
    background-color: var(--create-bus-btn);
    color: var(--cx-color-white);
  }
}



.phone-input-or-mobile-input-create-new-user{
  .phone-order-info{
    width: 314px;
  }
  mat-mdc-form-field {
    width: 384px;
  }
}

@include media-breakpoint-down(md){
  .phone-input-or-mobile-input-create-new-user{
    .phone-order-info{
      margin-bottom: 12px;
    }
    mat-mdc-form-field {
      width: 100%;
    }
  }
}

.create-new-user-security-cred-description{
  width: 252px;
  height: 36px;
}

.create-user-green-tick{
  height: 18px;
  width: 18px;
}

.select-role{
  .mat-form-field-appearance-outline .mat-form-field-prefix{
    top:0.075rem;
  }
}

}

.sign-in-security-container.reusable-component{
  box-shadow: none;
}