body{
.shiptobusiness-account-dialogs-style{
  .mat-mdc-dialog-container{
    border-radius: 0;
    padding:0 0;
    @include media-breakpoint-down(md) {  
      max-width: 100% !important;
      height: 100% !important;
      width: 100% !important; 
      padding:24px;
     }
  }
  .create-business-account {
    &-input-outline {
      &:focus {
        outline: none;
      }
    }
  }
  .create-business-account-input-display {
       mat-mdc-form-field {
        display: block;
      }
    }
  .create-business-account-submit-button {
      & button {
        background-color: var(--cx-color-primary);
        color: var(--cx-color-white);
      }
    }

    .phone-input-or-mobile-input{
      .phone-order-info{
        width: 314px;
      }
      mat-mdc-form-field {
        width: 366px;
      }
    }

    @include media-breakpoint-down(md){
      .phone-input-or-mobile-input{
        .phone-order-info{
          margin-bottom: 12px;
        }
        mat-mdc-form-field {
          width: 100%;
        }
      }
    }

    .edit-ship-to-toggle-ship{
      width: 75px;
      overflow-wrap: break-word;
    }

    .edit-ship-to-toggle-bill{
      width: 52px;
      overflow-wrap: break-word;
    }
  }
}