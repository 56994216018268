.register-user-dialog {
  .mat-mdc-dialog-container {
    padding: 0px !important;
    overflow: hidden;
  }

  // @include media-breakpoint-up(lg) {
  //   width: 550px;
  // }
  @include media-breakpoint-down(up) {
    // min-width: 540px;
  }

  .logoIcon {
    height: 40px;
  }

  .borderColor {
    border-bottom: solid 1px var(--cx-lt-primary-container);
    width: 90%;
    max-width: 300px;
  }

  .recaptcha {
    padding-bottom: 125px;
  }

  .mat-icon {
    height: 26px;
    width: 26px;
  }

  .t-tile-m {
    font-weight: $font-weight-medium;
  }

  .text-align-center {
    text-align: center;
  }

  .customer-modal-textarea-field {
    .mat-mdc-text-field-wrapper {
      margin: 0 !important;

      textarea {
        min-height: 150px;
      }
    }
  }
}

.customer-modal {
  .mat-mdc-dialog-container {
    padding: 0px !important;
    overflow: hidden;
  }

  @include media-breakpoint-up(lg) {
    width: 550px;
  }

  @include media-breakpoint-down(lg) {
    max-width: 95vw !important;
    height: 95vh;
  }
}