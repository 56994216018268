.spinner {
  animation-name: spinning;
  animation-duration: 1.8s;
  animation-iteration-count: infinite;

  animation-timing-function: linear;
  background: transparent;
  height:100px;
  @include media-breakpoint-down(md) {
  height:80px;
  }
  @include media-breakpoint-down(sm) {
    height:65px;
    }
  // opacity: 0.8;
  padding: 0;
  position: absolute;
  z-index: 99999;
  @keyframes spinning {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

#pause {
  display: block;
  background: rgba(0, 0, 0, 0.22) no-repeat 0 0;
  width: 100%;
  height: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  // z-index: 20000;
}

.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2000;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.2);
}

.loaderWithWidth
{
  position: absolute;
  width: 75%;
  height: 100%;
  z-index: 2;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.2);
}

.loadImg {
  top: 50%;
  left: 45%;
  width: 100px;
  height: 100px;
  position: absolute;
  background: transparent;
  animation-name: spinning;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  /* linear | ease | ease-in | ease-out | ease-in-out */
  animation-timing-function: linear;
  @keyframes spinning {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}


ng-http-loader {
  #spinner {
    background: transparent !important;
    opacity: 1;
  }
}