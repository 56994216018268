.portal-bxd-orders{
  .gap-6{
    gap: 6px;
    display: grid;
  }

  .gap-10{
    gap: 10px;
    display: grid;
  }

  .blue-highlight {
    color: var(--status-blue-2);
  }

  .dashboard{
    border-radius: 7px;
    padding: 12px;
    box-shadow: var(--common-box-shadow);
    background: var(--cx-color-white);

    .totalUnitsNumber{
      color: var(--cx-color-black);
    }

    .ball{
      border-radius: 20px;
      color: var(--cx-color-white);
      padding: 2px 6px;
    }

    .box{
      border: 1px solid var(--cx-p2-20);
      border-radius: 6px;
      padding: 12px;
    }

    .partially-fulfilled{
      background-color:var(--status-orange);
    }

    .released-to-warehouse{
      background-color: var(--status-blue-2);
    }

    p.circle-text  {
      color: var(--cx-color-white);
    }
  }

  .order-table__container {
    overflow-x: auto;
    box-shadow: var(--common-box-shadow);

    table {
      width: 100%;
      tr {
        th:first-of-type {
          width: 60px;
          min-width: 50px;
        }
        th:nth-of-type(2), th:nth-of-type(3) {
          width: 110px;
          min-width: 90px;
        }
        th:nth-of-type(4), th:nth-of-type(5) {
          width: 150px;
          min-width: 110px;
        }
        th:nth-of-type(6) {
          width: 100px;
          min-width: 90px;
        }
        th:nth-of-type(7) {
          width: 140px;
          min-width: 110px;
        }
        th:nth-of-type(8) {
          width: 60px;
          min-width: 50px;
        }
        th:nth-of-type(9) {
          width: 110px;
          min-width: 90px;
        }
        th:nth-of-type(10) {
          width: 220px;
          min-width: 190px;
        }
        th:nth-of-type(11), th:nth-of-type(12) {
          width: 60px;
          min-width: 50px;
        }
        th:nth-of-type(13) {
          width: 90px;
          min-width: 80px;
        }
        th:nth-of-type(14) {
          width: 80px;
          min-width: 70px;
        }
        th:nth-of-type(15) {
          width: 130px;
          min-width: 110px;
        }
        th:last-of-type {
          width: 100px;
          min-width: 90px;
        }

      }

      @include media-breakpoint-down(md) {
        width: fit-content;
      }
    }
  }

  .order-table-2a__container {
    overflow-x: auto;
    box-shadow: var(--common-box-shadow);

    & > table {
      overflow: auto;

      @include media-breakpoint-down(md) {
        width: fit-content;
      }
    }
  }

  table {
    & .table-sort__column {
      mat-icon {
        height: 24px;
        width: 16px;
        transition: transform 0.3s ease;
        &.rotated {
          transform: rotate(180deg);
        }
      }
    }

    & .link-highlight {
      .orders-hyperlink {
        color: var(--hyperlink-blue) !important;
        text-decoration: underline !important;
      }
    }
    .tracking-hyperlink{
      color: var(--hyperlink-blue);
    }
  }

  .order-table{

    @include media-breakpoint-down(md) {
      display: block;
    }
  }

  .disp-block{
    display: block;
  }


  .expand-table {
    background-color: var(--cx-color-white);
    box-shadow: var(--common-box-shadow);

    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
    height: 100vh;
    overflow-y: auto;
  }

  .paginate{
    border: 0px;
    border-radius: 0px 0px 7px 7px;
  }

  .section-bottom {
    @include media-breakpoint-down(sm) {
      width: 100%;
      flex-direction: column;
      position: relative;
      mat-form-field.view-select {
        width: 100% !important;
      }
    }
  }
  
  @include media-breakpoint-down(sm) {
    .hide-mobile{
      display: none !important;
    }

    .alignItemVertically{
      display: grid !important;
      gap: 6px;
    }
    
    .contentStart{
      justify-content: flex-start !important;
    }

    .alignTextRight{
      text-align: end;
    }

    .dashboard{
      .box{
        margin-top: 6px;
        border: none;
        padding: 0px;
        align-items: end !important;
      }
    }
  }

  .inventory-transaction-footer  {
    .pagination__container {
      margin-left: auto;
  
      @include media-breakpoint-down(sm) {
        margin-left: 0;
        width: 100%;
      }
    }
  }
  
  // For NGX Pagination
  .ngx-pagination {
    li {
      width: auto;
      min-width: 30px;
    }
  }
} 