.header-sitelinks {
  &-link {
    a {
      color: var(--cx-color-black);
    }
  }
  &-signin {
    color: var(--cx-color-white);
  }
}
