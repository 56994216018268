.notify-stock-container {
  z-index: 99999;
  position: fixed;
  width: 368px;
  box-shadow: var(--common-box-shadow);
  background: var(--cx-color-white);
  right: 0;
  top: 0;
  height: 100vh;
  .notify-stock-header-border{
    padding: 10px;
    height: 44px;
    border-bottom: 1px solid var(--cx-color-black);
    .mat-mdc-unelevated-button.mat-primary {
      background-color: var(--cx-color-primary2);
      padding: 5px;
      &.sitelinks-close {
          min-width: 25px;
          padding: 0;
          span {
              color: var(--cx-color-white);
          }
      }
      &.cdk-program-focused , &.cdk-focused {
          color: var(--cx-color-white);
          background-color: var(--cx-color-primary2);
      }
  }
}
  .notify-stock-input{
    mat-mdc-form-field {
      display: block;
    }
  }
  .notify-stock-action-style {
    background: var(--cx-p2-10);
  }
  .sku-bgcolor{
    width: fit-content;
    background: var(--cx-drop-ship);
    padding: 2px // needed for proper display the sku code
  }
  .sales-rep-action{
    width:138px;
    background: var(--create-bus-btn);
    color: var(--cx-color-white);
  }
}