.user-admin {
  &-header {
    background: var(--cx-color-white);

    .add-user-cta-section {
      gap: 1rem;
    }
  }
  &-table-search {
    background: var(--cx-color-white);
    .mat-mdc-text-field-wrapper {
      width: 95%;
      .mat-mdc-form-field-infix {
        height: 48px;
        padding-top: 10px;
        padding-bottom: 10px;
        min-height: auto;
        .closeIcon {
          position: absolute;
          top: 10px;
          right: -10px;
          font-size: 1rem;
          width: 18px;
          height: 24px;
        }
      }
      .mat-mdc-form-field-flex .mat-mdc-floating-label {
        top: 21px;
        &.mdc-floating-label--float-above {
          top: 28px;
        }
      }
    }
  }
  &-table {
    table {
      width: 100%;
      tr {
        height: 45px;
        background-color: var(--cx-color-white);
        &::before {
          content: "."; // Necessary
          display: block;
          font-size: 8px;
          line-height: 45px;
          border-radius: 3px 0 0 3px;
          background-color: var(--side-nav-border-bottom);
          color: var(--cx-color-primary2);
        }
        &.example-detail-row {
          height: 0;
          &::before {
            content: ""; // Necessary
          }
          td.child-table-body {
            padding: 0;
            border: none;
            .expanded-child-table-container {
              padding: 16px 8px;
              .mat-mdc-checkbox {
                margin-right: 0 !important;
              } 
            }
            table {
              width: 96%;
              tr {
                &:nth-child(even) {
                  background-color: var(--cx-p2-10);
                }
                &::after {
                  position: absolute;
                  left: 24px;
                  content: ".";
                  margin-top: -24px;
                  width: 14px;
                  display: block;
                  line-height: 76px;
                  border-radius: 0 0 0 16px;
                  border-style: solid;
                  border-width: 0 0 2px 2px;
                  border-color: var(--side-nav-border-bottom);
                  background-color: transparent;
                  color: var(--cx-color-white);
                  box-shadow: -3px 3px 5px var(--cx-shadow-grey);
                  @include media-breakpoint-down(lg) {
                    left: 1px;
                  }
                  @include media-breakpoint-down(md) {
                    left: 8px;
                  }
                }
                &.mat-mdc-row {
                  &:last-child:not(:first-child) {
                    &::after {
                      content: "";
                      display: none;
                    }
                  }
                }
                td.mat-mdc-cell,
                th.mat-mdc-header-cell {
                  height: 45px;
                  padding: 10px;
                }
              }
            }
          }
        }
      }

      th.mat-mdc-header-cell {
        padding: 10px;
        background-color: var(--cx-p2-20);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        box-sizing: border-box;
        border-right: 1px solid var(--cx-table-cell-border);

        .col-header {
          color: var(--cx-table-header);
          .sort-icon {
            position: relative;
            left: 70%;
            bottom: 4px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            position: relative;
            cursor: pointer;
            &::before {
              content: url("./../../../assets/sort-asc.svg");
              position: absolute;
            }
            &::after {
              content: url("./../../../assets/sort-desc.svg");
              position: absolute;
            }
            &.ascending-order {
              &::before {
                margin-top: -10px;
              }
              &::after {
                margin-bottom: -10px;
              }
            }
            &.descending-order {
              &::before {
                margin-top: 12px;
                transform: rotate(180deg);
              }
              &::after {
                margin-top: -8px;
                transform: rotate(180deg);
              }
            }
          }
        }
      }

      td.mat-mdc-cell {
        padding: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: wrap;
        box-sizing: border-box;
        border-right: 1px solid var(--cx-table-cell-border);

        .col-value {
          display: flex;
          align-items: center;
          color: var(--cx-table-column);
          .show-manage-toggler {
            display: inline-flex;
            align-items: center;
            width: 25px;
            height: 100%;
            mat-icon[data-mat-icon-name="chevron_down"] {
              width: 12px;
              height: 20px;
            }
            mat-icon[data-mat-icon-name="chevron_right"] {
              width: 8px;
              height: 20px;
            }
          }
          .mat-mdc-checkbox {
            height: 16px;
            .mdc-form-field {
              margin-top: -12px;
            }
          }
        }
        &.mat-column-select:last-child {
          border-right: 1px solid var(--cx-shadow-grey);
        }
      }
    }

    .mobile-view {
      background-color: var(--cx-color-white);
      box-shadow: 0 4px 4px var(--cx-shadow-grey);
      border-radius: 7px;
      &-heading {
        background-color: var(--cx-hr-border);
        color: var(--cx-color-white);
        margin-bottom: 10px;
      }
      .row-heading {
        margin-bottom: 6px;
      }
      .multiple-accounts-toggle {
        margin-top: 22px;
        .toggler {
          color: var(--link-color);
        }
      }
      .multiple-accounts-heading {
        background-color: var(--cx-table-vertical-border);
        color: var(--cx-color-white);
        margin-top: 10px;
      }
      .account-template {
        margin-top: 27px;
        border-bottom: 1px solid var(--cx-hr-border);
        .account-id {
          .mat-mdc-checkbox {
            height: 16px;
          }
          @include media-breakpoint-down(md) {
            margin-left: -12px;
          }
        }
        .manage-cta {
          .manage-link {
            border: 1px dashed var(--cx-dark-grey);
            text-decoration: underline;
            border-radius: 4px;
          }
        }
      }
    }
  }
}
