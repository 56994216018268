.add-payment-card {
  @include media-breakpoint-down(sm) {
    width: 90vw;
    max-width: 90vw !important; //important is needed to overrride inline style provided by material
    margin-top: 16px;

    .mat-mdc-dialog-container {
      padding: 16px;

      .payment-add-edit-button {
        padding: 10px 15px;
      }
    }

  }

  .add-edit-card-dialog {
    .expiry-section {
      mat-mdc-form-field {
        width: 80px;
      }
    }

    .cvv-section {
      mat-mdc-form-field {
        width: 80px;
      }
    }

    @include media-breakpoint-up(sm) {
      .cvv-section {
        mat-mdc-form-field {
          width: 160px;
        }
      }
    }

    @include media-breakpoint-up(md) {
      .state-input-or-zipcode-input {
        mat-mdc-form-field {
          width: 240px;
        }
      }

      .expiry-section {
        mat-mdc-form-field {
          width: 100px;
        }
      }

      .cvv-section {
        mat-mdc-form-field {
          width: 270px;
        }
      }

    }

    .close {
      background-color: var(--cx-color-primary2);
      padding: 5px;
      min-width: 25px;
      padding: 0;
      opacity: 1;

      span {
        color: var(--cx-color-white);
      }

      &:not(:disabled):not(.disabled):hover,
      &:not(:disabled):not(.disabled):focus {
        opacity: 1;
      }

      &.cdk-program-focused,
      &.cdk-focused {
        color: var(--cx-color-white);
        background-color: var(--cx-color-primary2);
      }
    }

    .billing-address-section {
      .warning-section {
        font-style: italic;
        .warning-color {
          color: var(--red-warning-color);
          font-weight: 500;

        }
      }
    }
  }

  .default-payment {
    &-toggle {
      label {
        margin-bottom:0;
        @extend .t-label-l;
      }
    }
    &-add-addr.mat-icon {
      width: 14px;
    }
  }

  .expiry-error-section {
    margin-top: -24px;
    padding: 0 12px;
    .mat-mdc-form-field-error {
      font-size: 12px;
      letter-spacing: 0.4px;
    }
  }
}