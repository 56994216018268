.manage-fav-product-list-container {
  background: var(--cx-color-white);
  box-shadow: var(--common-box-shadow);

  .manage-check{
    margin-left: -8px;
  }

  .selectProduct {
    .mat-mdc-select-value {
      display: none;
    }
  }

}

.manage-favorite-action{
  .ena{
    cursor: pointer;
    color: var(--cx-color-primary);
  }
  .dis{
    pointer-events: none;
    cursor: default;
    @extend .link-color;
  }
}

.fav-edit-button{
cursor: pointer;
padding: 0 5px;
width: 53px;
height: 23px;
background: var(--cx-color-white);
border: 1px solid var(--cx-color-primary2);
border-radius: 5px;

&-edit-icon-style{
  width: 9px;
  height: 9px;
}

}

.sort-field{
  .mat-form-field-appearance-outline {
    .mat-mdc-text-field-wrapper {
      max-width: 120px;
      height: 52px;
    }
  }
}

.manage-fav-list-notes{
  text-align: center;
}