.ship-to-client-drawer-for-all{


  .mat-mdc-dialog-container{
    .mat-mdc-dialog-surface {
    padding: 0px;
    }
    mat-mdc-form-field {
      display: block;
    }
    .client-ship-to-state-input-or-zipcode-input{
      mat-form-field{
        width: 172px;
      }
    }
    .new-client-ship-to-state-input-or-zipcode-input{
      mat-form-field{
        width: 161px;
      }
    }
    //796: client ship to add account
    .client-ship-to-phone-input-or-mobile-input{
      .phone-order-info{
        width: 150px;
      }
      mat-mdc-form-field {
        width: 162px;
      }
    }
  }


.client-ship-to-submit{
  & button {
    background-color: var(--cx-color-primary);
    color: var(--cx-color-white);
  }
}

&.cart-add-business {
  .phone-number-section {
    width: 47%;
    .mat-mdc-form-field-subscript-wrapper {
      width: 150%;
    }
  }
  .mobile-number-section {
    width: 47%;
  }

  .sitelinks-close  {
    .mat-icon {
      font-size: 1.5rem;
    }
  }
}

.create-business-account-dialog {
  width: auto;
}
  
}