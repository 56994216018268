.order-list {
  //will be removed post api integration

  &-prod{
    max-width: 65%;
  }
  &-header{
    border-bottom: 2px solid var(--cx-color-black);
  }
  &-product {
    .prodImageClass {
      height: 90px;
      width: 90px;
    }
  }

  &-container{
    // min-width: 50%;
    @include media-breakpoint-down(sm) {
    min-width: 65%;
    }
  }
  &-slNo {
    color:var(--cx-color-white);
    padding: 16px 20px 8px 18px;
    background-color: var(--cx-hr-border);
  }
  &-header {
    background: var(--cx-color-white);
    box-shadow: var(--common-box-shadow);
    margin-bottom: 1px solid var(--cx-color-primary);
  }
  &-prices{
    width: 38%;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
    &-sectionOne{
      height: 50px;
      @include media-breakpoint-down(sm) {
    margin-left: 100px;
      }
    }
    &-sectionTwo{
      @include media-breakpoint-down(sm) {
      width: 102%;
      }
    }
    &-border{
      border-top: 1px solid var(--cx-color-primary);
    }
  }
  &-headerInfo {
    position: relative;
    background-color: var(--cx-global-inventory);
  }
  &-pipe {
    width: 5px;
    background-color: var(--cx-table-vertical-border);
    height: 100px;
  }
  &-info {
    border: 1px solid var(--cx-color-primary2);
    background: var(--cx-color-white);
    box-shadow: var(--common-box-shadow);
  }

  &-status{
    padding:4px 20px;
    margin-right: 10px;
    color:var(--cx-color-white);
    background-color: var(--cx-warning-success);
    position: absolute;
    right: 0px;
    margin-top: 12px;
    @include media-breakpoint-down(md) {
      margin-top: 0;
    }
  }
  .trackingId{
    background-color: var(--cx-p70);
  }
  .priceCent{
    padding-right: 2px;
  }
}

.order-info-card{
.order-confirm-width{
  width:65%;
}
}

.order-detail-total {
  .order-parameters {
    gap: 25px;
    .order-parameter-keys,
    .order-parameter-values {
      gap: 23px;
    }
  }
}

.save-amount {
  background: var(--save-amount);
  outline: 1px solid var(--save-amount);
  border: 1px solid var(--cx-color-black)
}