body {
  .register-modal {
    .mat-mdc-dialog-container {
      padding: 0px !important;
      overflow: hidden;
    }
    @include media-breakpoint-up(lg) {
    min-width: 550px;
    }
    @include media-breakpoint-down(lg) {
      max-width: 95vw !important;
      height: 95vh;
    }
  }
}
