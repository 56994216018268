
.order-detail-promotion{
  &-detail{
    background: var(--cx-color-white);
    box-shadow: var(--common-box-shadow);
  }
  &-border{
    border-top: 1px solid var(--cx-color-black);
  }
  .mat-icon{
    width:50px;
    height:30px;
  }
  &-section{
    background: var(--cx-color-white);
    box-shadow: var(--common-box-shadow);
  }
  &-seperator{
  border-bottom: 1px solid  var(--cx-table-vertical-border);
  }

  
  .order-detail-line-promotion {
    background: var(--cx-color-white);
    box-shadow: 0px 4px 4px var(--cx-box-shadow);
    gap: 1rem;
    margin-top: -20px;
    .promotion-icon {
      &.mat-icon[svgicon="promotion"] {
        min-width: 46px;
        width: 46px;
        height: 28px;
      }
    }
    .content-section {
      .promo-text {
        color: var(--cx-multiple-order);
      }
      @include media-breakpoint-down(sm) {
        width: 70%;
      }
    }
    
  }
  

  
}