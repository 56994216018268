.sales-rep-home-page-graph{
  .sales-rep-home-page-vertical-border{
    border-left: 1px solid var(--cx-color-black);
  }
  .sales-rep-home-page-horizontal-border{
    border-bottom: 1px solid var(--cx-color-black);
  }
  .sales-rep-perc{
    color: var(--cx-warning-success);
  }
  .sales-rep-negative-perc{
    color: var(--cx-warning-error);
  }
}