.header-searchbar-modal-main {
  /* Column container */
  z-index: 99999;
  position: absolute;
  width: 750px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  .header-searchbar-modal {
    display: flex;
    flex-wrap: wrap;
  }
  &.header-searchbar-modal-main-logout{
  width: 627px;
}
  /* Create two unequal columns that sits next to each other */
  /* Sidebar/left column */
  .side {
    flex: 50%;
    background-color: var(--cx-color-white);
    padding: 20px;
  }

  /* Main column */
  .main {
    flex: 50%;
    background-color: var(--cx-p2-10);
    padding: 20px;
  }

  .mat-mdc-list-item {
    border-top: 1px solid   var(--cx-p20);
  }

  .mat-mdc-list-item:first-of-type {
    border-top: 1px solid var(--cx-color-primary2);
  }
  .product-img{
    width: 75px;
    margin-right: 16px;
  }

  p{
    margin-bottom: 0;
  }
.recomendations-main{
  border-top: 1px solid  var(--cx-p20) ;
}
.recomendations-main:first-of-type {
  border-top: 1px solid  var(--cx-color-primary2);
}

  .text-color-primary {
    color: var(--cx-color-primary);
  }

  /* Responsive layout - when the screen is less than 700px wide, make the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 700px) {
    .header-searchbar-modal {
      flex-direction: column;
    }

  }
  .sticky-search-modal-main{
    width: auto;
  }
}
@include media-breakpoint-down(md) {
  .header-searchbar-modal-main {
    width: calc(100% - 16px);
    margin-left: 8px;
  }
  .sticky-search-modal-main{
    width: auto;
  }
}

@include media-breakpoint-only(lg) {
  .header-searchbar-modal-main {
    width: 640px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px){
  .header-searchbar-modal-main {
    width: 54%;
  }
}
