.category-content-container {
  width: 354px;
  height: 366px;
  box-shadow: var(--common-box-shadow);
  background-color: var(--cx-color-white);
  border-radius: 5px;
  .category-image{
    .category-image-style{
       img{
        width: 92px;
        height: 94px;
      }
     
    }
   
    .category-info-link-display {
      color: var(--cx-color-black);
      a {
        &:hover {
          color: var(--cx-color-black);
        }
      }
    }
  }
}
