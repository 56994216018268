body {
  .business-addresses {
    &-header {
      background: var(--cx-color-white);
    }

    &-sub-header {
      background: var(--business-addresses-sub-header);
    }

    &-sub-header-2 {
      background-color: var(--cx-p2-20);
    }

    &-number {
      background: var(--side-nav-border-bottom);
      color: var(--business-addresses-number);
    }

    &-line-design {
      height: 144px;
      width: 5px;
      background: var(--side-nav-border-bottom);
    }
  }

  .ngx-pagination {
    display: flex;
    justify-content: flex-end;
    margin: 0;
    -webkit-justify-content: flex-end;
    align-items: end;
    -webkit-align-items: end;
  }

  .viewAddressCircle {
    display: grid;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    height: 23px;
    width: 23px;
    border: 1px solid var(--cx-shadow-grey);
    border-radius: 50%;
    background-color: var(--cx-view-all);
    color: var(--cx-color-white);
  }

  .dashed {
    border: 1px dashed;
    border-color: var(--cx-color-black);
    text-decoration: underline;
    border-radius: 6px;
  }

  .address-border {
    border: 1px solid var(--side-nav-border-bottom);
  }

  .editAddress {
    border-radius: 0 0 5px 5px;
    border: 1px dashed var(--side-nav-border-bottom); 
    text-decoration: underline;

    mat-icon {
      width: 15px;
      height: 15px;
      margin-left: 5px;
    }
  }

  .up-arrow {
    width: 12px;
    margin-bottom: 1px;
  }

  .createShipment {
    padding: 0 10px;
  }

  .actionButton {
    gap: 10px;
    flex-wrap: wrap;

    .shipTo {
      flex-grow: 1;
      min-width: auto;
    }

    .mdc-button, .mdc-button__label {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 9px;
      grid-gap: 9px;
    }

    .removeAcct {      
      gap: 0;
      grid-gap: 0;
      .mdc-button__label {
        gap: 0;
        grid-gap: 0;
      }
    }

    mat-icon[svgicon="ship_to"] , mat-icon[svgicon="up_arrow"] {
      width: 16px;
      height: 20px;
      margin: 0;
    }

    mat-icon[svgicon="manage_acct"] {
      width: 16px;
      height: 23px;
    }

    button {
      padding: 10px;

      @include media-breakpoint-down(sm) {
        padding: 10px 5px; // For viewport less than 360px

        &:first-child {
          width: 100%;
        }

        &:not(:first-child) {
          width: calc(50% - 5px);
          flex-grow: 1;
        }

        &.only-one-address,
        &.only-one-account {
          width: 100%;
        }

        &.one-address-and-one-account {
          width: calc(50% - 5px);
        }
      }

      .remove-shipTo {
        width: 16px;
        height: 23px;
        svg {
          path {
            fill: var(--btn-white-txt-color);
          }
        }
        &.mat-icon {
          @include media-breakpoint-down(md) {
            margin-right: 8px;
          }
        }
      }
    }
    .mat-mdc-unelevated-button .mat-icon {
      margin: 0;
    }
  }
}