.my-account-user-info {

    a{
        color:var(--cx-color-black);
        &:hover{
            color:var(--cx-color-black);
        }
    }

    &.user-info {
        box-shadow: 0 4px 4px rgba(0 0 0 / 25%);
        background: var(--cx-color-white);

        .avatar {
            border: 4px solid var(--cx-color-secondary);
            border-radius: 50%;
            width: 150px;
        }

        .mat-mdc-card {
            background: var(--cx-color-white);
            box-shadow: none;
        }

        span {
            display: block;
        }

        @media only screen and (max-width: 768px) {
            .info {
                text-align: center;
            }
        }

        .email-on-hover:hover {
            text-decoration: underline;
        }

        .user-contant-btn:hover {
            background-color: var(--hover-button-color);
        }

        .card-body{
            overflow-wrap: break-word;
        }
    }
}