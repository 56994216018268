body{

  .business-account-dialogs-style{
    .mat-mdc-dialog-container{
      .mat-mdc-dialog-surface {
      border-radius: 0;
      padding:24px 0;
      @include media-breakpoint-down(md) {  
        max-width: 100% !important;
        height: 100% !important;
        width: 100% !important; 
        padding:24px;
       }
      //  .mat-form-field-appearance-outline {
      //   width: 100%;
      //  }
      }
    }
    .create-business-account {
      &-input-outline {
        &:focus {
          outline: none;
        }
      }
    }
    .create-business-account-input-display {
         mat-mdc-form-field {
          display: block;
        }
      }
    .create-business-account-submit-button {
        & button {
          background-color: var(--create-bus-btn);
          color: var(--cx-color-white);
        }
      }
  
      .phone-input-or-mobile-input{
        .phone-order-info{
          width: 314px;         
        }
        mat-mdc-form-field {
          width: 366px;
        }
      }

      
  
      @include media-breakpoint-down(md){
        .phone-input-or-mobile-input{
          .phone-order-info{
            margin-bottom: 12px;
          }
          mat-mdc-form-field {
            width: 100%;
          }
        }
      }

      .edit-ship-to-toggle-ship{
        width: 75px;
        overflow-wrap: break-word;
      }

      .edit-ship-to-toggle-bill{
        width: 52px;
        overflow-wrap: break-word;
      }
  }

.create-business-account-dialog{
  width: 780px;
  @include media-breakpoint-down(md) {  
    width: auto;
   }
  &.container{
    padding: 16px !important;
  }
  .ship-to-business-header {
    cursor: pointer;
    padding: 10px 10px;
    height: 44px;
    border-bottom: 1px solid var(--cx-color-black);

    .cancel-img {
      width: 25px;
    }

    .mat-mdc-unelevated-button.mat-primary {
      background-color: var(--side-nav-close-button);
      padding: 5px;

      &.sitelinks-close {
        min-width: 25px;
        padding: 0;

        span {
          color: var(--cx-color-white);
        }
      }

      &.cdk-program-focused,
      &.cdk-focused {
        color: var(--cx-color-white);
        background-color: var(--side-nav-close-button);
      }
    }
  }
  .bus-acct-phone,.client-phone {
    width: 50%;
    @include media-breakpoint-down(md) {  
      width: 100%;
     }
  }
}

.ship-to-business-account-container {
  .create-business-account-dialog{
    width: auto;
  }
}

 .shipto-business-formField{
  mat-mdc-form-field {
    width: 95%;
    padding-left:10px;
  }
 }

 .cart-shipTo-businessAccount{
  mat-mdc-form-field {
    width: 155px;
  }
 }
}