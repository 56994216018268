body.theme-musite {
  header {
    .header {
      .SiteLinkFlexSlot {
        .header-sitelinks {
          &-link {
            a {
              color: var(--cx-color-black);
            }
          }
          &-signin {
            color: var(--cx-color-white);
          }
          &-btn {
            svg {
              path {
                fill: var(--cx-color-white);
              }
            }
            &:hover {
              color: var(--cx-color-black);
              svg {
                path {
                  &:hover {
                    fill: var(--cx-color-black);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}