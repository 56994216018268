.ship-to-client-patient-container {


  a{
    color:var(--cx-color-black);
    &:hover{
        color:var(--cx-color-black);
    }
}


  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 7px;
    background: var(--cx-p10);
    box-shadow: inset 0 0 4px var(--cx-p10);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--cx-table-scroll);
    border-radius: 10px;
  }
  
  position: fixed;
  width: 368px;
  box-shadow: var(--common-box-shadow);
  background: var(--cx-color-white);
  right: 0;
  top: 0;
  height: 100vh;


  .ship-to-address-block{
    border: 1px dashed var(--side-nav-border-bottom);
    border-radius: 7px;
    .ship-to-address-block-margin{
      border: 1px solid var(--cx-hr-border);
    }
    .single-addresses-count-style{
      width: 30px;
      height: 30px;
      background: var(--cx-color-primary);
      border-radius: 18px;
      color:  var(--cx-color-white);
      text-align: center;
    }
    .other-addresses-count-style{
      width: 30px;
      height: 30px;
      background: var(--cx-warning-error);
      border-radius: 18px;
      color:  var(--cx-color-white);
      text-align: center;
    }
  }

  .ship-to-address-header-border{
    padding: 10px;
    height: 44px;
    border-bottom: 1px solid var(--cx-color-black);
    .mat-mdc-unelevated-button.mat-primary {
      background-color: var(--cx-color-primary2);
      padding: 5px;
      &.sitelinks-close {
          min-width: 25px;
          padding: 0;
          span {
              color: var(--cx-color-white);
          }
      }
      &.cdk-program-focused , &.cdk-focused {
          color: var(--cx-color-white);
          background-color: var(--cx-color-primary2);
      }
  }
  }

  .ship-to-this-address-button{
    padding: 6px;
  }

  .add-address-icon {
    font-size: 30px;
  }

  .paginationPosition{
    position: fixed;
    bottom: 0px;
    width: 368px;
    z-index: 1000;
  }
  
}