.reusable-address {
  &-input-outline {
    &:focus {
      outline: none;
    }
  }
}

.reusable-addresst-input-display {
   mat-mdc-form-field {
    display: block;
  }
}

.reusable-addresst-input-display-inline {
   mat-mdc-form-field {
    display: block;
  }
}

.state-input-or-zipcode-input{
  mat-form-field{
    width: 366px;
  }
}




@include media-breakpoint-down(md){
  .state-input-or-zipcode-input{
    mat-mdc-form-field {
      width: 100%;
    }
  }
}
