

.category-page-main-container{
  .category-page-title-container{
    box-shadow: var(--common-box-shadow);
    background-color: var(--cx-color-white);
  .category-heading{
    color: var(--cx-color-primary);
  }
  }

	  
.category-top-banner{
	cx-banner cx-generic-link {
        &:focus{
          outline-style: none;
        }
        a{
          &:focus{
            outline-style: none;
          }
          cx-media img{
            max-height: 127px;
          }
        }
      }
      cx-media img{
        box-shadow: var(--common-box-shadow);
      }
	}
	  
.category-middle-banner{
	  cx-banner cx-generic-link {
        &:focus{
          outline-style: none;
        }
        a{
          &:focus{
            outline-style: none;
          }
          cx-media img{
            max-height: 127px;
          }
        }
      }
      cx-media img{
        box-shadow: var(--common-box-shadow);
      }
	}
  
}

