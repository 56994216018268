.sr-user-impersonation-dialog {
  max-width: 95vw !important; // Necessary for design
  max-height: 95vh;
  .sr-user-impersonation {
    max-width: 422px;
    .sr-warning-icon {
      width: 66px;
      height: 66px;
    }
    .action-buttons {
      gap: 16px;

      .cancel-button {
        box-sizing: border-box;
        position: relative;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        white-space: nowrap;
        text-decoration: none;
        vertical-align: baseline;
        text-align: center;
        min-width: 64px;
        line-height: 38px;
        border-radius: 4px;
        border: 1px solid var(--btn-white-border-color);
        color: var(--btn-white-txt-color);
        background-color: var(--btn-white-bg-color);
      }
    }
    .warning-message {
      max-width: 327px;
    }
  }
}
