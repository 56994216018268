.manage-client-acct {
  a{
    color:var(--cx-color-black);
    &:hover{
        color:var(--cx-color-black);
    }
}
  &-section {
      box-shadow: var(--common-box-shadow);
      background-color: var(--cx-color-white);

      h3 {
          background-color: var(--cx-p2-60);
          height: 35px;
      }
  } 
  .business-addresses {
    .card {
      border: 0;
      .card-body {
        padding: 0 !important;
      }
    }
  }
}