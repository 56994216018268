.acct-page-header {
    .t-section-header {
        @include media-breakpoint-down(sm) {
            font-size: 20px;
        }
    }
}
.rewards-credits {
    &-header {
        box-shadow: var(--common-box-shadow);
        border-radius: 5px 5px 0 0;
        background-color: var(--cx-color-white);
    }

    &-total-earned {
        background-color: var(--cart-credit-modal-header);
        color: var(--cx-color-white);

        @include media-breakpoint-down(sm) {
            .t-rewards-inverse-brand-earned-custom {
                font-size: 28px;
                line-height: 34px;
            }

            .t-rewards-inverse-amount-points {
                font-size: 15px;
                line-height: 12px;
            }
        }
    }

    &-details-wrapper{
        border-radius: 5px 5px 0 0;
        background-color: var(--cx-color-white);

    }

    &-brand-details-left {
        flex-basis: 40%;
    }

    &-brand-details-right {
        flex-basis: 60%;
    }

    &-details-popup {
        flex-basis: 15%;
    }

    &-earned-total {
        flex-basis: 80%;
    }

    &-earned {
        width: 150px;
        flex-basis: 100%;
        border: 1px solid var(--rewards-border-color);
        overflow-wrap: break-word;
        min-width: 100px;
    }

    &-total-available {
        width: 150px;
        flex-basis: 100%;
        border: 1px solid var(--rewards-border-color);
        overflow-wrap: break-word;
        min-width: 100px;
    }

    &-button-class {
        border: 1px solid;
        color: var(--btn-white-txt-color);
        background-color: var(--btn-white-bg-color);
        border-color: var(--rewards-button-color);;
        border-radius: 5px;
    }

    &-program-overview {
        background-color:  var(--cx-color-white);
        border-radius: 0%;
    }

    &-main-header {
        line-height: 30px;
    }

    &-available-label {
        text-align: right;
    }
}
