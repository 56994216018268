$font-size8: 0.5rem;
$font-size10: 0.625rem;
$font-size12: 0.75rem;
$font-size14: 0.875rem;
$font-size16: 1rem;
$font-size18: 1.125rem;
$font-size20: 1.25rem;
$font-size22: 1.375rem;
$font-size24: 1.5rem;
$font-size28: 1.75rem;
$font-size30: 1.875rem;
$font-size32: 2rem;
$font-size36: 2.25rem;
$font-size48: 3rem;

// font weights
$font-weight-thin: 100;
$font-weight-extra-thin: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-black: 900;

// !====== start - to override the existing spartacus theme variables by custom variables =====!

// font sizes
$font-size-base: $font-size16; //16px
// $h1-font-size: $font-size-base * 2.375; //38px
$h1-font-size: $font-size32; //36px
$h2-font-size: $font-size28;
$h3-font-size: $font-size22;
$h4-font-size: $font-size18;
$h5-font-size: $font-size16;
$small-font-size: $font-size14;

$font-weight-light: $font-weight-light;
$font-weight-normal: $font-weight-regular;
$font-weight-semi: $font-weight-semi-bold;
$font-weight-bold: $font-weight-bold;

// !====== end - to override the existing spartacus theme variables by custom variables =====!

// line heights
$line-height125: 1.25;
$line-height127: 1.27;
$line-height128: 1.28;
$line-height15: 1.5;
