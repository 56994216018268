// This is a temporary fix. Will be fixed permanently with content migration story
.page-not-found {
  cx-page-layout {
    .LiveSupportSlot,
    .SiteLogo,
    .SiteLinks,
    .FooterPoweredByBoxout,
    .FooterLegalLink,
    .FooterLogo,
    .SiteLogin,
    .PreHeader {
      display: none;
    }
  }
}