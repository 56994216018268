.sr-user-setting {
  &-password-box {
    max-width: 400px;
    box-shadow: var(--common-box-shadow);
    background-color: var(--cx-color-white);
    .password {
      background-color: var(--sr-nav);
    }
    .change-password-cta {
      border-radius: 8px;
      height: 36px;
      color: var(--cx-mat-input-color) !important; // Necessary for design
      border-color: var(--sr-nav);
    }
  }
}
